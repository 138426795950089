/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
/**
 * Constant definitions
 */
export const COURSE_SYNC_REQUESTED = 'COURSE_SYNC_REQUESTED';
export const FILTER_TYPE = 'FILTER_TYPE';
export const SCHEDULE = 'Schedule';
export const UNSCHEDULE = 'Unschedule';
export const RESCHEDULE = 'Reschedule';
export const WELCOME_MESSAGE = 'welcomeMessage';
export const BOOK_SEARCH_DATA_FETCHED = 'BOOK_SEARCH_DATA_FETCHED';
export const MANAGE_USERS_DATA_FETCHED = 'MANAGE_USERS_DATA_FETCHED';
export const MANAGE_USERS_PERMISSIONS = 'MANAGE_USERS_PERMISSIONS';
export const MANAGE_USERID = 'MANAGE_USERID';
export const ADD_USER_DATA = 'ADD_USER_DATA';
export const EDIT_USER_DATA = 'EDIT_USER_DATA';
export const GET_USER_ROLES = 'USER_ROLES';
export const TOC_DATA_FETCHED = 'TOC_DATA_FETCHED';
export const EPUB_TOC_DATA_FETCHED = 'EPUB_TOC_DATA_FETCHED';
export const MODULE_LIST_FETCHED = 'MODULE_LIST_FETCHED';
export const PDF_HOTSPOT_REQUESTED = 'PDF_HOTSPOT_REQUESTED';
export const PDF_HOTSPOT_FETCHED = 'PDF_HOTSPOT_FETCHED';
export const PDF_SAVE_HOTSPOT_REQUESTED = 'PDF_SAVE_HOTSPOT_REQUESTED';
export const PDF_DELETE_HOTSPOT_REQUESTED = 'PDF_DELETE_HOTSPOT_REQUESTED';
export const BOOK_METADATA_FETCHED = 'BOOK_METADATA_FETCHED';
export const BOOK_USECALLBACK = 'BOOK_USECALLBACK';
export const BOOK_METADATA_POST = 'BOOK_METADATA_POST';
export const MEDIA_BASEPATHS_DATA_FETCHED = 'MEDIA_BASEPATHS_DATA_FETCHED';
export const MEDIA_BASEPATHS_POST = 'MEDIA_BASEPATHS_POST';
export const COLOR_INTERFACE_DATA_FETCHED = 'COLOR_INTERFACE_DATA_FETCHED';
export const CUSTOM_ICON_FETCHED = 'CUSTOM_ICON_FETCHED';
export const COLOR_INTERFACE_POST = 'COLOR_INTERFACE_POST';
export const CUSTOM_ICONS_GET_DATA = 'CUSTOM_ICONS_GET_DATA';
export const CUSTOM_ICONS_UPLOAD = 'CUSTOM_ICONS_UPLOAD';
export const CM_GET_BUSINESS_UNIT = 'CM_GET_BUSINESS_UNIT';
export const CM_FETCH_DB_FLAG = 'CM_FETCH_DB_FLAG';
export const CM_DB_FLAG_FETCHED = 'CM_DB_FLAG_FETCHED';
export const CM_WORKFLOW_STATUS_HISTORY_GET = 'CM_WORKFLOW_STATUS_HISTORY_GET';
export const CM_WORKFLOW_SEARCH = 'CM_WORKFLOW_SEARCH';
export const CM_WORKFLOW_STATUS_SEARCH_POST = 'CM_WORKFLOW_STATUS_SEARCH_POST';
export const SERVER_SIDE_PROCESS_REQUESTED = 'SERVER_SIDE_PROCESS_REQUESTED';
export const GET_SERVER_SIDE_PROCESS_DATA = 'GET_SERVER_SIDE_PROCESS_DATA';
export const CANCEL_SERVER_SIDE_PROCESS = 'CANCEL_SERVER_SIDE_PROCESS';
export const SEARCH_SERVER_SIDE_PROCESS_DATA = 'SEARCH_SERVER_SIDE_PROCESS_DATA';
export const SSP_DATA = 'SSP_DATA';
export const CM_GET_BUCKET_FOLDERS = 'CM_GET_BUCKET_FOLDERS';
export const CM_CREATE_SERVICE = 'CM_CREATE_SERVICE';
export const CM_VALIDATE_SERVICE = 'CM_VALIDATE_SERVICE';
export const CM_PREFERENCE_SERVICE = 'CM_PREFERENCE_SERVICE';
export const CM_BUCKET_FOLDERS = 'CM_BUCKET_FOLDERS';
export const CM_BUCKET_CLOSE = 'CM_BUCKET_CLOSE';
export const CM_CREATE_NEW_BUCKET = 'CM_CREATE_NEW_BUCKET';
export const CM_DELETE_BUCKET_FILES = 'CM_DELETE_BUCKET_FILES';
export const CM_CREATE_BUCKET_FOLDERS = 'CM_CREATE_BUCKET_FOLDERS';
export const CM_GET_SIGNED_URL = '/cm/ingestion/signedurl';
export const CM_POST_EXPORT_URL = '/workflow/export';
export const CM_PREVIEW_TITLE_DATA = 'CM_PREVIEW_TITLE_DATA';
export const CM_CANCEL_INGESTION_DATA = 'CM_CANCEL_INGESTION_DATA';
export const CM_INGESTION_CANCEL = 'CM_INGESTION_CANCEL';
export const CM_PREVIEW_TITLE_CHANGES_POST = 'CM_PREVIEW_TITLE_CHANGES_POST';
export const CM_PREVIEW_DATA_FETCHED = 'CM_PREVIEW_DATA_FETCHED';
export const CM_TITLE_DATA_FETCHED_NAVIGATION = 'CM_TITLE_DATA_FETCHED_NAVIGATION';
export const CM_DB_SUB_BUSINESS_CODE = 'CM_DB_SUB_BUSINESS_CODE';
export const CM_DB_DIRECTORY = 'CM_DB_DIRECTORY';
export const GET_QUERY_RESULT = 'GET_QUERY_RESULT';
export const CM_TITLE_SUB_BUSINESS_CODE = 'CM_TITLE_SUB_BUSINESS_CODE';
export const CM_TITLE_DIRECTORY = 'CM_TITLE_DIRECTORY';
export const CM_TITLEACTION_POST = 'CM_TITLEACTION_POST';
export const CM_TITLEACTION_POST_BACKUP = 'CM_TITLEACTION_POST_BACKUP';
export const CM_TITLEACTION_POST_UPLOAD = 'CM_TITLEACTION_POST_UPLOAD';
export const CM_TITLEACTION_EXPORT_EXCEL = 'CM_TITLEACTION_EXPORT_EXCEL';
export const CM_TITLEACTION_UPDF = 'CM_TITLEACTION_UPDF';
export const CM_VERIFY_PARENT_ID = 'CM_VERIFY_PARENT_ID';
export const CM_INGEST_UNITS = 'CM_INGEST_UNITS';
export const ETEXT_REQUEST_POST = 'ETEXT_REQUEST_POST';
export const ETEXT_REVISION_POST = 'ETEXT_REVISION_POST';
export const ETEXT_NEW_REQUEST_GET = 'ETEXT_NEW_REQUEST_GET';
export const ETEXT_NEW_REVISION_GET = 'ETEXT_NEW_REVISION_GET';
export const ETEXT_REQUEST_UPDATE_POST = 'ETEXT_REQUEST_UPDATE_POST';
export const ETEXT_REVISION_UPDATE_POST = 'ETEXT_REVISION_UPDATE_POST';
export const ETEXT_REQUEST_DELETE_POST = 'ETEXT_REQUEST_DELETE_POST';
export const ETEXT_REVISION_DELETE_POST = 'ETEXT_REVISION_DELETE_POST';
export const ETEXT_DB_POST_DELETE = 'ETEXT_DB_POST_DELETE';
export const ETEXT_DB_POST_UPDATE = 'ETEXT_DB_POST_UPDATE';
export const ETEXT_DB_UPDATE_REVISION = 'ETEXT_DB_UPDATE_REVISION';
export const ETEXT_DB_VENDOR_VALUES = 'ETEXT_DB_VENDOR_VALUES';
export const ETEXT_DB_MANAGEDBY_VALUES = 'ETEXT_DB_MANAGEDBY_VALUES';
export const CUSTOM_ICONS_DEFAULT_UPLOAD = 'CUSTOM_ICONS_DEFAULT_UPLOAD';
export const ETEXT_DB_CUSTOM_ICON = 'ETEXT_DB_CUSTOM_ICON';
export const QUERY = 'QUERY';
export const QUERY_RESULT_FETCHED = 'QUERY_RESULT_FETCHED';
export const ETEXT_DB_REQUEST_QUEUE_SEARCH = 'ETEXT_DB_REQUEST_QUEUE_SEARCH';
export const ETEXT_DB_EXPORT_REQUEST_QUEUE_SEARCH = 'ETEXT_DB_EXPORT_REQUEST_QUEUE_SEARCH';
export const ETEXT_DB_STATUS_DROPDOWN_VALUES = 'ETEXT_DB_STATUS_DROPDOWN_VALUES';
export const ETEXT_DB_DIVISIONS_DROPDOWN_VALUES = 'ETEXT_DB_DIVISIONS_DROPDOWN_VALUES';
export const ETEXT_DB_DISCIPLINE_CODE_VALUES = 'ETEXT_DB_DISCIPLINE_CODE_VALUES';
export const ETEXT_DB_LANGUAGES_VALUES = 'ETEXT_DB_LANGUAGES_VALUES';
export const ETEXT_DB_REQUEST_QUEUE_POST = 'ETEXT_DB_REQUEST_QUEUE_POST';
export const USER_ROLE_PERMISSIONS = 'USER_ROLE_PERMISSIONS';
export const ASSET = 'asset';
export const STANDARDS = 'standards';
export const ASSESSMENT = 'assessment';
export const CUSTOM = 'custom';
export const INTERMEDIARY = 'intermediary';
export const DASHBOARD = 'dashboard';
export const MODULE = 'module';
export const SUBMODULE = 'submodule';
export const USER_DETAILS_FETCHED = 'USER_DETAILS_FETCHED';
export const STANDARD_EBOOK_WORKFLOW = 'ebook';
export const STANDARD_EBOOK_FOLDER = 'standardebook';

export const EPUB_TITLE = 'epub';
export const PDF_TITLE = 'pdf';
export const RPLUS_PDF_TITLE = 'RPLUS_PDF';
export const RPLUS_EPUB_TITLE = 'RPLUS_EPUB';

export const SIMPLE_EBOOK_WORKFLOW = 'Simple eBook';
export const SIMPLE_EBOOK_FOLDER = 'simpleebook';
export const SPDF_WORKFLOW = 'spdf';

export const ENV_QA = 'QA';
export const ENV_PROD = 'PROD';
export const ENV_SANDBOX = 'SANDBOX';
// Chat Utility
export const BOOK_ID = 'Book Id';
export const DRAFT_AND_PUBLISHED = '(Draft/Published)';
export const MCQ = 'Mcq';
export const TOPIC_GENERATION = 'Topic Generation';
export const IMAGE_EXTRACTION = 'Image Extraction';
export const CHAT_CRITERIA_TEXT = 'Please enter search criteria to fetch the details';
export const CHAT_SEARCH = 'SEARCH';
export const CHAT_RESET = 'RESET';
export const CHAT_EXPORT = 'EXPORT';
export const CHAT_FETCH = 'Please search to fetch data ...';
export const CHAT_USERLOGIN = 'User Id';
export const CHAT_PROMPT = 'Prompt';
export const CHAT_SAFETY = 'Safety';
export const USER_ID = 'User Id';
export const REQUEST_ID = 'Request Id';
export const SELECT = 'Select';
export const TITLE = 'Title';
export const TYPE = 'Type';
export const SEARCH = 'Search';
export const PRODUCT_TYPE = 'Product Type';
export const CHAT_AUTHOR = 'Author';
export const CHAT_USERTOPIC = 'userTopic';
export const CHAT_PROMPT_CONTENT = 'Prompt With Content';
export const CHAT_PROMPT_CONTEXT = 'Prompt With Context';
export const DATE_TIME = 'Date / Time';
export const GET_CHATGPT_DATA = 'GET_CHATGPT_DATA';
export const GET_CHATGPT_USER_TOC_DATA = 'GET_CHATGPT_USER_TOC_DATA';
export const GET_CHATGPT_IES_USER_DATA = 'GET_CHATGPT_IES_USER_DATA';
export const SET_CHATGPT_IES_USER_DATA = 'SET_CHATGPT_IES_USER_DATA';
export const SET_CHATGPT_USER_TOC_DATA = 'SET_CHATGPT_USER_TOC_DATA';
export const SET_CHATGPT_MAPPING_DATA = 'SET_CHATGPT_MAPPING_DATA';
export const GET_CHATGPT_FEEDBACK_DATA = 'GET_CHATGPT_FEEDBACK_DATA';
export const GET_CHATGPT_RESPONSE_DATA = 'GET_CHATGPT_RESPONSE_DATA';
export const GET_CHATGPT_SUB_TYPE_RESPONSE_DATA = 'GET_CHATGPT_SUB_TYPE_RESPONSE_DATA';
export const SET_CHATGPT_SUB_TYPE_RESPONSE_DATA = 'SET_CHATGPT_SUB_TYPE_RESPONSE_DATA';
export const GET_CHATGPT_TRENDS_DATA = 'GET_CHATGPT_TRENDS_DATA';
export const GET_CHATGPT_RESPONSE_MODEL_DATA = 'GET_CHATGPT_RESPONSE_MODEL_DATA';
export const GET_CHATGPT_TRENDS_MODEL_DATA = 'GET_CHATGPT_TRENDS_MODEL_DATA';
export const GPT_RESPONSE = 'GPT_RESPONSE';
export const SET_CHATGPT_TOC_DATA = 'SET_CHATGPT_TOC_DATA';
export const GET_CHATGPT_TOC_DATA = 'GET_CHATGPT_TOC_DATA';
export const SET_SUMMARY_DATA = 'SET_SUMMARY_DATA';
export const SET_STUDYTOOL_EFFECTIVENESS_DATA = 'SET_STUDYTOOL_EFFECTIVENESS_DATA';
export const GET_STUDYTOOL_EFFECTIVENESS_DATA = 'GET_STUDYTOOL_EFFECTIVENESS_DATA';
export const GET_STUDYTOOL_EFFECTIVENESS_METADATA = 'GET_STUDYTOOL_EFFECTIVENESS_METADATA';
export const SET_STUDYTOOL_EFFECTIVENESS_METADATA = 'SET_STUDYTOOL_EFFECTIVENESS_METADATA';
export const SET_UPDATE_SUMMARY_DATA = 'SET_UPDATE_SUMMARY_DATA';
export const SET_SUMMARY_DATA_RESPONSE = 'SET_SUMMARY_DATA_RESPONSE';
export const GET_CHATGPT_SUMMARY_DATA = 'GET_CHATGPT_SUMMARY_DATA';
export const UPDATE_CHATGPT_SUMMARY_DATA = 'UPDATE_CHATGPT_SUMMARY_DATA';
export const UPDATE_CHATGPT_SUMMARY_DATA_STATUS = 'UPDATE_CHATGPT_SUMMARY_DATA_STATUS';
export const UPDATE_CHATGPT_PUBLISH_ALL_SUMMARY_DATA_STATUS = 'UPDATE_CHATGPT_PUBLISH_ALL_SUMMARY_DATA_STATUS';
export const DELETE_CHATGPT_SUMMARY_DATA = 'DELETE_CHATGPT_SUMMARY_DATA';
export const FETCH_MCQ_SEARCH_DATA = 'FETCH_MCQ_SEARCH_DATA';
export const SET_MCQ_SEARCH_DATA = 'SET_MCQ_SEARCH_DATA';
export const MCQ_QUESTION_UPDATE_STATUS = 'MCQ_QUESTION_UPDATE_STATUS';
export const MCQ_QUESTION_DELETE_STATUS = 'MCQ_QUESTION_DELETE_STATUS';
export const PUBLISHED_STATUS = 'PUBLISHED';
export const PUBLISH_ALL = 'Publish All';
export const PUBLISHED_ALL_STATUS = 'PUBLISHED_ALL';
export const MCQ_QUESTION_UPDATE_PUBLISHED_ALL_STATUS = 'MCQ_QUESTION_UPDATE_PUBLISHED_ALL_STATUS';
export const DELETED_STATUS = 'DELETED';
export const REVIEW_STATUS = 'REVIEW';
export const UPDATE_MCQ_QUESTION_DATA = 'UPDATE_MCQ_QUESTION_DATA';
export const ADD_MCQ_QUESTION_DATA = 'ADD_MCQ_QUESTION_DATA';
export const SET_UPDATE_MCQ_QUESTION_DATA = 'SET_UPDATE_MCQ_QUESTION_DATA';
export const SET_ADD_MCQ_QUESTION_DATA = 'SET_ADD_MCQ_QUESTION_DATA';
export const GPT_FEEDBACK_RESPONSE = 'GPT_FEEDBACK_RESPONSE';
export const GET_CHATGPT_MAPPING_DATA = 'GET_CHATGPT_MAPPING_DATA';
export const CONTENT_FILTER_BLOCKED = 'Content Filter (Blocked)';
export const GET_CHATGPT_LISTING_DATA = 'GET_CHATGPT_LISTING_DATA';
export const GET_CHATGPT_LISTING_MODEL_DATA = 'GET_CHATGPT_LISTING_MODEL_DATA';
export const CLUSTER_LIST = 'Cluster List';
export const CLUSTERING = 'Clustering';
export const COUNT = 'Count';
export const CANCEL = 'Cancel';
export const SAVE = 'Save';
export const ADMIN_SCREEN = 'Admin';
export const SAVE_ADMIN_DATA = 'SAVE_ADMIN_DATA';
export const SET_ADMIN_DATA = 'SET_ADMIN_DATA';
export const GET_ADMIN_DATA = 'GET_ADMIN_DATA';
export const EDIT_ADMIN_DATA = 'EDIT_ADMIN_DATA';

export const SAVE_PROPERTIES_ADMIN_DATA = 'SAVE_PROPERTIES_ADMIN_DATA';
export const SET_PROPERTIES_ADMIN_DATA = 'SET_PROPERTIES_ADMIN_DATA';
export const GET_PROPERTIES_ADMIN_DATA = 'GET_PROPERTIES_ADMIN_DATA';
export const GET_MODEL_PROPERTIES_ADMIN_DATA = 'GET_MODEL_PROPERTIES_ADMIN_DATA';
export const SET_MODEL_PROPERTIES_ADMIN_DATA = 'SET_MODEL_PROPERTIES_ADMIN_DATA';
export const EDIT_PROPERTIES_ADMIN_DATA = 'EDIT_PROPERTIES_ADMIN_DATA';
export const SET_PROMPT_FEATURE_DATA = 'SET_PROMPT_FEATURE_DATA';
export const GET_PROMPT_FEATURE_DATA = 'GET_PROMPT_FEATURE_DATA';
export const BOOK_STATUS_COLUMN_NAME = 'Status';

export const GPT_TABLECONTENT = 'Table Of Contents';
export const GPT_TOTALCHAPTER = 'Total Chapter questions';
export const GPT_TOCMODIFIED = 'Modified By';
export const GPT_STATUS = 'Status';
export const GPT_LASTMODIFIED = 'Last Modified';
export const GPT_TOTALPAGE = 'Total Page questions';
export const GPT_DIFFICULTY = 'Difficulty';
export const GPT_EDITQUESTION = 'Edit';
export const GPT_ADDQUESTION = 'Add';
export const GPT_DELETEQUESTION = 'Delete';
export const GPT_PUBLISHALL = 'Publish';
export const GPT_UN_PUBLISH = 'Un-Publish';
export const GPT_NOQUESTON = 'No question....Please add question';
export const GPT_NOSUMMARY = 'No summary found...';
export const GPT_UNPUBLISH_DIALOG_TEXT = 'Are you Sure to Un-Publish?';

// Gpt Trends
export const CHAT_UTILITY_ANSWER = 'answer';
export const GPT_TRENDS_USER_INPUT = 'User Input';
export const GPT_TRENDS_COMMAND = 'User Command';
export const GPT_TRENDS_DATE = 'Date';
export const GPT_TRENDS_BOOKTITLE = 'Book Title';
export const GPT_TRENDS_LIKECOUNT = 'Like Count';
export const GPT_TRENDS_DISLIKECOUNT = 'Dislike Count';
export const GPT_TRENDS_EXPLAINCOUNT = 'Explain Count';
export const GPT_TRENDS_SUMMARYCOUNT = 'Summary Count';
export const GPT_TRENDS_QUIZCOUNT = 'Quiz Mcq Count';
export const GPT_TRENDS_BLOCKEDWORDCOUNT = 'Blocked Word Count';
export const GPT_TRENDS_UNKNOWNCOUNT = 'Unknown Count';
export const GPT_TRENDS_UNANSWEREDCOUNT = 'Un-Answered Count';
export const GPT_TRENDS_UNANSWERED_NO_CSG_DOCS_COUNT = 'Un-Answered (No RAG Docs) Count';
export const GPT_TRENDS_UNANSWERED_UNKNOWN_INTENT_COUNT = 'Un-Answered (Unknown Intent) Count';
export const GPT_TRENDS_UNANSWERED_NO_TOPIC_FOUND_COUNT = 'Un-Answered (No Topic Found) Count';
export const GPT_TRENDS_BLOCKED_COUNT = 'Blocked Count';

// Trending Topics
export const GET_TRENDING_TOPICS_MODEL_DATA = 'GET_TRENDING_TOPICS_MODEL_DATA';
export const SET_TRENDING_TOPICS_MODEL_DATA = 'SET_TRENDING_TOPICS_MODEL_DATA';
// Bulk Utility
export const BULK_INGESTION = 'BULK_INGESTION';
export const BULK_ACTION = 'BULK_ACTION';
export const GET_BULK_INGESTION_LOGS = 'GET_BULK_INGESTION_LOGS';
export const SET_BULK_INGESTION_LOGS = 'SET_BULK_INGESTION_LOGS';
export const START_MIGRATION = 'START_MIGRATION';
export const GET_PULSE_ASSETS = 'GET_PULSE_ASSETS';
export const SET_PULSE_ASSETS = 'SET_PULSE_ASSETS';
export const GET_PULSE_ASSETS_BY_ID = 'GET_PULSE_ASSETS_BY_ID';
export const SET_PULSE_ASSETS_BY_ID = 'SET_PULSE_ASSETS_BY_ID';
export const BULK_ACTION_FOLDER = 'bulk-utility/bulk-action-assets';
export const BULK_INGESTION_FOLDER = 'bulk-utility/bulk-ingestion-assets';

export const SEPARATOR = '/';
export const PIPE = '|';
export const ICON_HOTSPOT = 1;
export const PAGE_HOTSPOT = 2;

export const MM_DD_YYYY_FORMAT = 'MM/DD/YYYY';
export const YYYY_MM_DD_FORMAT = 'YYYY/MM/DD';
export const DD_MM_YYYY_FORMAT = 'DD-MM-YYYY';
export const PEARSON_PRIMARY_COLOR = '#005d83';

export const YES = 'Y';
export const NO = 'N';

export const HOTSPOT_DEFAULT_FEATURE_CONFIG = {
  hotSpotColor: '#c10a5d',
  isUnderLineHotspot: false,
  regionHotSpotAlpha: '50',
  underLineHotSpotColor: '#0000FF',
  underLineHotSpotHoverColor: '#FF0000',
  underLineHotSpotThickness: '2'
};

export const STATUSES = {
  NOT_STARTED: 0,
  FOXIT_IN_PROGRESS: 1,
  FOXIT_PROCESS_COMPLETED: 2,
  FOXIT_PROCESS_FAILED: 3,
  FOXIT_FILES_S3UPLOAD_IN_PROGRESS: 4,
  FOXIT_FILES_S3UPLOAD_FAILED: 5,
  COMPLETED: 6,
  FAILED: 7,
  COVER_PAGE_NOT_FOUND: 8,
  SLATES_NOT_FOUND: 9
};

export const SSP_LOG_STATUS = {
  NOT_STARTED: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
  FAILED: 3,
  NOT_IN_OUEUE: 4,
  CANCELLED: 5
};

export const BOOK_CONTENT_TYPE = {
  PDF: 'PDF',
  EPUB: 'EPUB',
  ETEXT_PDF: 'ETEXT_PDF'
};

export const EMAIL_PATTERN = new RegExp(
  [
    '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)',
    '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.',
    '[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+',
    '[a-zA-Z]{2,}))$'
  ].join('')
);
export const FILTER_PROMPT = [
  'math_mcq_validation',
  'physics_mcq_validation',
  'recommendations',
  'summary_gen',
  'quiz_topic_practice2',
  'quiz_topic_practice1',
  'expertdeck_mcq',
  'expertdeck_free_response',
  'problem_solve',
  'explain_stand_alone',
  'quiz_mcq',
  'mcq_question_generation',
  'quiz_free_response',
  'free_response',
  'quiz_intent',
  'mcq_distract',
  'quiz_validate_intent',
  'mcq_misconceptions',
  'mcq_skill_identifier',
  'summary_topic',
  'summary-enrichment',
  'content_classifier',
  'chat_enrichment',
  'topic',
  'chat_intent',
  'mcq',
  'flashcards',
  'answer',
  'discuss',
  'dictionary_summary',
  'dictionary_quiz',
  'salutation',
  'context_identifier_user_selection',
  'summary_explain',
  'answer_cyu_mcq',
  'problem_solve_rectifier',
  'quiz',
  'non_math_mcq_validation',
  'math_mcq_core_concepts',
  'math_mcq_question_generation',
  'math_mcq',
  'summary_gpt4o',
  'physics_mcq',
  'physics_mcq_question_generation',
  'generate_python',
  'problem_solve_final_response',
  'history_title',
  'context_identifier_summary',
  'specific_topic_intent_identifier',
  'context_identifier_summary_topic',
  'context_identifier_quiz',
  'channel_fetch',
  'search_overview',
  'image_copilot_explain',
  'image_copilot_answer',
  'image_copilot_cyu',
  'image_copilot_cyu_evaluate',
  'image_copilot_intent_classifier'
];
export const SEARCH_OVERVIEW_TYPE = 'search_overview';
export const NON_MATH_MCQ_VALIDATION = 'non_math_mcq_validation';
export const MATH_MCQ_CORE_CONCEPTS = 'math_mcq_core_concepts';
export const MATH_MCQ_QUESTION_GENERATION = 'math_mcq_question_generation';
export const MATH_MCQ = 'math_mcq';
export const commonAuditTypesResponse = [
  'search_overview',
  'math_mcq_validation',
  'physics_mcq_validation',
  'recommendations',
  'quiz_topic_practice1',
  'quiz_topic_practice2',
  'non_math_mcq_validation',
  'math_mcq_core_concepts',
  'math_mcq_question_generation',
  'math_mcq',
  'summary_gpt4o',
  'summary_gen',
  'physics_mcq',
  'physics_mcq_question_generation',
  'generate_python',
  'problem_solve_final_response',
  'image_copilot_explain',
  'image_copilot_answer',
  'image_copilot_cyu',
  'image_copilot_cyu_evaluate',
  'image_copilot_intent_classifier'
];
export const ANSWER_CYU_MCQ = 'answer_cyu_mcq';
export const ASSET_SYNC_REQUESTED = 'ASSET_SYNC_REQUESTED';
export const ASSET_STATUS_REQUESTED = 'ASSET_STATUS_REQUESTED';
export const TENANT_LIST_TEXT = 'Please select Product to view the Audit Logs';
export const SELECT_PRODUCT_LABEL = 'Select Product';
export const TENANT_SEARCH_BUTTON = 'GO';
export const GET_CHANNELS_DATA = 'GET_CHANNELS_DATA';
export const GET_CHANNELS_SUB_TYPE_DATA = 'GET_CHANNELS_SUB_TYPE_DATA';
export const GET_CHANNELS_MODEL_DATA = 'GET_CHANNELS_MODEL_DATA';
export const CHANNELS_MODEL_RESPONSE = 'CHANNELS_MODEL_RESPONSE';
export const CHANNELS_SUB_TYPE_RESPONSE = 'CHANNELS_SUB_TYPE_RESPONSE';
export const CHANNELS_ANSWER_EXPLAINATION = 'generate_answer_explaination';
export const CHANNELS_RESPONSE = 'CHANNELS_RESPONSE';
export const CHANNELS_ID = 'Channels Id';
export const CHANNELS_FILTER_PROMPT = [
  'find_relevant_topics',
  'generate_answer_explaination',
  'proxy_content_filter',
  'generate_video_summary',
  'generate_topic_summary',
  'generate_question_intent',
  'generate_question_explanation',
  'generate_question_steps_and_solution',
  'generate_question_solution'
];
export const FLASHCARD_TYPE = 'flashcard';
export const GET_CHANNELS_LISTING_DATA = 'GET_CHANNELS_LISTING_DATA';
export const GET_CHANNELS_LISTING_MODEL_DATA = 'GET_CHANNELS_LISTING_MODEL_DATA';
export const RELEVANT_TOPICS = 'find_relevant_topics';
export const PROXY_CONTENT_FILTER = 'proxy_content_filter';
export const OTHER_TOPICS = 'Other topics';
export const CONTEXT_IDENTIFIER_USER_SELECTION = 'context_identifier_user_selection';
export const CHAT_INTENT_PROBLEM_SOLVE = 'chat_intent_problem_solve';
export const PROBLEM_SOLVE_FEATURE = 'problem_solve_feature';
export const CHAT_INTENT = 'chat_intent';
export const GENERATE_TOPIC_SUMMARY = 'generate_topic_summary';
export const GENERATE_VIDEO_SUMMARY = 'generate_video_summary';
export const EXPERTDESK_MCQ = 'expertdeck_mcq';
export const EXPERTDESK_FREE_RESPONSE = 'expertdeck_free_response';
export const GENERATE_QUESTION_INTENT = 'generate_question_intent';
export const GET_CHANNELS_TRENDS_DATA = 'GET_CHANNELS_TRENDS_DATA';
export const GET_CHANNELS_TRENDS_MODEL_DATA = 'GET_CHANNELS_TRENDS_MODEL_DATA';
export const SIGNED_OUT_USER = 'Signed Out User';
export const SIGNED_OUT_USER_ID = 'ffffffff61bb375351b4d75952c2cb68';
export const CHANNELS_CHECKBOX_TYPE = [
  'find_relevant_topics',
  'generate_answer_explaination',
  'generate_video_summary',
  'generate_topic_summary',
  'generate_question_intent',
  'generate_question_explanation'
];
export const RELEVANT_TOPICS_COUNT = 'Relevant Topics Count';
export const GENERATE_VIDEO_SUMMARY_COUNT = 'Video Summary Count';
export const GENERATE_QUESTION_INTENT_COUNT = 'Question Intent Count';
export const SUMMARY_EXPLAIN = 'summary_explain';
export const GENERATE_QUESTION_EXPLAINATION = 'generate_question_explanation';
export const GENERATE_QUESTION_SOLUTION = 'generate_question_solution';
export const GENERATE_QUESTION_STEPS_AND_SOLUTION = 'generate_question_steps_and_solution';
export const GENERATE_QUESTIONS = 'generate_questions';
export const GENERATE_TOPIC_FAQ = 'generate_topic_FAQ';
export const VIEW = 'view';
export const DOWNLOAD = 'Download';
export const MONTHS = 'Months';
export const DISCUSS = 'discuss';
export const PROBLEM_SOLVE = 'problem_solve';
export const PROBLEM_SOLVE_RECTIFIER = 'problem_solve_rectifier';
export const PROBLEM_SOLVE_VERIFIER = 'problem_solve_verifier';
export const EXPLAIN_STAND_ALONE = 'explain_stand_alone';
export const SALUTATION = 'salutation';
export const TOPIC = 'topic';
export const MMMM_FORMAT = 'MMMM';
export const SELECT_MONTH = 'Select Month';
export const CURRENT_MONTH = 'current_month';
export const LAST_MONTH = 'last_month';
export const LAST_THREE_MONTH = 'last_three_month';
export const GENERATE_QUESTION_SOLUTION_COUNT = 'Question Solutions Count';
export const QUESTION_STEP_SOLUTION_COUNT = 'Question Step Solution Count';
export const REPORT_SIGNED_URL = '/chatutility/reports';
export const MMM_YYYY_FORMAT = 'MMM YYYY';
export const SAVE_ADMIN_PROMPTS_DATA = 'SAVE_ADMIN_PROMPTS_DATA';
export const SET_ADMIN_PROMPTS_DATA = 'SET_ADMIN_PROMPTS_DATA';
export const SET_ADMIN_PROMPTS_UPDATED_DATA = 'SET_ADMIN_PROMPTS_UPDATED_DATA';
export const GET_ADMIN_PROMPTS_DATA = 'GET_ADMIN_PROMPTS_DATA';
export const EDIT_ADMIN_PROMPTS_DATA = 'EDIT_ADMIN_PROMPTS_DATA';
export const DELETE_ADMIN_PROMPTS_DATA = 'DELETE_ADMIN_PROMPTS_DATA';
export const DELETE = 'DELETE';
export const PROMPT_ARCHIVED_STATUS = 'ARCHIVED';
export const PROMPT_LIVE_STATUS = 'LIVE';
export const GENERATE_PYTHON = 'generate_python';
export const PROBLEM_SOLVE_FINAL_RESPONSE = 'problem_solve_final_response';
export const SET_PROBLEM_SOLVE_FINAL_RESPONSE = 'SET_PROBLEM_SOLVE_FINAL_RESPONSE';
export const GET_PYTHON_GENERATION_DATA = 'GET_PYTHON_GENERATION_DATA';
export const GENERATE_PYTHON_AI_RESPONSE = 'Generate Python Response';
export const PROBLEM_SOLVE_FINAL_AI_RESPONSE = 'Problem Solve Final Response';
export const VERIFIER_FLOW = 'Verifier';
export const GENERATE_PYTHON_FLOW = 'GeneratePython';
export const LIVE_IN_PRODUCTION = 'Live in Production';
export const SET_PROBLEM_SOLVE_DATA = 'SET_PROBLEM_SOLVE_DATA';
export const ADMIN_PROMPT_RESTORE_DIALOG_TEXT = 'Are you sure you want to restore this version and make this LIVE?';
export const ADMIN_PROMPT_PUBLISH_TEXT = 'Are you sure you want to Publish?';
export const PROMPT_DRAFT_STATUS = 'DRAFT';
export const INITIATE_NEW = 'INITIATE NEW';
export const DATE_TIME_GMT = 'Date/Time(GMT)';
export const S3_PATH = 'File Name';
export const SHOW_RESULTS = 'Show Results';
export const EXECUTED_BY = 'Executed By';
export const F1_SCORE_NAME = 'f1_score';
export const EFFECTIVENESS_INITIATE_DATA = 'EFFECTIVENESS_INITIATE_DATA';
export const EFFECTIVENESS_TRIGGER_FILE_UPLOAD = 'EFFECTIVENESS_TRIGGER_FILE_UPLOAD';
export const EFFECTIVENESS_SET_FILE_UPLOAD_RESPONSE = 'EFFECTIVENESS_SET_FILE_UPLOAD_RESPONSE';
export const GET_ADMIN_PROMPTS_URL = '/chatutility/prompt/getprompt';
export const GET_BOOK_DATA_URL = '/chatutility/quizmcq/search';
export const GET_S3_USERS_LIST_URL = '/chatutility/getfiledata';
export const TYPE_QUIZ = 'quiz';
export const CHAT_ENRICHMENT = 'chat_enrichment';
export const DOWNLOAD_TEMPLATE = 'Download Template';
export const CONTENT_MANAGEMENT_EXPORT_FILE_NAME = 'AIAdmin-Content Management';

export const GET_COMMON_SEARCH_UTILITY_DATA = 'GET_COMMON_SEARCH_UTILITY_DATA';
export const GET_COMMON_SEARCH_UTILITY_RESPONSE = 'GET_COMMON_SEARCH_UTILITY_RESPONSE';
export const GET_COMMON_SEARCH_INGESTION_RESPONSE = 'GET_COMMON_SEARCH_INGESTION_RESPONSE';
export const SUMMARY = 'Summary';
export const EXPLAIN = 'Explain';
export const QUIZ = 'Quiz';
export const PROBLEM_SOLVE_TYPE = 'Problem Solve';
export const EVALUATE = 'Evaluate';
export const RUN = 'Run';
export const ALL_BOOKS = 'All Books';
export const SUMMARY_TYPE = 'summary';
export const QUIZ_MCQ_TYPE = 'quiz_mcq';
export const EXPLAIN_TYPE = 'answer';
export const FEEDBACK_EXPLAIN_TYPE = 'discuss';
export const FEEDBACK_QUIZ_TYPE = 'quiz';

// ChatUtility Users
export const CHATUTILITY_MANAGE_USERS_DATA_FETCHED = 'CHATUTILITY_MANAGE_USERS_DATA_FETCHED';
export const CHATUTILITY_GET_USER_ROLES = 'CHATUTILITY_GET_USER_ROLES';
export const CHATUTILITY_ADD_USER_DATA = 'CHATUTILITY_ADD_USER_DATA';
export const CHATUTILITY_EDIT_USER_DATA = 'CHATUTILITY_EDIT_USER_DATA';
export const SET_ADDED_DATA = 'SET_ADDED_DATA';
export const SET_UPDATED_DATA = 'SET_UPDATED_DATA';
export const CHATUTILITY_ADD_ROLES_DATA = 'CHATUTILITY_ADD_ROLES_DATA';
export const CHATUTILITY_UPDATE_ROLES_DATA = 'CHATUTILITY_UPDATE_ROLES_DATA';
export const CHATUTILITY_GET_USER_PERMISSIONS = 'CHATUTILITY_GET_USER_PERMISSIONS';
export const PRACTICE_QUESTIONS = 'Practice Questions';
export const FETCH_POPULAR_PAGES_DATA = 'FETCH_POPULAR_PAGES_DATA';
export const SET_POPULAR_PAGES_DATA = 'SET_POPULAR_PAGES_DATA';
export const EXPLAIN_FEATURE = 'explain_feature';
export const SUMMARIZE_FEATURE = 'summarize_feature';
export const PRACTICE_QUESTION_FEATURE = 'practice_questions';
export const MATHJAX_CDN_URL =
  'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=AM_CHTML,TeX-AMS-MML_HTMLorMML';
export const CUSTOM_ASSESSMENT = 'Create Custom Assessment';
export const CUSTOM_ASSESSMENT_NAME = 'Name your custom assessment';
export const AUTO_GENERATE_AI = 'AUTO GENERATE WITH AI';
export const GET_CHATGPT_TYPE_RESPONSE_DATA = 'GET_CHATGPT_TYPE_RESPONSE_DATA';
export const SET_CHATGPT_TYPE_RESPONSE_DATA = 'SET_CHATGPT_TYPE_RESPONSE_DATA';
export const PROMPT_START_COLUMN_NAME = 'promptStart';
export const PROMPT_END_COLUMN_NAME = 'promptEnd';
export const CONTENT_FILTER_TYPE = 'content_filter';
export const CONTENT_CLASSIFIER = 'content_classifier';
export const DICTIONARY_SUMMARY = 'dictionary_summary';
export const DICTIONARY_QUIZ = 'dictionary_quiz';
export const MCQ_MISCONCEPTIONS = 'mcq_misconceptions';
export const MCQ_TYPE = 'mcq';
export const FLASHCARDS = 'flashcards';
export const MCQ_SKILL_INDENTIFIER = 'mcq_skill_identifier';
export const MCQ_DISTRACT = 'mcq_distract';
export const PHYSICS_MCQ = 'physics_mcq';
export const SUMMARY_GPT = 'summary_gpt4o';
export const PHYSICS_MCQ_QUESTION_GENERATION = 'physics_mcq_question_generation';
export const QUIZ_INTENT = 'quiz_intent';
export const QUIZ_VALIDATE_INTENT = 'quiz_validate_intent';
export const SUMMARY_TOPIC = 'summary_topic';
export const AI_STUDY_TOOLS_RESPONSE = 'AI Study Tool Response';
export const PROBLEM_SOLVE_RECTIFIER_TEXT = 'Problem Solve Rectifier';
export const HISTORY_TITLE = 'history_title';
export const CONTEXT_IDENTIFIER_SUMMARY = 'context_identifier_summary';
export const SPECIFIC_TOPIC_INTENT_IDENTIFIER = 'context_identifier_quiz';
export const SUMMARY_TOPIC_IDENTIFIER = 'context_identifier_summary_topic';
export const CHANNEL_FETCH_TYPE = 'channel_fetch';
export const GET_PI_TOKEN_DATA = 'GET_PI_TOKEN_DATA';
export const SET_PI_TOKEN_DATA = 'SET_PI_TOKEN_DATA';
export const GET_PI_TOKEN_URL = '/getpitoken';
export const GET_AI_CHARTS_UTILITY_LIST_DATA = 'GET_AI_CHARTS_UTILITY_LIST_DATA';
export const SET_AI_CHARTS_UTILITY_LIST_DATA = 'SET_AI_CHARTS_UTILITY_LIST_DATA';
export const GET_AI_CHARTS_UTILITY_DATA = 'GET_AI_CHARTS_UTILITY_DATA';
export const SET_AI_CHARTS_UTILITY_DATA = 'SET_AI_CHARTS_UTILITY_DATA';
export const GET_AI_CHARTS_FEEDBACK_URL = '/chartsutility/getfeedbackdata';
export const GET_AI_CONTENT_FILTER_CHARTS_UTILITY_DATA = 'GET_AI_CONTENT_FILTER_CHARTS_UTILITY_DATA';
export const SET_AI_CONTENT_FILTER_CHARTS_UTILITY_DATA = 'SET_AI_CONTENT_FILTER_CHARTS_UTILITY_DATA';
export const GET_AI_CONTENT_FILTER_CHARTS_FEEDBACK_URL = '/chartsutility/getcontentfilterdata';
export const GET_AI_TOPIC_CHARTS_URL = '/chartsutility/getclusterdata';
export const GET_TRENDING_TOPIC_DATA = 'GET_TRENDING_TOPIC_DATA';
export const SET_TRENDING_TOPIC_DATA = 'SET_TRENDING_TOPIC_DATA';
export const GET_AI_CONTENT_FILTER_CHARTS_DETAILS_DATA = 'GET_AI_CONTENT_FILTER_CHARTS_DETAILS_DATA';
export const SET_AI_CONTENT_FILTER_CHARTS_DETAILS_DATA = 'SET_AI_CONTENT_FILTER_CHARTS_DETAILSDATA';
export const GET_FILE_DATA = 'GET_FILE_DATA';
export const GET_MATHJAX_VERSION_SWITCH = 'mathjax-versioning/mathJaxVersionSwitch.json';
export const CHAT_INTENT_AUDIO = 'chat_intent_audio';
export const GET_TEST_SUITE_DATA = 'GET_TEST_SUITE_DATA';
export const ADD_TEST_SUITE_DATA = 'ADD_TEST_SUITE_DATA';
export const SET_TEST_SUITE_DATA = 'SET_TEST_SUITE_DATA';
export const UPDATE_TEST_SUITE_DATA = 'UPDATE_TEST_SUITE_DATA';
export const UPDATE_TEST_SUITE_DATA_URL = '/chatutility/effectiveness/updateTestSuite';
export const GET_LLM_TEST_RESULTS_DATA = 'GET_LLM_TEST_RESULTS_DATA';
export const SET_LLM_TEST_RESULTS_DATA = 'SET_LLM_TEST_RESULTS_DATA';
export const S3_EXCLUDED_USERS_LIST_PATH = 'exclude-user-list/excludedUserIds.json';
export const GET_INTERNAL_USERS_LIST_DATA = 'GET_INTERNAL_USERS_LIST_DATA';
export const SET_INTERNAL_USERS_LIST_DATA = 'SET_INTERNAL_USERS_LIST_DATA';
export const GET_CDN_TOKEN_DATA = 'GET_CDN_TOKEN_DATA';
export const SET_CDN_TOKEN_DATA = 'SET_CDN_TOKEN_DATA';
export const GET_CDN_TOKEN_URL = '/getCDNToken';
export const DRAWER_PANEL_RESOURCES = 'Resources';
export const DRAWER_STATE_CLOSED = 'closed';
export const DRAWER_STATE_OPEN = 'open';
export const DRAWER_STATE_EXPANDED = 'expanded';
export const CONTENT_TYPE_RPLUS_PDF = 'RPLUS_PDF';
export const CONTENT_TYPE_RPLUS_EPUB = 'RPLUS_EPUB';
export const CONTENT_TYPE_ETEXT_PDF = 'ETEXT_PDF';
export const RESOURCE_REQUESTED = 'RESOURCE_REQUESTED';
export const RESOURCE_FETCHED = 'RESOURCE_FETCHED';
export const USER_ROLE_TEACHER = 'Teacher';
export const USER_ROLE_INSTRUCTOR = 'instructor';
export const CONTENT_TYPE_INTERNAL_LINK = 'internal-link';
export const AUDIO_FILE_TYPE = 'audio';
export const GET_CHANNELS_DATA_URL = '/chatutility/admin/channels';
export const EDIT_LLM_TEST_SUITE_TITLE = 'Edit Test Details';
export const ADD_LLM_TEST_SUITE_TITLE = 'Add New Test Suite';
export const SUB_BUSINESS_UNIT_AUSTRALIA = 'australia';
export const SUB_BUSINESS_UNIT_ELL = 'ell';
export const IMAGE_COPILOT_EXPLAIN = 'image_copilot_explain';
export const IMAGE_COPILOT_ANSWER = 'image_copilot_answer';
export const IMAGE_COPILOT_CYU = 'image_copilot_cyu';
export const IMAGE_COPILOT_CYU_EVALUATE = 'image_copilot_cyu_evaluate';
export const IMAGE_COPILOT_INTENT_CLASSIFIER = 'image_copilot_intent_classifier';
export const IMAGE_EXPLAIN = 'image_explain';
export const IMAGE_CYU_EVALUATE = 'image_cyu_evaluate';
export const IMAGE_ASK_QUESTION = 'image_ask_question';
export const PVS_INGESTION_URL = '/chatUtility/getPVSIngestionStatus';
export const SEARCH_PVS_INGESTION_URL = '/chatUtility/searchPVSIngestion';
export const GET_PVS_TOC_DATA_URL = '/chatUtility/pvs/getTOCData';
export const CITATION_IMAGES_SUB_TYPE = 'citations_images';
export const ADDITIONAL_CONTEXT_SUB_TYPE = 'additional_context';
// Constants for Report Download functionality
export const REPORT_DOWNLOAD_SQS_SERVICE = 'REPORT_DOWNLOAD_SQS_SERVICE';
export const REPORT_DOWNLOAD_SERVICE = 'REPORT_DOWNLOAD_SERVICE';

// API Endpoints
export const GET_TENANT_LIST_DATA_URL = 'chatutility/getTenantsList';
export const GET_TENANT_LIST_DATA = 'GET_TENANT_LIST_DATA';
export const SET_TENANT_LIST_DATA = 'SET_TENANT_LIST_DATA';
export const SELECT_TENANT_LIST = 'Please Select Tenant Name and Edit Provider Details';
export const SELECT_TENANT_LABEL = 'Select Tenant';
export const SELECT_MODEL = 'Select Model';
export const SELECT_PROVIDER = 'Select Provider';
export const SELECT_FEATURE = 'Select Feature';
export const GET_PROVIDERS_LIST_DATA = 'GET_PROVIDERS_LIST_DATA';
export const SET_PROVIDERS_LIST_DATA = 'SET_PROVIDERS_LIST_DATA';
export const ADD_NEW_MODEL = 'Add New Config';
export const UPDATE_PROVIDER_DATA_URL = '/chatutility/updateProvidersList';
export const ADD_PROVIDER_DATA_URL = '/chatutility/addProvidersList';
export const GET_TENANTS_LIST_URL = '/chatutility/getTenantsList';
export const ADD_NEW_PROMPT_TYPE = 'Add New Prompt Type';
export const REPORT_DOWNLOAD_SQS_URL = '/chatgptutility/report/generatereport';
export const REPORT_DOWNLOAD_S3_URL = '/chatgptutility/report/download';
export const TENANT_ASSET_SYNC_REQUESTED = 'TENANT_ASSET_SYNC_REQUESTED';
export const LLM_NO_DATA_FOUND = 'Data not available for selected Test Suite.';
export const PAGE_MAPPING_REQUESTED = 'PAGE_MAPPING_REQUESTED';
export const PAGE_MAPPING_FETCHED = 'PAGE_MAPPING_FETCHED';
export const RPLUSDATA_REQUESTED = 'RPLUSDATA_REQUESTED';
export const RPLUSDATA_FETCHED = 'RPLUSDATA_FETCHED';
export const GET_PROMPT_VERSION_DATA = 'GET_PROMPT_VERSION_DATA';
export const SET_PROMPT_VERSION_DATA = 'SET_PROMPT_VERSION_DATA';
export const ADMIN_PROMPT_LIVE_STATUS_PUBLISH_TEXT =
  'Previous version of same prompt is already LIVE. To make this new edits LIVE, click on “Publish”';
export const GET_TENANTS_CONFIG_DATA_URL = '/chatUtility/getTenantConfig';
export const GET_TENANT_CONFIG_DATA = 'GET_TENANT_CONFIG_DATA';
export const SET_TENANT_CONFIG_DATA = 'SET_TENANT_CONFIG_DATA';
export const GET_CLUSTER_LISTING_DATA = 'GET_CLUSTER_LISTING_DATA';
export const SET_CLUSTER_LISTING_DATA = 'SET_CLUSTER_LISTING_DATA';
export const GET_CLUSTER_LISTING_LIST_DATA = 'GET_CLUSTER_LISTING_LIST_DATA';
export const SET_CLUSTER_LISTING_LIST_DATA = 'SET_CLUSTER_LISTING_LIST_DATA';
export const PROMPT_HISTORY_URL = '/prompts/history';
export const PROMPT_HISTORY_ERROR_MSG = 'History not available for this prompt type.';
