const chatData = [
  {
    id: 0,
    bookId: 'BRNT-CCD1J3NNHS',
    title: 'Campbell Biology, 12e'
  },
  {
    id: 1,
    bookId: 'c357fd4c-bb16-4dea-b89b-99344b542f2b',
    title: 'Principles of Marketing, 18e'
  },
  {
    id: 2,
    bookId: 'BRNT-2YG2D663JN2',
    title: 'Chemistry A Molecular Approach,6e'
  },
  {
    id: 3,
    bookId: '545c066b-1630-4c04-9bd4-d8c26cd2e6f6',
    title: 'Principles of Marketing, 19e'
  }
  /* {
    id: 3,
    bookId: '4FHGBOUUFNS',
    title: 'Campbell Biology'
  },
  {
    id: 4,
    bookId: 'de7c2873-1039-4e69-b1c1-bcebb989d438',
    title: 'Accounting Information Systems'
  },
  {
    id: 5,
    bookId: 'aedd368b-2c3c-4df4-a3ab-d91031a9d57e',
    title: `Prebles' Artforms`
  },
  {
    id: 6,
    bookId: '3cd6d65b-8516-4693-a947-777bd04b07d5',
    title: 'Society: The Basics'
  },
  {
    id: 7,
    bookId: '1QQYQ88Y7KR',
    title: 'Business Analytics'
  },
  {
    id: 8,
    bookId: 'LDZIA769HJ',
    title: 'Emergency Care'
  },
  {
    id: 9,
    bookId: 'c9d42891-135e-4945-b3b2-71b0de230c3a',
    title: 'Using MIS'
  },
  {
    id: 10,
    bookId: '171638',
    title: 'Human Anatomy & Physiology Laboratory Manual, Fetal Pig Version'
  },
  {
    id: 11,
    bookId: '1bb6154d-297f-4066-be68-2adcef22cc65',
    title: 'Psychology'
  },
  {
    id: 12,
    bookId: 'BRNT-4TWXOYMS0MM',
    title: 'Starting out with Python'
  },
  {
    id: 13,
    bookId: '67d4445a-0ef4-4c2e-804f-cb92a87a06d4',
    title: 'Out of Many: A History of the American People'
  }
  {
    id: 14,
    bookId: '4dd9491b-dd0c-4da4-8275-4ae51821311e',
    title: 'Pearson eText for Starting Out With Python'
  } */
];

const feedBackTypesDropdownList = [
  {
    name: 'summary',
    label: 'Summary'
  },
  {
    name: 'answer',
    label: 'Explain'
  },
  {
    name: 'quiz_mcq',
    label: 'Quiz'
  },
  {
    name: 'problem_solve',
    label: 'Problem Solve'
  },
  {
    name: 'flashcard',
    label: 'Flashcard'
  },
  {
    name: 'problem_solve_rectifier',
    label: 'Problem Solve Rectifier'
  },
  {
    name: 'image_explain',
    label: 'Image Explain'
  },
  {
    name: 'image_cyu_evaluate',
    label: 'Image CYU Evaluate'
  },
  {
    name: 'image_ask_question',
    label: 'Image Ask Question'
  }
];

const filterByChats = [
  {
    name: 'summary',
    label: 'Summary'
  },
  {
    name: 'answer',
    label: 'Explain'
  },
  {
    name: 'quiz_mcq',
    label: 'Quiz'
  },
  {
    name: 'problem_solve',
    label: 'Problem Solve'
  },
  {
    name: 'answer_cyu_mcq',
    label: 'Answer Cyu Mcq'
  },
  {
    name: 'Content Filter (Blocked)',
    label: 'Content Filter (Blocked)'
  },
  {
    name: 'chat_intent',
    label: 'Chat Intent'
  },
  {
    name: 'chat_intent_audio',
    label: 'Chat Intent (Audio)'
  },
  {
    name: 'chat_enrichment',
    label: 'Chat Enrichment'
  },
  {
    name: 'content_classifier',
    label: 'Content Classifier'
  },
  {
    name: 'context_identifier_user_selection',
    label: 'Content Identifier User Selection'
  },
  // {
  //   id: 9,
  //   name: 'chat_intent_problem_solve',
  //   label: 'Chat Intent (Problem Solve)'
  // },
  {
    name: 'dictionary_summary',
    label: 'Dictionary Summary'
  },
  {
    name: 'dictionary_quiz',
    label: 'Dictionary Quiz'
  },
  {
    name: 'explain_stand_alone',
    label: 'Explain Standalone'
  },
  {
    name: 'expertdeck_mcq',
    label: 'Expert Deck MCQ'
  },
  {
    name: 'expertdeck_free_response',
    label: 'Expert Deck Free Response'
  },
  {
    name: 'additional_context',
    label: 'Explain (Additional Context)'
  },
  {
    name: 'citations_images',
    label: 'Explain (Citation)'
  },
  {
    name: 'flashcards',
    label: 'Flashcards'
  },
  /*   {
    id: 14,
    name: 'free_response',
    label: 'Free_Response'
  }, */
  {
    name: 'image_copilot_explain',
    label: 'Image Copilot Explain'
  },
  {
    name: 'image_copilot_answer',
    label: 'Image Copilot Answer'
  },
  {
    name: 'image_copilot_cyu',
    label: 'Image Copilot CYU'
  },
  {
    name: 'image_copilot_cyu_evaluate',
    label: 'Image Copilot CYU Evaluate'
  },
  {
    name: 'image_copilot_intent_classifier',
    label: 'Image Copilot Intent Classifier'
  },
  {
    name: 'mcq',
    label: 'Mcq'
  },
  {
    name: 'mcq_misconceptions',
    label: 'Mcq_Misconceptions'
  },
  {
    name: 'mcq_skill_identifier',
    label: 'Mcq_Skill_Identifier'
  },
  {
    name: 'mcq_question_generation',
    label: 'Mcq_Question_Generation'
  },
  // {
  //   id: 15,
  //   name: 'quiz_mcq',
  //   label: 'Quiz_Mcq'
  // },

  {
    name: 'mcq_distract',
    label: 'Mcq Distract'
  },
  {
    name: 'math_mcq_question_generation',
    label: 'Math_Mcq_Question_Generation'
  },
  {
    name: 'math_mcq_core_concepts',
    label: 'Math_Mcq_Core_Concepts'
  },
  {
    name: 'math_mcq',
    label: 'Math_Mcq'
  },
  {
    name: 'math_mcq_validation',
    label: 'Math Mcq Validation'
  },
  {
    name: 'non_math_mcq_validation',
    label: 'Non_Math_Mcq_Validation'
  },
  {
    name: 'generate_python',
    label: 'Problem Solve (Python)'
  },
  {
    name: 'problem_solve_rectifier',
    label: 'Problem Solve Rectifier'
  },
  {
    name: 'physics_mcq',
    label: 'Physics_mcq'
  },
  {
    name: 'physics_mcq_validation',
    label: 'Physics Mcq Validation'
  },
  {
    name: 'physics_mcq_question_generation',
    label: 'Physics_mcq_question_generation'
  },
  {
    name: 'quiz_topic_practice1',
    label: 'Quiz Topic Practice1'
  },
  {
    name: 'quiz_topic_practice2',
    label: 'Quiz Topic Practice2'
  },
  {
    name: 'quiz_free_response',
    label: 'Quiz_Free_Response'
  },
  {
    name: 'quiz_intent',
    label: 'Quiz_Intent'
  },
  {
    name: 'quiz_validate_intent',
    label: 'Quiz_Validate_Intent'
  },
  {
    name: 'recommendations',
    label: 'Recommendations'
  },
  {
    name: 'search_overview',
    label: 'Search Overview'
  },
  {
    name: 'summary-enrichment',
    label: 'Summary Enrichment'
  },
  {
    name: 'summary_explain',
    label: 'Summary Explain'
  },
  {
    name: 'summary_topic',
    label: 'Summary Topic'
  },
  {
    name: 'salutation',
    label: 'Salutation'
  },
  {
    name: 'summary_gpt4o',
    label: 'Summary4O'
  },
  {
    name: 'summary_gen',
    label: 'Summary Gen'
  },
  {
    name: 'topic',
    label: 'Topic'
  },
  {
    name: 'quiz',
    label: 'Topic Quiz'
  },
  {
    name: 'channel_fetch',
    label: 'Topic Generation'
  }
];

const tenantData = [
  {
    id: 0,
    tenantId: '32e81240-7457-11ed-89c4-a117ea7cad6a',
    name: 'aistudytools',
    title: 'AI Study Tools',
    tabDetails: [
      {
        id: 1,
        tabName: 'Observe',
        description: 'End-to-end insights of user interaction with AI',
        tabUrl: 'observe',
        toolTipTitle: 'chatUtility.AUDIT_LOG'
      },
      {
        id: 2,
        tabName: 'Trends & Insights',
        description: 'Reports on Usage patterns and content safety',
        tabUrl: 'trends',
        toolTipTitle: 'chatUtility.TRENDS_INSIGHTS'
      },
      {
        id: 3,
        tabName: 'Configurations',
        description: 'Configure Berlin Platform to suit your needs',
        tabUrl: '',
        toolTipTitle: 'chatUtility.CONFIGURATIONS'
      },
      {
        id: 4,
        tabName: 'LLM Evaluation & Reports',
        description: 'Evaluate your LLM/RAG performance and metrics',
        tabUrl: 'promptmgt/effectiveness',
        toolTipTitle: 'chatUtility.LLM_EVALUVATION'
      },
      {
        id: 5,
        tabName: 'Prompt Management',
        description: 'Full lifecycle, from versioning and updates to production rollouts.',
        tabUrl: 'promptmgt',
        toolTipTitle: 'chatUtility.PROMPT_MANANGEMENT'
      },
      {
        id: 6,
        tabName: 'Content Management',
        description: 'Review and update pre-generated content (Summaries, Quizes)',
        tabUrl: 'titlesearch',
        toolTipTitle: 'chatUtility.SUMMARY_QUIZ_MANANGEMENT'
      }
    ]
  },
  {
    id: 1,
    tenantId: 'a7167a3e-2538-4069-8f63-ef761f75b332',
    name: 'channels',
    title: 'Channels',
    tabDetails: [
      {
        id: 1,
        tabName: 'Observe',
        description: 'End-to-end insights of user interaction with AI',
        tabUrl: 'observe',
        toolTipTitle: 'chatUtility.AUDIT_LOG'
      },
      {
        id: 2,
        tabName: 'Trends & Insights',
        description: 'Reports on Usage patterns and content safety',
        tabUrl: 'trends',
        toolTipTitle: 'chatUtility.TRENDS_INSIGHTS'
      },
      {
        id: 3,
        tabName: 'Prompt Management',
        description: 'Full lifecycle, from versioning and updates to production rollouts.',
        tabUrl: 'promptmgt',
        toolTipTitle: 'chatUtility.PROMPT_MANANGEMENT'
      }
    ]
  },
  {
    id: 2,
    tenantId: '886a344d-54c0-4f87-82ae-3fab16af7e9e',
    name: 'pvs',
    title: 'Pearson Virtual Schools',
    tabDetails: [
      {
        tabUrl: 'auditlog',
        tabName: 'Audit Logs',
        toolTipTitle: 'chatUtility.AUDIT_LOG'
      },
      {
        tabUrl: 'feedback',
        tabName: 'User Feedback',
        toolTipTitle: 'chatUtility.USER_FEEDBACK'
      },
      {
        tabUrl: 'trends',
        tabName: 'Trends & Insights',
        toolTipTitle: 'chatUtility.TRENDS_INSIGHTS'
      },
      {
        tabName: 'Prompt Management',
        tabUrl: 'promptmgt',
        toolTipTitle: 'chatUtility.PROMPT_MANANGEMENT'
      },
      {
        tabUrl: 'titlesearch',
        tabName: 'Content Management',
        toolTipTitle: 'chatUtility.SUMMARY_QUIZ_MANANGEMENT'
      }
    ],
    titles: [
      {
        id: 1,
        bookId: 'pvs-c9bc464d-bf64-44b7-9067-84644db44eea',
        title: 'Biology-A'
      },
      {
        id: 2,
        bookId: 'pvs-22706eae-864a-4467-9df9-f803ff8170c4',
        title: 'Biology-B'
      },
      {
        id: 3,
        bookId: '165733',
        title: '165733'
      },
      {
        id: 4,
        bookId: '164969',
        title: '164969'
      },
      {
        id: 5,
        bookId: '165009',
        title: '165009'
      },
      {
        id: 6,
        bookId: '165729',
        title: '165729'
      }
    ]
  },
  {
    id: 3,
    tenantId: '32e81240-7457-11e4', // TODO will update once get original tenantId
    name: 'Reader+',
    title: 'Reader+',
    tabDetails: [
      {
        tabUrl: 'auditlog',
        tabName: 'Audit Logs',
        toolTipTitle: 'chatUtility.AUDIT_LOG'
      },
      {
        tabUrl: 'feedback',
        tabName: 'User Feedback',
        toolTipTitle: 'chatUtility.USER_FEEDBACK'
      },
      {
        tabUrl: 'trends',
        tabName: 'Trends & Insights',
        toolTipTitle: 'chatUtility.TRENDS_INSIGHTS'
      }
    ],
    titles: [
      {
        id: 1,
        bookId: 'rplus-60ff996c0e429e21ee07cce2',
        title: 'Heinemann Biology'
      },
      {
        id: 2,
        bookId: '8731852dbd490a5d5c655914',
        title: 'Pearson Mathematics 10 3rd Edition - SPDF'
      },
      {
        id: 3,
        bookId: '7000fd3164d7db154ca4bc15',
        title: 'Pearson Chemistry 11 Queensland - SPDF'
      },
      {
        id: 4,
        bookId: '60ff9a120e429e21ee07cce3',
        title: 'Heinemann Biology 1 6th Edition - EPUB'
      },
      {
        id: 5,
        bookId: '76ebbb03bd490a5d5c651b62',
        title: 'Heinemann Chemistry 2 6th Edition - EPUB'
      }
    ]
  },
  {
    id: 4,
    tenantId: 'bfff63e6-3f6b-4aa9-acd8-fab7a71ee8ed',
    name: 'servicenow',
    title: 'Peri',
    tabDetails: [
      {
        tabUrl: 'auditlog',
        tabName: 'Audit Logs',
        toolTipTitle: 'chatUtility.AUDIT_LOG'
      },
      {
        tabUrl: 'feedback',
        tabName: 'User Feedback',
        toolTipTitle: 'chatUtility.USER_FEEDBACK'
      },
      {
        tabUrl: 'trends',
        tabName: 'Trends & Insights',
        toolTipTitle: 'chatUtility.TRENDS_INSIGHTS'
      }
    ],
    titles: [
      {
        id: 1,
        bookId: 'cf426e7b1bd209508f22337f034bcbe8',
        title: 'End User Technology'
      }
    ]
  },
  {
    id: 5,
    tenantId: '7b8a10a3-1326-4a16-aa92-0b82a79add01"',
    name: 'paceblueprint',
    title: 'PACE Blueprint',
    tabDetails: [
      {
        tabUrl: 'auditlog',
        tabName: 'Audit Logs',
        toolTipTitle: 'chatUtility.AUDIT_LOG'
      },
      {
        tabUrl: 'feedback',
        tabName: 'User Feedback',
        toolTipTitle: 'chatUtility.USER_FEEDBACK'
      },
      {
        tabUrl: 'trends',
        tabName: 'Trends & Insights',
        toolTipTitle: 'chatUtility.TRENDS_INSIGHTS'
      },
      {
        tabName: 'Prompt Management',
        tabUrl: 'promptmgt',
        toolTipTitle: 'chatUtility.PROMPT_MANANGEMENT'
      },
      {
        tabUrl: 'titlesearch',
        tabName: 'Content Management',
        toolTipTitle: 'chatUtility.SUMMARY_QUIZ_MANANGEMENT'
      }
    ]
  }
];

const groupData = [
  {
    id: 0,
    groupId: '1',
    name: 'types',
    title: 'Types'
  },
  {
    id: 1,
    groupId: '2',
    name: 'titles',
    title: 'Titles'
  }
];

const channelsTypeData = [
  {
    id: 1,
    name: 'proxy_content_filter',
    label: 'Content Filter'
  },
  // {
  //   id: 2,
  //   name: 'generate_answer_explaination',
  //   label: 'Explain'
  // },
  {
    id: 3,
    name: 'generate_question_intent',
    label: 'Generate Question Intent'
  },
  {
    id: 4,
    name: 'generate_question_explanation',
    label: 'Generate Question Explaination'
  },
  {
    id: 5,
    name: 'generate_video_summary',
    label: 'Generate Video Summary'
  },
  {
    id: 6,
    name: 'generate_topic_summary',
    label: 'Generate Topic Summary'
  },
  {
    id: 7,
    name: 'generate_question_solution',
    label: 'Generate Question Solution'
  },
  {
    id: 8,
    name: 'generate_question_steps_and_solution',
    label: 'Generate Question Steps And Solution'
  }
  // {
  //   id: 9,
  //   name: 'find_relevant_topics',
  //   label: 'Relevant Topic'
  // }
];

const reportsData = [
  {
    id: 0,
    reportsId: 'current_month',
    title: 'Current Month'
  },
  {
    id: 1,
    reportsId: 'last_month',
    title: 'Last Month'
  },
  {
    id: 2,
    reportsId: 'last_three_month',
    title: 'Last 3 Months'
  }
];

const reportsDevBookData = [
  {
    id: 0,
    bookId: 'BRNT-XUF8BS197',
    title: 'BRNT-XUF8BS197'
  },
  {
    id: 1,
    bookId: '47176bce-9894-4799-a962-e5bc835008de',
    title: '47176bce-9894-4799-a962-e5bc835008de'
  },
  {
    id: 2,
    bookId: '2NFUJ4O6CN',
    title: '2NFUJ4O6CN'
  }
];
const adminStatusData = [
  {
    id: 0,
    statusId: 'DRAFT',
    title: 'DRAFT'
  },
  {
    id: 1,
    statusId: 'EXPERIMENT',
    title: 'EXPERIMENT'
  },
  {
    id: 2,
    statusId: 'LIVE',
    title: 'LIVE'
  },
  {
    id: 3,
    statusId: 'ARCHIVED',
    title: 'ARCHIVED'
  }
];

const AdminPromptProperties = [
  {
    id: 'temperature',
    sliderLable: 'Temperature',
    minVal: 0,
    maxVal: 100,
    step: 0.1,
    value: 0
  },
  {
    id: 'max_tokens',
    sliderLable: 'Maximum Tokens',
    minVal: 0,
    maxVal: 5000,
    step: 1,
    value: 0
  },
  {
    id: 'top_p',
    sliderLable: 'Top P',
    minVal: 0,
    maxVal: 100,
    step: 0.1,
    value: 0
  },
  {
    id: 'frequency_penalty',
    sliderLable: 'Frequency Penalty',
    minVal: 0,
    maxVal: 100,
    step: 0.1,
    value: 0
  },
  {
    id: 'presence_penalty',
    sliderLable: 'Presence Penalty',
    minVal: 0,
    maxVal: 100,
    step: 0.1,
    value: 0
  }
];

const ChatgptConfig = {
  feedBackTypesDropdownList,
  chatData,
  filterByChats,
  tenantData,
  channelsTypeData,
  groupData,
  reportsData,
  reportsDevBookData,
  adminStatusData,
  AdminPromptProperties
};

module.exports = ChatgptConfig;
