/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import { inject, observer } from 'mobx-react';
import Framework, { shapes, LoadingHandler, StateErrorDisplay } from '@greenville/framework';
import PropTypes from 'prop-types';
import PdfViewer from './components/PdfViewer';
import Asset from './model/Asset';
import EpubAsset from './model/EpubAsset';
import Loader from '../../common/Loader';
import Notification from '../../common/components/notification/Notification';
import MediaBasepaths from '../books/models/MediaBasepaths';
import InterfaceColors from '../books/models/InterfaceModel';
import ValidatePermissions from '../users/ValidatePermissions';
import Books from '../books/models/Books';
import * as constants from '../../common/constants';
import ResourcePanel from './model/Resources';
import PageMapping from './model/PageMapping';
import RplusData from './model/RplusData';

@inject(
  'asset',
  'epubAsset',
  'booksState',
  'tocStatus',
  'basepath',
  'mediaBasepathStatus',
  'colorInterface',
  'resourcePanel',
  'epubTocStatus',
  'pageMapping',
  'rplusData'
)
@observer
class PdfViewerContainer extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    asset: shapes.modelOf(Asset).isRequired,
    epubAsset: shapes.modelOf(EpubAsset).isRequired,
    tocStatus: shapes.state.isRequired,
    booksState: shapes.modelOf(Books).isRequired,
    basepath: shapes.modelOf(MediaBasepaths).isRequired,
    mediaBasepathStatus: shapes.state.isRequired,
    colorInterface: shapes.modelOf(InterfaceColors).isRequired,
    resourcePanel: shapes.modelOf(ResourcePanel).isRequired,
    epubTocStatus: shapes.state.isRequired,
    pageMapping: shapes.modelOf(PageMapping).isRequired,
    rplusData: shapes.modelOf(RplusData).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      resources: []
    };
    this.isPdf = true;
  }

  componentDidMount() {
    const { asset, match, basepath, colorInterface, booksState, epubAsset, pageMapping, rplusData } = this.props;
    const { bookDetail } = booksState;
    colorInterface.fetch(match.params.bookid);
    if (
      bookDetail.titleType === 'PDF' ||
      bookDetail.titleType === 'ETEXT_PDF' ||
      bookDetail.titleType === 'RPLUS_PDF'
    ) {
      this.isPdf = true;
      asset.fetch(match.params.bookid);
    } else if (
      bookDetail.titleType === constants.EPUB_TITLE ||
      bookDetail.titleType === constants.CONTENT_TYPE_RPLUS_EPUB
    ) {
      this.isPdf = false;
      epubAsset.fetch(bookDetail.pulseProductID);
      rplusData.fetch(bookDetail.pulseProductID);
      pageMapping.fetch(bookDetail.pulseProductID);
    } else {
      booksState.fetchBook(match.params.bookid, true);
      Framework.getEventManager().on(constants.BOOK_USECALLBACK, () => {
        if (
          bookDetail.titleType === constants.BOOK_CONTENT_TYPE.PDF ||
          bookDetail.titleType === constants.BOOK_CONTENT_TYPE.ETEXT_PDF ||
          bookDetail.titleType === constants.RPLUS_PDF_TITLE
        ) {
          this.isPdf = true;
          asset.fetch(match.params.bookid);
        } else if (
          bookDetail.titleType === constants.EPUB_TITLE ||
          bookDetail.titleType === constants.CONTENT_TYPE_RPLUS_EPUB
        ) {
          this.isPdf = false;
          epubAsset.fetch(bookDetail.pulseProductID);
          pageMapping.fetch(bookDetail.pulseProductID);
          rplusData.fetch(bookDetail.pulseProductID);
        }
      });
    }
    basepath.fetch(match.params.bookid);
  }

  render() {
    const { mediaBasepathStatus, epubTocStatus, tocStatus } = this.props;
    const { resources } = this.state;
    return (
      <ValidatePermissions givenPermissions="authoring_can_view" fallElse>
        <LoadingHandler
          loading={(this.isPdf ? tocStatus.isPending : epubTocStatus.isPending) || mediaBasepathStatus.isPending}
          isError={
            (epubTocStatus.isError && epubTocStatus.error && epubTocStatus.error.status === 401) ||
            (mediaBasepathStatus.isError && mediaBasepathStatus.error && mediaBasepathStatus.error.status === 401) ||
            (tocStatus.isError && tocStatus.error && tocStatus.error.status === 401)
          }
          loadingContent={<Loader />}
          errorContent={
            <StateErrorDisplay
              error={epubTocStatus.error || mediaBasepathStatus.error || tocStatus.error}
              showDetails
            />
          }
          content={
            <>
              <Notification />
              <PdfViewer {...this.props} resources={resources} />
            </>
          }
        />
      </ValidatePermissions>
    );
  }
}

export default PdfViewerContainer;
