/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2025 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to pdf Hotspot
 *
 * @file PdfHotspot.js
 * @author Gowtaman Madanselvaraj
 */

import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

/**
 * A mobx model for page-mapping children
 *
 */
const Children = types.model('Children', {
  pageId: types.maybeNull(types.string),
  playOrder: types.maybeNull(types.string),
  pageUri: types.maybeNull(types.string),
  pageNumberUrn: types.maybeNull(types.string),
  pageNo: types.maybeNull(types.string),
  pageTitle: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  csgReferenceId: types.maybeNull(types.string)
});
/**
 * A mobx model for page-mapping
 *
 */
const PageMapping = types
  .model('PageMapping', {
    bookId: types.maybeNull(types.string),
    productId: types.maybeNull(types.string),
    assetType: types.maybeNull(types.string),
    productModel: types.maybeNull(types.string),
    productPlatformCode: types.maybeNull(types.string),
    serverUpdatedDateTime: types.maybeNull(types.string),
    lastUpdatedDateTime: types.maybeNull(types.string),
    children: types.array(Children)
  })
  .actions((self) => ({
    fetch(productId) {
      if (self.id === productId) {
        Framework.getEventManager().publish(constants.PAGE_MAPPING_FETCHED, {});
      } else {
        Framework.getEventManager().publish(constants.PAGE_MAPPING_REQUESTED, {
          productId
        });
      }
    },

    /**
     * Set the page mapping data to the store
     *
     * @param {Object} pageMappingData
     */
    setPageMappingData(pageMappingData) {
      applySnapshot(self, pageMappingData);
      Framework.getEventManager().publish(constants.PAGE_MAPPING_FETCHED, {});
    }
  }));

export default PageMapping;
