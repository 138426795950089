import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import { Grid, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import _ from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Loader from '../../../../common/Loader';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import * as constants from '../../../../common/constants';
import env from '../../../../common/env';
import utils from '../../../../common/utils';
import ProvidersListModel from '../../models/ProvidersList';
import TenantProviderDialog from './TenantProviderDialog';

const styles = () => ({
  flexBorder: {
    border: '1px solid grey',
    margin: '20px',
    borderRadius: '4px'
  },
  gridColumns: {
    display: 'flex',
    justifyContent: 'center'
  },
  flexContainerBottom: {
    display: 'flex',
    justifyContent: 'right',
    padding: '10px'
  },
  ButtonClass: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '50px'
  },
  button: {
    backgroundColor: '#005d83 !important',
    color: 'white !important'
  },
  textAreaSize: {
    width: '80%'
  },
  labelColumns: {
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: '30px',
    paddingBottom: '10px'
  },
  subLable: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    marginLeft: '60px !important',
    marginTop: '5px !important',
    marginBottom: '5px !important'
  },
  heading: {
    marginTop: '20px !important'
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey'
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const steps = ['Tenant Configuration', 'Add New Feature', 'LLM Configuration', 'Content Filtering', 'Text Embedding'];
const TenantTextFields = [
  {
    id: 1,
    label: 'Tenant Name',
    name: 'tenantName',
    readOnly: false
  },
  {
    id: 2,
    label: 'Tenant Key',
    name: 'tenantKey',
    readOnly: true
  },
  {
    id: 3,
    label: 'Tenant Id',
    name: 'tenantId',
    readOnly: true
  }
];

const defaultProviderFields = {
  host: '',
  uri: '',
  region: '',
  timeout: '',
  keyName: '',
  valueLocation: '',
  type: ''
};

const INITIAL_STATE = {
  tenantId: utils.uuid(),
  tenantKey: utils.uuid(),
  tenantName: '',
  productModel: '',
  isActive: '',
  feature: [],
  contentFilter: {
    ...defaultProviderFields,
    providerId: '',
    providerName: ''
  },
  llm: {
    ...defaultProviderFields,
    providerId: '',
    providerName: ''
  },
  textEmbedding: {
    ...defaultProviderFields,
    providerId: '',
    providerName: ''
  }
};

const FIELD_LABELS = {
  host: 'Host',
  uri: 'URI',
  region: 'Region',
  timeout: 'Timeout',
  keyName: 'Key Name',
  valueLocation: 'Value Location',
  type: 'Type'
};

const TenantConfigureDialog = (props) => {
  const { openDialog, classes, handleClose, providersList, GetProvidersListServiceStatus, isEdit, selectedInputValue } =
    props;
  const initialValues = isEdit
    ? {
        tenantId: selectedInputValue?.tenantId,
        tenantKey: selectedInputValue?.tenantKey,
        tenantName: selectedInputValue?.tenantName,
        productModel: selectedInputValue?.productModel,
        isActive: selectedInputValue?.isActive,
        feature: selectedInputValue?.feature
      }
    : INITIAL_STATE;
  const [inputValue, setInputValue] = useState(initialValues);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [open, setOpen] = useState(null);
  const [providers, setProviders] = useState({ llm: [], contentFilter: [], textEmbedding: [] });
  const [model, setModels] = useState([]);
  const [newModelName, setNewModelName] = useState('');
  const [newProviderName, setNewProviderName] = useState('');
  const [newPromptTypeName, setNewPromptTypeName] = useState('');
  const [showLLMFields, setShowLLMFields] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [currentProvider, setCurrentProvider] = useState('');
  // Feature
  const [selectedFeature, setSelectedFeature] = useState('');
  const [featureTypesNames, setFeatureTypesNames] = useState(initialValues.feature || []);
  const [subPromptTypeVal, setSubPromptTypeVal] = useState('');
  const [subPromptTypes, setSubPromptTypes] = useState([]);
  const [isAddingToFirst, setIsAddingToFirst] = useState(true);
  const [openFeatureDialog, setOpenFeatureDialog] = useState(false);

  useEffect(() => {
    let currentProviderVal;
    if (activeStep === 2) {
      currentProviderVal = 'llm';
    }
    if (activeStep === 3) {
      currentProviderVal = 'contentFilter';
    }
    if (activeStep === 4) {
      currentProviderVal = 'textEmbedding';
    }
    setCurrentProvider(currentProviderVal);
  }, [activeStep]);

  useEffect(() => {
    if (inputValue.tenantId && isEdit) {
      const { data } = providersList;
      const updateProviders = () => {
        const finalData = toJS(data);
        const llmProviders = [
          ...new Set(finalData?.filter((item) => item.type === 'llm').map((item) => item.providerName))
        ];
        const contentFilterProviders = [
          ...new Set(finalData?.filter((item) => item.type === 'contentFilter').map((item) => item.providerName))
        ];
        const textEmbeddingProviders = [
          ...new Set(finalData?.filter((item) => item.type === 'textEmbedding').map((item) => item.providerName))
        ];

        setProviders({
          llm: llmProviders,
          contentFilter: contentFilterProviders,
          textEmbedding: textEmbeddingProviders
        });
      };
      if (_.isEmpty(toJS(data))) {
        providersList.fetch({
          tenantId: inputValue.tenantId
        });
      }
      Framework.getEventManager().on(constants.SET_PROVIDERS_LIST_DATA, updateProviders);
    }
  }, [inputValue.tenantId, isEdit]);

  useEffect(() => {
    if (!inputValue.providerName && !currentProvider) return;
    const { data } = providersList;
    if (!data) return;
    const finalData = toJS(data);
    const modelNames = finalData
      ?.filter((item) => item.providerName === inputValue.providerName && item.type === currentProvider)
      ?.map((modelName) => modelName.model);
    setModels(modelNames);
  }, [inputValue.providerName, currentProvider]);

  useEffect(() => {
    if (!inputValue.model && !currentProvider) return;
    const { data } = providersList;
    if (!data) return;
    const finalData = toJS(data);
    const selectedModel = finalData?.find(
      (item) =>
        item.model === inputValue.model &&
        item.type === currentProvider &&
        item.providerName === inputValue.providerName
    );
    if (!selectedModel) return;
    setShowLLMFields(true);
    setInputValue((prevInputValue) => {
      const updatedInputValue = { ...prevInputValue };
      updatedInputValue[currentProvider] = {
        ...updatedInputValue[currentProvider],
        ...selectedModel
      };
      return updatedInputValue;
    });
  }, [inputValue.model, currentProvider]);

  const handleInputChange = (event) => {
    const {
      target: { name, value }
    } = event;

    if (name.startsWith('llm.') || name.startsWith('contentFilter.') || name.startsWith('textEmbedding.')) {
      const typeVal = name.split('.')[0];
      const field = name.replace(`${typeVal}.`, '');
      setInputValue((prev) => ({
        ...prev,
        [typeVal]: { ...prev[typeVal], [field]: value }
      }));
    } else {
      setInputValue((prevInputValue) => ({ ...prevInputValue, [name]: value }));
    }
  };

  const openDialogHandler = (type) => {
    setOpen(type);
  };

  const handleDialogClose = () => {
    setOpen(null);
    setNewModelName('');
    setNewProviderName('');
  };

  const handleOpenDialog = (param) => {
    setIsAddingToFirst(param);
    setOpenFeatureDialog(true);
  };

  const handleCloseFeatureDialog = () => {
    setOpenFeatureDialog(false);
    setNewPromptTypeName('');
  };

  const handleSaveFeature = () => {
    if (isAddingToFirst) {
      const newFeature = {
        display_name: newPromptTypeName,
        db_field: newPromptTypeName.toLowerCase().replace(/ /g, '_'),
        prompt_types: []
      };
      setFeatureTypesNames([...featureTypesNames, newFeature]);
    } else {
      const updatedFeature = [...featureTypesNames];
      const selectedFeatureIndex = updatedFeature.findIndex((item) => item.display_name === selectedFeature);
      if (selectedFeatureIndex !== -1) {
        updatedFeature[selectedFeatureIndex].prompt_types.push({
          display_name: newPromptTypeName,
          db_field: newPromptTypeName.toLowerCase().replace(/ /g, '_')
        });
      }
      setFeatureTypesNames(updatedFeature);
    }
    setOpenFeatureDialog(false);
    setNewPromptTypeName('');
  };

  const handleSave = (dialogType) => {
    const [type, actionType] = dialogType.split(' ');
    if (actionType === 'Provider') {
      setProviders((prevProviders) => ({
        ...prevProviders,
        [type]: [...prevProviders[type], newProviderName]
      }));
      setInputValue((prevInputValue) => {
        const updatedInputValue = { ...prevInputValue };
        updatedInputValue[type] = {
          ...updatedInputValue[type],
          providerName: newProviderName,
          type
        };
        return updatedInputValue;
      });
      setNewProviderName('');
      setOpen(null);
    } else if (actionType === 'Model') {
      setModels([...model, newModelName]);
      setInputValue((prevInputValue) => {
        const updatedInputValue = { ...prevInputValue };
        updatedInputValue[type] = {
          ...updatedInputValue[type],
          type,
          model: newModelName,
          providerName: inputValue.providerName
        };
        return updatedInputValue;
      });
      setOpen(null);
      setNewModelName('');
      setShowLLMFields(true);
    }
  };

  const handleFeatureTypeChange = (event) => {
    setSelectedFeature(event.target.value);
    setSubPromptTypeVal('');
    const selectedFeatureData = featureTypesNames?.find((feature) => feature.display_name === event.target.value);
    setSubPromptTypes(selectedFeatureData ? selectedFeatureData.prompt_types : []);
  };

  const handleSubPromptTypes = (event) => {
    const { value } = event.target;
    setSubPromptTypeVal(value);
  };

  const renderTenantDetails = () => {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ marginTop: '25px' }}>
        <Grid container direction="row" style={{ justifyContent: 'center', margin: '10px' }}>
          <Grid item xs={10} direction="row">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
              {TenantTextFields?.map((item, index) => {
                return (
                  <TextField
                    id={index}
                    label={item.label}
                    style={{ minWidth: '32%' }}
                    margin="dense"
                    name={item.name}
                    variant="outlined"
                    onChange={handleInputChange}
                    value={
                      /* eslint-disable */
                      item.name === 'tenantKey'
                        ? inputValue.tenantKey
                        : item.name === 'tenantId'
                        ? inputValue.tenantId
                        : inputValue.tenantName
                      /* eslint-disable */
                    }
                    disabled={item.readOnly}
                    required
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={10} direction="row" />
          <Grid item xs={10} direction="row">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
              <TextField
                name="productModel"
                label="Product Model"
                fullWidth
                margin="dense"
                variant="outlined"
                style={{ marginLeft: '0px', marginRight: '10px', marginTop: '30px' }}
                onChange={handleInputChange}
                value={inputValue.productModel}
              />
              <FormControl fullWidth style={{ marginTop: '30px' }}>
                <InputLabel id="isActive">Active</InputLabel>
                <Select
                  labelId="isActive"
                  name="isActive"
                  onChange={handleInputChange}
                  value={inputValue.isActive}
                  label="Active"
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={10} direction="row" />
        </Grid>
      </Grid>
    );
  };

  const renderProviderFields = (type) => {
    const { tenantId } = inputValue;

    return (
      <Grid container spacing={2} style={{ padding: '32px' }}>
        {Object.keys(defaultProviderFields).map((field) => {
          const fieldValue = inputValue[type]?.[field] ?? '';
          const label = FIELD_LABELS[field] || field;

          return (
            <Grid item xs={4} key={`${type}-${field}`}>
              <TextField
                id={`${type}-${field}`}
                name={`${type}.${field}`}
                type={label === 'Timeout' ? 'number' : 'text'}
                label={label}
                fullWidth
                variant="outlined"
                margin="dense"
                value={fieldValue}
                onChange={handleInputChange}
                disabled={!tenantId || label === 'Type'}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderProviders = (type) => {
    const typeMap = {
      llm: 'LLM',
      contentFilter: 'Content Filter',
      textEmbedding: 'Text Embedding'
    };

    return (
      <>
        <Grid item xs={16} style={{ padding: '5px' }}>
          <Typography variant="body1" align="center" gutterBottom spacing={9}>
            {isEdit ? `Edit ${typeMap[type]} Provider` : `Add ${typeMap[type]} Provider`}
          </Typography>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid container direction="row" style={{ justifyContent: 'center' }}>
            <Grid item xs={10} direction="row">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: '0px 32px',
                  marginTop: '30px',
                  flexDirection: 'column'
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <FormControl style={{ width: '60%' }}>
                    <InputLabel id={`${typeMap[type]}_provider`}>Select Provider</InputLabel>
                    <Select
                      labelId={`${typeMap[type]}provider`}
                      name="providerName"
                      onChange={handleInputChange}
                      value={inputValue.providerName}
                      label="Select Provider"
                    >
                      <MenuItem value="" disabled>
                        {providers[type]?.length > 0
                          ? `Select a ${typeMap[type]} Provider`
                          : `Click (+) to Add  ${typeMap[type]} Provider`}
                      </MenuItem>
                      {providers[type]?.map((provider, index) => (
                        <MenuItem key={index} value={provider}>
                          {provider}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div style={{ display: 'flex' }}>
                    <TooltipCustom title="Add New Provider">
                      <IconButton onClick={() => openDialogHandler(`${type} Provider`)} size="large">
                        <AddIcon />
                      </IconButton>
                    </TooltipCustom>
                  </div>
                </div>
                <br />
                <Grid item xs={12} style={{ width: '100%' }}>
                  <Divider />
                </Grid>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <FormControl style={{ width: '60%' }}>
                    <InputLabel id={`${typeMap[type]}_model`}>{constants.SELECT_MODEL}</InputLabel>
                    <Select
                      labelId={`${typeMap[type]}_model`}
                      name="model"
                      onChange={handleInputChange}
                      value={inputValue.model}
                      label={constants.SELECT_MODEL}
                      disabled={!inputValue?.providerName}
                    >
                      <MenuItem value="" disabled>
                        {model.length > 0 ? 'Select a Model' : 'Click (+) to Add Model'}
                      </MenuItem>
                      {model?.map((modelVal, index) => (
                        <MenuItem key={index} value={modelVal}>
                          {modelVal}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div style={{ display: 'flex' }}>
                    <TooltipCustom title="Add New Model">
                      <IconButton
                        onClick={() => openDialogHandler(`${type} Model`)}
                        size="large"
                        disabled={!inputValue?.providerName}
                      >
                        <AddIcon />
                      </IconButton>
                    </TooltipCustom>
                  </div>
                </div>
                <br />
                <Grid item xs={12} style={{ width: '100%' }}>
                  <Divider />
                </Grid>
                <br />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {showLLMFields && renderProviderFields(type)}
      </>
    );
  };

  const renderAddNewFeature = () => {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid container direction="row" style={{ justifyContent: 'center' }}>
          <Grid item xs={10} direction="row">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: '0px 32px',
                marginTop: '30px',
                flexDirection: 'column'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <FormControl style={{ width: '60%' }}>
                  <InputLabel id="feature">{constants.SELECT_FEATURE}</InputLabel>
                  <Select
                    labelId="feature"
                    name="display_name"
                    onChange={handleFeatureTypeChange}
                    value={selectedFeature}
                    label={constants.SELECT_FEATURE}
                  >
                    <MenuItem value="" disabled>
                      Click (+) to Add Prompt Feature
                    </MenuItem>
                    {featureTypesNames?.map((feature, index) => (
                      <MenuItem key={feature.db_field} value={feature.display_name}>
                        {feature.display_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div style={{ display: 'flex' }}>
                  <TooltipCustom title="Add New Feature">
                    <IconButton onClick={() => handleOpenDialog(true)} size="large">
                      <AddIcon />
                    </IconButton>
                  </TooltipCustom>
                </div>
              </div>
              <br />
              <Grid item xs={12} style={{ width: '100%' }}>
                <Divider />
              </Grid>
              <Grid item xs={16} style={{ padding: '5px' }}>
                <Typography variant="body1" align="center" gutterBottom spacing={9}>
                  {isEdit ? 'Add/Edit Sub Prompt Type' : 'Add Sub Prompt Type'}
                </Typography>
              </Grid>
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <FormControl style={{ width: '60%' }}>
                  <InputLabel id="prompt_types">Type</InputLabel>
                  <Select
                    labelId="prompt_types"
                    name="prompt_types"
                    onChange={handleSubPromptTypes}
                    value={subPromptTypeVal}
                    label="Type"
                    disabled={!selectedFeature}
                  >
                    <MenuItem value="" disabled>
                      Click (+) to Add Sub Prompt Type
                    </MenuItem>
                    {subPromptTypes?.map((typeName) => (
                      <MenuItem key={typeName.db_field} value={typeName.display_name}>
                        {typeName.display_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div style={{ display: 'flex' }}>
                  <TooltipCustom title="Add Sub Prompt Type">
                    <IconButton onClick={() => handleOpenDialog(false)} size="large" disabled={!selectedFeature}>
                      <AddIcon />
                    </IconButton>
                  </TooltipCustom>
                </div>
              </div>
              <br />
              <Grid item xs={12} style={{ width: '100%' }}>
                <Divider />
              </Grid>
              <br />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const defaultSteps = [
    {
      id: 1,
      step: <>{renderTenantDetails()}</>
    },
    {
      id: 2,
      step: <>{renderAddNewFeature()}</>
    },
    {
      id: 3,
      step: <>{renderProviders('llm')}</>
    },
    {
      id: 4,
      step: <>{renderProviders('contentFilter')}</>
    },
    {
      id: 5,
      step: <>{renderProviders('textEmbedding')}</>
    }
  ];

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleCloseTenant = () => {
    setInputValue(INITIAL_STATE);
    providersList.resetStoreValues();
    handleClose();
  };

  const submitData = async () => {
    setApiLoading(true);
    const token = utils.getToken();
    const headers = { Authorization: `Bearer ${token}` };
    const url = isEdit
      ? `${env.EVERGREEN_API_BASE_URL}${constants.UPDATE_PROVIDER_DATA_URL}`
      : `${env.EVERGREEN_API_BASE_URL}${constants.ADD_PROVIDER_DATA_URL}`;
    const inputData = [];
    const addToInputData = (key) => {
      if (!_.isEmpty(inputValue?.[key])) {
        inputData.push({
          ...inputValue[key],
          tenantId: inputValue.tenantId,
          productModel: inputValue.productModel,
          tenantKey: inputValue.tenantKey,
          tenantName: inputValue.tenantName,
          feature: featureTypesNames,
          isActive: inputValue.isActive
        });
      }
    };

    ['contentFilter', 'llm', 'textEmbedding'].forEach(addToInputData);

    if (inputData.length === 0) {
      const notification = {
        open: true,
        type: 'error',
        message: 'No provider data found to submit'
      };
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
      setApiLoading(false);
      return;
    }
    const finalValues = inputData.filter((item) => ['llm', 'contentFilter', 'textEmbedding'].includes(item.type));
    try {
      for (let data of finalValues) {
        try {
          const response = await axios.post(url, data, { headers });
          if (response?.data?.data) {
            const notification = {
              open: true,
              type: 'success',
              message: isEdit ? 'Data Updated successfully' : 'Provider Data Added successfully'
            };
            Framework.getStoreRegistry().getStore('notificationState').set(notification);
          } else {
            const notification = {
              open: true,
              type: 'error',
              message: isEdit ? 'Data not Updated' : 'Something went wrong with provider type: ' + data.type
            };
            Framework.getStoreRegistry().getStore('notificationState').set(notification);
          }
        } catch (error) {
          console.error(`Error calling API for ${data.type}:`, error);
          const notification = {
            open: true,
            type: 'error',
            message: `API call failed for provider type: ${data.type}. Please try again.`
          };
          Framework.getStoreRegistry().getStore('notificationState').set(notification);
        }
      }
    } catch (error) {
      console.error('Unexpected error during API calls:', error);
      const notification = {
        open: true,
        type: 'error',
        message: 'Unexpected error. Please try again.'
      };
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
    } finally {
      setApiLoading(false);
      handleCloseTenant();
    }
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    if (activeStep === steps.length - 1) {
      submitData();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setInputValue(INITIAL_STATE);
  };

  return (
    <LoadingHandler
      loading={GetProvidersListServiceStatus.isPending || apiLoading}
      loadingContent={<Loader />}
      content={
        <>
          <Dialog fullScreen open={openDialog} onClose={handleCloseTenant} TransitionComponent={Transition}>
            <div className={classes.flexBorder}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} style={{ flexDirection: 'column' }}>
                  <Box display="flex" style={{ justifyContent: 'flex-end', marginTop: '20px', marginRight: '20px' }}>
                    <IconButton edge="start" color="default" onClick={() => handleCloseTenant()} aria-label="close">
                      <Tooltip title="Close">
                        <CloseIcon />
                      </Tooltip>
                    </IconButton>
                  </Box>
                </Grid>
              </Box>
              <Box>
                <Grid item xs={16}>
                  <Typography variant="h5" align="center" gutterBottom spacing={9}>
                    {isEdit ? 'Edit Tenant Provider Details' : 'Configure New Tenant Provider'}
                  </Typography>
                </Grid>
              </Box>
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === steps.length ? (
                  <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button variant="contained" size="small" onClick={handleReset} className={classes.button}>
                        Reset
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Paper style={{ margin: '10px' }}>
                      <Box sx={{ width: '100%' }}>
                        {defaultSteps &&
                          defaultSteps.filter((item) => item.id === activeStep + 1).map((item) => <>{item.step}</>)}
                      </Box>
                    </Paper>
                    <div className={classes.flexContainerBottom}>
                      <div className={classes.ButtonClass}>
                        <Stack direction="row" spacing={2}>
                          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                              variant="contained"
                              size="small"
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              sx={{ mr: 1 }}
                              className={classes.button}
                            >
                              Back
                            </Button>
                            <Button variant="contained" size="small" onClick={handleNext} className={classes.button}>
                              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                          </Box>
                        </Stack>
                      </div>
                    </div>
                  </>
                )}
              </Box>
              <br />
            </div>
          </Dialog>
          <TenantProviderDialog
            open={open && open.includes('Provider')}
            title="Add New Provider"
            fields={[
              { label: 'Provider Name', value: newProviderName, onChange: (e) => setNewProviderName(e.target.value) }
            ]}
            handleClose={handleDialogClose}
            handleSave={handleSave}
            dialogType={open}
          />
          <TenantProviderDialog
            open={open && open.includes('Model')}
            title="Add New Model"
            fields={[{ label: 'Model Name', value: newModelName, onChange: (e) => setNewModelName(e.target.value) }]}
            handleClose={handleDialogClose}
            handleSave={handleSave}
            dialogType={open}
          />
          <Dialog open={openFeatureDialog} onClose={handleCloseFeatureDialog} PaperProps={{ style: { width: '80%' } }}>
            <DialogTitle>Add New Value</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Enter new value"
                fullWidth
                value={newPromptTypeName}
                onChange={(e) => setNewPromptTypeName(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseFeatureDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSaveFeature} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>
      }
    />
  );
};

TenantConfigureDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  selectedInputValue: PropTypes.object.isRequired,
  providersList: shapes.modelOf(ProvidersListModel).isRequired,
  GetProvidersListServiceStatus: shapes.state.isRequired
};

export default withStyles(styles)(
  inject('providersList', 'GetProvidersListServiceStatus')(observer(TenantConfigureDialog))
);
