/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';
import env from '../../../common/env';

/**
 * A mobx model for asset audio meta data
 *
 */
const AssetTypes = types.model('AssetTypes', {
  locale: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  versionId: types.maybeNull(types.string),
  voiceName: types.maybeNull(types.string),
  uri: types.maybeNull(types.string)
});

const AudioMetaData = types.model('AudioMetaData', {
  assetTypes: types.maybeNull(types.array(AssetTypes)),
  basePath: types.maybeNull(types.string),
  srcUri: types.maybeNull(types.string),
  wordBoundaryAvailable: types.maybeNull(types.boolean)
});

/**
 * A mobx model for asset children
 *
 */
const Children = types.model('Children', {
  title: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  id: types.maybeNull(types.union(types.string, types.integer, types.number)),
  uri: types.maybeNull(types.union(types.string, types.integer, types.number)),
  playOrder: types.maybeNull(types.union(types.string, types.integer, types.number)),
  sectionType: types.maybeNull(types.string),
  children: types.array(types.late(() => Children)),
  pageno: types.maybeNull(types.union(types.string, types.integer, types.number)),
  linkTypeID: types.maybeNull(types.union(types.string, types.integer, types.number)),
  regionTypeID: types.maybeNull(types.union(types.string, types.integer, types.number)),
  role: types.maybeNull(types.string),
  audio: types.maybeNull(types.array(types.string)),
  optional: types.maybeNull(types.union(types.string, types.boolean)), // TODO Remove union for optional
  versionId: types.maybeNull(types.string),
  parentId: types.maybeNull(types.string),
  parentVersionId: types.maybeNull(types.string),
  usageType: types.maybeNull(types.string),
  external: types.maybeNull(types.boolean, false),
  audioTTSMetadata: types.maybeNull(types.string),
  label: types.maybeNull(types.string)
});

/**
 * A mobx model for asset slates
 *
 */
const Slates = types.model('Slates', {
  chapterUri: types.maybeNull(types.string),
  parentUri: types.maybeNull(types.string),
  moduleUri: types.maybeNull(types.string),
  pageno: types.maybeNull(types.union(types.string, types.integer, types.number)), // TODO Remove union for pageno
  optional: types.maybeNull(types.union(types.string, types.boolean)), // TODO Remove union for optional
  id: types.maybeNull(types.union(types.string, types.integer, types.number)), // TODO Remove union for id
  type: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  uri: types.maybeNull(types.string),
  playOrder: types.maybeNull(types.union(types.string, types.integer, types.number)), // TODO Remove union for playorder
  parentid: types.maybeNull(types.union(types.string, types.integer, types.number)), // TODO Remove union for parentid
  audio: types.maybeNull(types.array(types.string)),
  index: types.maybeNull(types.number),
  pagenumber: types.maybeNull(types.union(types.string, types.integer, types.number))
});

/**
 * A mobx model for asset
 *
 */
const Asset = types
  .model('Asset', {
    assetId: types.maybeNull(types.string),
    assetVersionId: types.maybeNull(types.string),
    audioMetadata: types.maybeNull(AudioMetaData),
    id: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    playOrder: types.optional(types.string, '0'),
    productId: types.optional(types.string, ''),
    children: types.array(Children),
    slates: types.maybeNull(types.array(Slates)),
    contentType: types.maybeNull(types.string, constants.BOOK_CONTENT_TYPE.EPUB),
    bookId: types.maybeNull(types.string)
  })
  .views((self) => ({
    /**
     * Method to get playlist
     *
     * @param {string} bundleId
     * @param {Object} product
     * @param {Boolean} serverSideEnabled
     */
    getPagePlaylist(product, pageMapping = null, version) {
      const v = `v${version.split('.')[0]}`;
      // const { format } = product;
      const playlist = [];
      // const page = language.getMessage('navigation.PAGE');
      /**
       * If book format is Epub
       * Execute below block of code
       */
      if (self.slates && pageMapping) {
        const pagesData = pageMapping.children;
        const { bookId } = self;
        const epubBasePath = `${env.EPUB_CLOUD_FRONT_URL}/${bookId}/${v}/`;
        self.slates.forEach((item) => {
          const mappedPage = pagesData.find((page) => item.uri === page.pageUri);
          const pageData = {
            href: `${epubBasePath}${item.uri}`,
            id: item.uri.indexOf('/') > -1 ? item.uri.split('\\').pop().split('/').pop() : item.uri,
            title: mappedPage ? mappedPage.pageNo : '',
            index: mappedPage ? mappedPage.playOrder - 1 : item.playOrder - 1,
            pagenumber: mappedPage ? mappedPage.pageNo : '',
            // title: '',
            // index: item.playOrder - 1,
            // pagenumber: '',
            type: 'page',
            uri: `${epubBasePath}${item.uri}`
          };
          playlist.push(pageData);
        });

        return playlist;
      }
      return false;
    },

    getEpubAssetToc(items) {
      // return TocMapper.tocData(tocChildren);
      const contents = [];
      // eslint-disable-next-line no-unused-vars
      const audio = null;
      // eslint-disable-next-line no-unused-vars
      const parentId = '';

      function drillToDeep(item) {
        const node = item;
        const href = node.href || node.uri || '';
        const hrefUri = href;
        // eslint-disable-next-line
        const isExternal = href.startsWith('https://') ? true : false;
        const list = {
          // eslint-disable-next-line
          id: isExternal ? node.id : href.indexOf('/') > -1 ? href.split('\\').pop().split('/').pop() : href,
          label: node.title || node.label,
          // eslint-disable-next-line
          urn: href.indexOf('/') > -1 ? href.split('\\').pop().split('/').pop() : href,
          level: node.level,
          // eslint-disable-next-line
          isExternal: isExternal
        };

        /**
         * Add to list only if below attrs are available
         */
        if (hrefUri) {
          list.href = hrefUri;
          list.url = hrefUri;
        }
        if (node.audio) {
          list.audio = node.audio;
          list.audioTTSMetadata = node.audioTTSMetadata;
        }
        const children = node.items || node.children || node.content;
        if (children) {
          list.children = [];
          children.forEach((child) => {
            // eslint-disable-next-line no-param-reassign
            child.level = node.level + 1;
            list.children.push(drillToDeep(child));
          });
        }

        return list;
      }
      if (items) {
        items.forEach((node) => {
          if (!node.part) {
            // eslint-disable-next-line no-param-reassign
            node.level = 1;
            const nodes = drillToDeep(node, node.id);
            contents.push(nodes);
          }
        });
      }
      const optionalList = [];
      let list = contents.filter((chapter) => {
        if (chapter.optional) {
          optionalList.push(chapter);
        }

        return !chapter.optional;
      });

      list = Array.prototype.concat.apply(list, optionalList);
      const tocData = {
        children: list
      };

      return tocData;
    }
    /**
     * Method to get epub toc
     *
     * @param {Array} tocChildren
     */
  }))
  .actions((self) => ({
    fetch(id) {
      Framework.getEventManager().publish(constants.EPUB_TOC_DATA_FETCHED, { id });
    },
    set(asset) {
      const updatedAsset = asset;
      applySnapshot(self, updatedAsset);
    }
  }));

export default Asset;
