import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const ClusterListData = types.model('ClusterListData', {
  clusterName: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  clusterId: types.maybeNull(types.string),
  bookId: types.maybeNull(types.string),
  channelsId: types.maybeNull(types.string),
  userCommand: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  dateStamp: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string)
});

const typeString = types.model('typeString', {
  S: types.maybeNull(types.string)
});
const lastEvaluvatedKey = types.model('lastEvaluvatedKey', {
  bookId: types.maybeNull(typeString),
  channelsId: types.maybeNull(typeString),
  clusterId: types.maybeNull(typeString),
  serverDateTime: types.maybeNull(typeString),
  type: types.maybeNull(typeString),
  referenceId: types.maybeNull(typeString),
  userId: types.maybeNull(typeString)
});

const ExclusiveStartKey = types.model('ExclusiveStartKey', {
  ExclusiveStartKey: types.optional(lastEvaluvatedKey, {})
});

const ClusterList = types
  .model('ClusterList', {
    data: types.optional(types.array(ClusterListData), []),
    isNextPageKey: types.optional(ExclusiveStartKey, {})
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_CLUSTER_LISTING_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response.data);
      Framework.getEventManager().publish(constants.SET_CLUSTER_LISTING_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
      applySnapshot(self.isNextPageKey, {});
      Framework.getEventManager().publish(constants.SET_CLUSTER_LISTING_DATA);
    }
  }));

export default ClusterList;
