/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2025 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
/* eslint-disable */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import Grid from '@material-ui/core/Grid';
import {
  Typography,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogContent,
  Paper,
  Box,
  IconButton,
  TextareaAutosize,
  InputLabel
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { shapes } from '@greenville/framework';

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { Accordion, AccordionDetails, Chip } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, { accordionSummaryClasses } from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import utils from '../../../../common/utils';
import _ from 'lodash';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.6)
    }
  },
  titleAuthorPadding: {
    paddingTop: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  titleMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: theme.spacing(1.5)
  },
  contentMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: 'inherit',
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '12px'
    }
  },
  dialogContent: {
    padding: '4px 20px'
  },
  dialogPaper: {
    minHeight: '60vh',
    maxWidth: '80%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 13
  },
  bookInfoText: {
    color: '#6a7070',
    fontSize: 13,
    paddingLeft: theme.spacing(0.6)
  },
  statusText: {
    textAlign: 'center',
    fontSize: 13
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.7),
    color: theme.palette.grey[700]
  },
  heading: {
    fontSize: '15px !important',
    fontWeight: '600 !important'
  },
  subheading: {
    fontSize: '14px !important',
    marginBottom: '5px'
  },
  accordionDetails: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  activityType: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  statusMessage: {
    display: 'flex !important',
    flexDirection: 'column !important',
    width: '100% !important',
    alignItems: 'center !important',
    marginTop: '15px !important'
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  textAreaSize: {
    width: '100%',
    height: '200px !important',
    overflowY: 'auto !important',
    scrollbarWidth: 'auto',
    resize: 'none'
  },
  commentArea: {
    width: '100%',
    height: '50px !important',
    overflowY: 'auto !important',
    scrollbarWidth: 'auto',
    resize: 'none'
  },
  title: {
    marginBottom: '10px',
    fontSize: '14px'
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, toolTip, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <TooltipCustom title={toolTip}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </TooltipCustom>
      ) : null}
    </MuiDialogTitle>
  );
});

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
    transform: 'rotate(90deg)'
  },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1)
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)'
  })
}));

const HistoryDialog = ({ language, classes, openhistory, close, rowData }) => {
  const renderPromptData = (promptData, label) => {
    return (
      <>
        <Grid item xs={12}>
          <Divider className={classes.heading}>
            <Chip label={label} size="medium" />
          </Divider>
        </Grid>
        {promptData?.map((promptStartVal) => (
          <Grid item xs={12} key={promptStartVal.role}>
            <Grid item xs={12}>
              <InputLabel id={promptStartVal.role} className={classes.subheading}>
                Role: {promptStartVal.role}
              </InputLabel>
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                className={classes.textAreaSize}
                id={`${promptStartVal.role}_promptStart_id`}
                value={promptStartVal.content}
                disabled
                minRows={6}
                maxRows={12}
              />
            </Grid>
          </Grid>
        ))}
      </>
    );
  };

  const renderCommonInfo = (data) => (
    <>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          <strong>Version:</strong> {data?.version}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          <strong>Status:</strong> {data?.status}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          <strong>Modified By:</strong> {data?.modifiedBy}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          <strong>Updated Date:</strong> {utils.convertServerDateTime(data?.updatedDate)}
        </Typography>
      </Grid>
    </>
  );
  return (
    <Paper>
      <Box>
        <Dialog
          open={openhistory}
          onClose={close}
          fullWidth="true"
          direction="column"
          classes={{ paper: classes.dialogPaper }}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={close}
            align="center"
            toolTip={<FormattedMessage {...language.getText('common.CLOSE')} />}
          >
            <Typography variant="h3">
              <FormattedMessage {...language.getText('prompt.HISTORY')} /> for {rowData[0]?.type}
            </Typography>
          </DialogTitle>
          <DialogContent>
            {rowData?.map((data, index) => (
              <Accordion key={index} style={{ minHeight: '60px' }}>
                <AccordionSummary aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div>
                      <Typography className={classes.subheading}>
                        <strong>Version:</strong> {data?.newPromptpayload?.version}
                      </Typography>
                      <Typography className={classes.subheading}>
                        <strong>Status:</strong> {data?.newPromptpayload?.status}
                      </Typography>
                    </div>
                    <div>
                      <Typography className={classes.subheading}>
                        <strong>Modified By:</strong> {data?.edited_by}
                      </Typography>
                      <Typography className={classes.subheading}>
                        <strong>Updated At:</strong> {utils.convertServerDateTime(data?.date_edited)}
                      </Typography>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <TableContainer className={classes.root} component={Paper}>
                    <Table className={classes.metaRoot} stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" style={{ width: '50%' }}>
                            <Typography variant="h6">Old Prompt</Typography>
                          </TableCell>
                          <TableCell align="center" style={{ width: '50%' }}>
                            <Typography variant="h6">New Prompt</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6">
                              {!_.isEmpty(data?.oldPromptpayload) && (
                                <>
                                  {renderCommonInfo(data?.oldPromptpayload)}
                                  {renderPromptData(data?.oldPromptpayload?.promptStart, 'Prompt Start')}
                                  {renderPromptData(data?.oldPromptpayload?.promptEnd, 'Prompt End')}
                                  <Grid item xs={12}>
                                    <InputLabel className={classes.subheading}>Comments</InputLabel>
                                    <TextareaAutosize
                                      className={classes.commentArea}
                                      value={data?.oldPromptpayload?.comments}
                                      disabled
                                      minRows={6}
                                      maxRows={12}
                                    />
                                  </Grid>
                                </>
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6">
                              {data?.newPromptpayload && (
                                <>
                                  {renderCommonInfo(data?.newPromptpayload)}
                                  {renderPromptData(data?.newPromptpayload?.promptStart, 'Prompt Start')}
                                  {renderPromptData(data?.newPromptpayload?.promptEnd, 'Prompt End')}
                                  <Grid item xs={12}>
                                    <InputLabel className={classes.subheading}>Comments</InputLabel>
                                    <TextareaAutosize
                                      className={classes.commentArea}
                                      value={data?.newPromptpayload?.comments}
                                      disabled
                                      minRows={6}
                                      maxRows={12}
                                    />
                                  </Grid>
                                </>
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
            {rowData.length < 1 && (
              <Grid item xs={12}>
                <Typography style={{ textAlign: 'center' }}> No Records Founds.</Typography>
              </Grid>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </Paper>
  );
};

HistoryDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  openhistory: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired
};

export default withStyles(styles)(inject('language')(observer(HistoryDialog)));
