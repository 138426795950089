/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2025 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import Framework, { BaseService } from '@greenville/framework';
import { RPLUSDATA_REQUESTED } from '../../../common/constants';

export default class RplusService extends BaseService {
  constructor() {
    super(RPLUSDATA_REQUESTED, 'rplusStatus');
  }

  handleEvent(eventName, eventData) {
    return this.getClient('evergreen').get(`books/${eventData.pid}/rplus`);
  }

  handleResponse(response) {
    Framework.getStoreRegistry().getStore('rplusData').setRplusData(response.data);
  }
}
