import Framework from '@greenville/framework';
import { applySnapshot, types } from 'mobx-state-tree';
import * as constants from '../../../common/constants';

const PropertiesRes = types.model('PropertiesRes', {
  n: types.maybeNull(types.number),
  presence_penalty: types.maybeNull(types.number),
  frequency_penalty: types.maybeNull(types.number),
  stop: types.maybeNull(types.string),
  stream: types.maybeNull(types.boolean),
  temperature: types.maybeNull(types.number),
  top_p: types.maybeNull(types.number),
  max_tokens: types.maybeNull(types.number)
});

const PromptProperties = types
  .model('PromptProperties', {
    tenantId: types.maybeNull(types.string),
    feature: types.maybeNull(types.string),
    model: types.maybeNull(types.string),
    modelId: types.maybeNull(types.string),
    id: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    version: types.maybeNull(types.string),
    properties: types.maybeNull(types.optional(PropertiesRes, {})),
    createdBy: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.number),
    updatedAt: types.maybeNull(types.number)
  })
  .actions((self) => ({
    fetchProperties(payload) {
      Framework.getEventManager().publish(constants.GET_PROPERTIES_ADMIN_DATA, {
        payload
      });
    },
    saveProperties(payload) {
      Framework.getEventManager().publish(constants.SAVE_PROPERTIES_ADMIN_DATA, {
        payload
      });
    },
    updateProperties(payload) {
      Framework.getEventManager().publish(constants.EDIT_PROPERTIES_ADMIN_DATA, {
        payload
      });
    },
    setPropertiesResponse(response) {
      applySnapshot(self, response.data);
      Framework.getEventManager().publish(constants.SET_PROPERTIES_ADMIN_DATA);
    }
  }));

export default PromptProperties;
