/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2025 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import { RPLUSDATA_REQUESTED } from '../../../common/constants';

/**
 * A mobx model for RplusData
 *
 */
const RplusData = types
  .model('RplusData', {
    version: types.maybeNull(types.string),
    height: types.maybeNull(types.string),
    width: types.maybeNull(types.string)
  })
  .actions((self) => ({
    /* eslint-disable no-param-reassign */
    fetch(id) {
      const request = {
        pid: id
      };
      Framework.getEventManager().publish(RPLUSDATA_REQUESTED, request);
    },
    /**
     * Set the page mapping data to the store
     *
     * @param {Object} RplusDatas
     */

    setRplusData(RplusDatas) {
      applySnapshot(self, RplusDatas);
    }
    /* eslint-enable no-param-reassign */
  }));

export default RplusData;
