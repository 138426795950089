/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Dev environment overrides.
 */

const dev = {
  ENVIRONMENT: 'dev',
  PARTNER_ENV: 'dev',
  DELAYED_GTM: false,
  GTM_CONTAINER_ID: 'GTM-WTX7GQN',
  GTM_AUTH: 'fhlb6akAre6D68G4ut5Y7A',
  GTM_PREVIEW: 'env-4',
  AZURE_REDIRECT_URI: 'https://evergreen-dev.pearson.com/',
  EPUB_CLOUD_FRONT_URL: 'https://d358nny7hi1tqb.cloudfront.net'
};

export default dev;
