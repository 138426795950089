/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Production environment overrides.
 *
 */

const prod = {
  ENVIRONMENT: 'prod',
  PARTNER_ENV: 'prod',
  DELAYED_GTM: false,
  GTM_CONTAINER_ID: 'GTM-WTX7GQN',
  GTM_AUTH: 'ikT7kCxv3rZkV9tHl_-ycA',
  GTM_PREVIEW: 'env-1',
  INT_LOGIN_URL: 'https://mycloud.pearson.com/redirect',
  INT_AZURE_CLIENT_ID: 'f5bd2701-6e7b-4400-b4c1-e72926069b38',
  AZURE_TENANT_ID: '8cc434d7-97d0-47d3-b5c5-14fe0e33e34b',
  AZURE_REDIRECT_URI: 'https://evergreen.pearson.com/',
  EPUB_CLOUD_FRONT_URL: 'https://d3hh25yitpz5ub.cloudfront.net'
};

export default prod;
