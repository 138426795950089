import Framework, { shapes } from '@greenville/framework';
import { Grid, TextField, TextareaAutosize } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Slide from '@mui/material/Slide';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import TooltipCustom from '../../../../../common/components/TooltipCustom';
import ChatgptConfig from '../../../../../common/config/ChatgptConfig';
import ColumnConfig from '../../../../../common/config/ColumnConfig';
import * as constants from '../../../../../common/constants';
import chatGptAdminPromptsData from '../../../models/ChatGptAdminPrompts';
import ChatgptAdmin from '../../../models/ChatgptAdmin';
import PromptModelData from '../../../models/PromptModels';
import PromptProperties from '../../../models/PromptProperties';
import PromptVersionModel from '../../../models/PromptVersion';
import DialogConfirms from '../../tocdialogues/McqDialogConfirms';

const styles = () => ({
  flexBorder: {
    border: '1px solid grey',
    margin: '20px',
    borderRadius: '4px'
  },
  gridColumns: {
    display: 'flex',
    justifyContent: 'center'
  },
  flexContainerBottom: {
    display: 'flex',
    justifyContent: 'right',
    borderTop: '1px solid grey',
    borderBottom: '1px solid grey',
    padding: '10px'
  },
  ButtonClass: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '50px'
  },
  textAreaSize: {
    width: '80%'
  },
  labelColumns: {
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: '20px',
    paddingBottom: '10px',
    paddingTop: '10px'
  },
  subLable: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    marginLeft: '60px !important',
    marginTop: '5px !important',
    marginBottom: '5px !important'
  },
  heading: {
    marginTop: '20px !important'
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey'
  },
  properties: {
    width: '380px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    marginTop: '25px',
    padding: '15px'
  },
  subSliderLable: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    marginTop: '5px !important',
    marginBottom: '5px !important'
  },
  sliderLabelColumns: {
    display: 'flex',
    justifyContent: 'start',
    paddingBottom: '10px',
    paddingTop: '10px'
  },
  helperTextClass: {
    display: 'flex',
    justifyContent: 'end',
    width: '75%',
    fontSize: '12px',
    fontStyle: 'italic',
    marginLeft: '3%'
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PromptPropertiesDialog = (props) => {
  const {
    openDialog,
    handleClose,
    selectedValue,
    classes,
    isEdit,
    tenantKey,
    chatgptAdminPrompts,
    permissionsList,
    language,
    promptProperties,
    promptModels,
    promptVersion,
    tenantIdValue,
    featureData
  } = props;
  const [isDialogboxOpen, setDialogboxOpen] = useState(false);
  const [DialogContent, setDialogContent] = useState('');
  const [inputValue, setInputValue] = useState({ ...selectedValue, version: isEdit ? selectedValue.version : '1.0.0' });
  const [promptAddStartData, setPromptAddStartData] = useState(ColumnConfig.promptConfig);
  const [promptAddEndData, setPromptAddEndData] = useState(ColumnConfig.promptConfig);
  const [isPublish, setIsPublish] = useState(false);
  const [isEditPubishArchived, setIsEditPubishArchived] = useState(false);
  // Properties States
  const [modelData, setModelData] = useState([]);
  const [selectedModel, setSelectedModel] = useState({ model: '', modelId: '' });
  const [stream, setStream] = useState(false);
  const [stop, setStop] = useState(null);
  const [sliderValues, setSliderValues] = useState(ChatgptConfig.AdminPromptProperties);
  const [propertiesDetails, setPropertiesDetails] = useState({});
  const isLiveStatus = inputValue?.status === constants.PROMPT_LIVE_STATUS;
  const [latestVersion, setLatestVersion] = useState('');
  const [types, setTypes] = useState([]);

  const compareVersions = (version1, version2) => {
    if (_.isEmpty(version1) || _.isEmpty(version2)) return true;
    let checkVersion = false;
    const parts1 = version1.split('.').map(Number);
    const parts2 = version2.split('.').map(Number);
    const length = Math.max(parts1.length, parts2.length);
    for (let i = 0; i < length; i += 1) {
      const v1 = parts1[i] || 0;
      const v2 = parts2[i] || 0;
      if (v1 > v2) {
        checkVersion = false;
        break;
      }
      if (v1 < v2) {
        checkVersion = true;
        break;
      }
    }
    return checkVersion;
  };

  const checkForLatestVersion = () => {
    promptVersion.fetch({ type: inputValue.type, tenantId: tenantIdValue, isDevTest: true });
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.SET_PROMPT_VERSION_DATA, () => {
      const { data } = promptVersion;
      setLatestVersion(data.version);
    });
  };

  useEffect(() => {
    promptModels.fetch({ tenantId: tenantIdValue });
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.SET_MODEL_PROPERTIES_ADMIN_DATA, () => {
      const { data } = toJS(promptModels);
      if (!_.isEmpty(data)) {
        const filteredData = data
          .filter((item) => item.modelId)
          .reduce((acc, item) => {
            // Check for duplicates using modelId to ensure uniqueness
            if (!acc.some((existingItem) => existingItem.modelId === item.modelId)) {
              acc.push(item);
            }
            return acc;
          }, []);
        setModelData(filteredData);
      }
    });
    if (isEdit) {
      promptProperties.fetchProperties({
        tenantId: tenantIdValue,
        feature: inputValue?.feature,
        version: inputValue?.version,
        type: inputValue?.type
      });
      /* eslint-disable camelcase */
      Framework.getEventManager().on(constants.SET_PROPERTIES_ADMIN_DATA, () => {
        const { properties, model, modelId } = toJS(promptProperties);
        if (!_.isEmpty(properties)) {
          const updatedAdminPromptProperties = ChatgptConfig.AdminPromptProperties.map((item) => ({
            ...item,
            value: properties[item.id] ?? item.value
          }));
          setSliderValues(updatedAdminPromptProperties);
        }
        setSelectedModel({
          model: model || '',
          modelId: modelId || ''
        });
      });
    }
    if ((inputValue && isLiveStatus) || inputValue.status === constants.PROMPT_ARCHIVED_STATUS) {
      setIsEditPubishArchived(true);
    }
  }, [inputValue?.status]);

  useEffect(() => {
    if (inputValue?.type) {
      checkForLatestVersion(inputValue?.type);
    }
  }, [inputValue?.type]);

  useEffect(() => {
    if (inputValue.feature) {
      const result = featureData.find((item) => item.db_field === inputValue.feature);
      if (result) {
        setTypes(result.prompt_types);
      } else {
        setTypes([]);
      }
    }
  }, [inputValue?.feature]);

  const handleCancel = () => {
    setInputValue({});
    setPromptAddStartData(ColumnConfig.promptConfig);
    setPromptAddEndData(ColumnConfig.promptConfig);
    handleClose();
  };

  const changeToRawText = (promptVal) => {
    const { promptStart, promptEnd } = promptVal;
    const promptStartRawText = promptStart?.map((item) => {
      return { ...item, content: item.content?.replaceAll(/\\n/g, '\n').replaceAll(/\\/g, '') };
    });
    const promptEndRawText = promptEnd?.map((item) => {
      return { ...item, content: item.content?.replaceAll(/\\n/g, '\n').replaceAll(/\\/g, '') };
    });
    return { ...promptVal, promptStart: promptStartRawText, promptEnd: promptEndRawText };
  };

  const handleSave = async (e) => {
    if (isEdit) {
      if (isLiveStatus) {
        setDialogContent(constants.ADMIN_PROMPT_LIVE_STATUS_PUBLISH_TEXT);
        setIsPublish(true);
        setDialogboxOpen(true);
      } else {
        const finalData = {
          ...changeToRawText(inputValue),
          type: inputValue.type && inputValue.type.toUpperCase(),
          isDevTest: true
        };
        await chatgptAdminPrompts.updateAdminPromptsData(finalData);
        e.stopPropagation();
        handleClose();
      }
    } else {
      const finalData = {
        ...inputValue,
        feature: inputValue.feature && inputValue.feature.toUpperCase(),
        type: inputValue.type && inputValue.type.toUpperCase(),
        tenantId: tenantIdValue,
        tenantName: tenantKey,
        promptStart: promptAddStartData,
        promptEnd: promptAddEndData,
        isDevTest: true
      };
      await chatgptAdminPrompts.saveAdminPromptsData(changeToRawText(finalData));
      e.stopPropagation();
      handleClose();
    }
    // Update Prompt Properties
    const finalProperties = {
      tenantId: tenantIdValue,
      feature: inputValue.feature && inputValue.feature.toUpperCase(),
      model: selectedModel.model,
      modelId: selectedModel.modelId,
      version: inputValue.version,
      type: inputValue.type && inputValue.type.toUpperCase(),
      properties: {
        ...propertiesDetails,
        stream,
        stop
      }
    };
    await promptProperties.updateProperties(finalProperties);
    e.stopPropagation();
  };

  const handleDelete = async () => {
    const finalData = { ...changeToRawText(inputValue), status: constants.PROMPT_ARCHIVED_STATUS, isDevTest: true };
    await chatgptAdminPrompts.updateAdminPromptsData(finalData);
  };

  const handleInputChange = (event) => {
    const {
      target: { name, value }
    } = event;
    setInputValue({ ...inputValue, [name]: value });
  };

  const handlePromptStartChange = (event, index) => {
    const {
      target: { name, value }
    } = event;
    const role = name.split('_')[1];
    if (isEdit) {
      const start = [...inputValue.promptStart].map((v, i) => {
        if (v.role === role && i === index) {
          return {
            ...v,
            role,
            content: value
          };
        }
        return v;
      });
      setInputValue({ ...inputValue, promptStart: start });
    } else {
      setPromptAddStartData(
        [...promptAddStartData].map((v) => {
          if (v.role === role) {
            return {
              ...v,
              role,
              content: value
            };
          }
          return v;
        })
      );
    }
  };

  const handlePromptEndChange = (event, index) => {
    const {
      target: { name, value }
    } = event;
    const role = name.split('_')[1];
    if (isEdit) {
      const end = [...inputValue.promptEnd].map((v, i) => {
        if (v.role === role && i === index) {
          return {
            ...v,
            role,
            content: value
          };
        }
        return v;
      });
      setInputValue({ ...inputValue, promptEnd: end });
    } else {
      setPromptAddEndData(
        [...promptAddEndData].map((v) => {
          if (v.role === role) {
            return {
              ...v,
              role,
              content: value
            };
          }
          return v;
        })
      );
    }
  };

  const renderPromptEndTextArea = () => {
    const { promptEnd } = inputValue;
    if (!_.isEmpty(promptEnd)) {
      return promptEnd.map((endVal, index) => {
        return (
          <Grid item xs={12}>
            <Grid item xs={12} className={classes.labelColumns}>
              <InputLabel id={index} className={classes.subLable}>
                Role: {endVal.role}
              </InputLabel>
            </Grid>
            <Grid item xs={12} className={classes.gridColumns}>
              <TextareaAutosize
                className={classes.textAreaSize}
                id={index}
                name={`promptEnd_${endVal.role}`}
                onChange={(e) => handlePromptEndChange(e, index)}
                variant="outlined"
                aria-label="maximum height"
                minRows={12}
                maxRows={30}
                value={endVal.content}
                required
                disabled={isEdit && inputValue.status === constants.PROMPT_ARCHIVED_STATUS}
              />
            </Grid>
          </Grid>
        );
      });
    }
    return <></>;
  };
  const renderPromptStartTextArea = () => {
    const { promptStart } = inputValue;
    if (!_.isEmpty(promptStart)) {
      return promptStart.map((startval, index) => {
        return (
          <Grid item xs={12}>
            <Grid item xs={12} className={classes.labelColumns}>
              <InputLabel id={index} className={classes.subLable}>
                Role: {startval.role}
              </InputLabel>
            </Grid>
            <Grid item xs={12} className={classes.gridColumns}>
              <TextareaAutosize
                className={classes.textAreaSize}
                id={index}
                name={`promptStart_${startval.role}`}
                variant="outlined"
                aria-label="maximum height"
                onChange={(e) => handlePromptStartChange(e, index)}
                minRows={12}
                maxRows={30}
                value={startval.content}
                required
                disabled={isEdit && inputValue.status === constants.PROMPT_ARCHIVED_STATUS}
              />
            </Grid>
          </Grid>
        );
      });
    }
    return <></>;
  };

  const isFormValid = () => {
    const { type, version, status } = inputValue;
    const checkEditValidation = () => {
      return (
        _.isEmpty(type) ||
        _.isEmpty(version) ||
        _.isEmpty(status) ||
        (inputValue.promptStart && inputValue.promptStart.some((start) => _.isEmpty(start.content)))
      );
    };
    const checkAddValidation = () => {
      return (
        _.isEmpty(type) ||
        _.isEmpty(version) ||
        _.isEmpty(status) ||
        (promptAddStartData && promptAddStartData.some((start) => _.isEmpty(start.content))) ||
        (promptAddEndData && promptAddEndData.some((end) => _.isEmpty(end.content)))
      );
    };
    return isEdit ? checkEditValidation() : checkAddValidation();
  };

  const handleDialogBoxClose = () => {
    setDialogboxOpen(false);
  };

  const handleDialogBoxOpen = () => {
    setDialogContent('Are you sure you want to delete?');
    setDialogboxOpen(true);
  };

  const handlePublishDialogBoxOpen = () => {
    setDialogContent(constants.ADMIN_PROMPT_PUBLISH_TEXT);
    setIsPublish(true);
    setDialogboxOpen(true);
  };

  const handleConfirmAction = async (confirmed) => {
    if (isPublish && confirmed) {
      const finalData = isLiveStatus
        ? {
            ...changeToRawText(inputValue),
            status: constants.PROMPT_LIVE_STATUS,
            isLiveRecord: isLiveStatus,
            isDevTest: true
          }
        : { ...changeToRawText(inputValue), status: constants.PROMPT_LIVE_STATUS, isDevTest: true };
      await chatgptAdminPrompts.updateAdminPromptsData(finalData);
      handleClose();
    } else if (confirmed) {
      await handleDelete();
      handleClose();
    } else {
      setIsPublish(false);
    }
  };

  const handleSliderChange = (id) => (event, newValue) => {
    const propertyData = sliderValues.map((param) => {
      if (param.id === id) {
        return {
          ...param,
          value: newValue
        };
      }
      return param;
    });
    const mappedProperties = propertyData.reduce((acc, item) => {
      acc[item.id] = item.value;
      return acc;
    }, {});
    setPropertiesDetails({ ...propertiesDetails, ...mappedProperties });
    setSliderValues(propertyData);
  };

  const handleTextFieldChange = (id) => (event) => {
    const newValue = Number(event.target.value);
    const propertyData = sliderValues.map((param) => {
      if (param.id === id) {
        return {
          ...param,
          value: newValue
        };
      }
      return param;
    });
    const mappedProperties = propertyData.reduce((acc, item) => {
      acc[item.id] = item.value;
      return acc;
    }, {});
    setPropertiesDetails({ ...propertiesDetails, ...mappedProperties });
    setSliderValues(propertyData);
  };

  const handleChange = (event) => {
    const selectedItem = modelData?.find((item) => item.model === event.target.value);
    setSelectedModel({
      model: selectedItem.model || '',
      modelId: selectedItem.modelId || ''
    });
  };
  const renderVersionText = () => {
    const shouldShowVersionError =
      latestVersion &&
      inputValue.version &&
      (isLiveStatus || (!isEdit && ['DRAFT', 'EXPERIMENT'].includes(inputValue.status))) &&
      !compareVersions(latestVersion, inputValue.version);

    if (shouldShowVersionError) {
      return <div className={classes.helperTextClass}>* Please enter version greater than {latestVersion}</div>;
    }

    return null;
  };

  return (
    <>
      <Dialog fullScreen open={openDialog} onClose={handleClose} TransitionComponent={Transition}>
        <div className={classes.flexBorder}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} style={{ flexDirection: 'column' }}>
              <Box display="flex" style={{ justifyContent: 'flex-end', marginTop: '20px', marginRight: '20px' }}>
                <IconButton edge="start" color="default" onClick={() => handleClose()} aria-label="close">
                  <Tooltip title="Close">
                    <CloseIcon />
                  </Tooltip>
                </IconButton>
              </Box>
            </Grid>
          </Box>
          <Box>
            <Grid item xs={16}>
              <Typography variant="h5" align="center" gutterBottom spacing={9}>
                {isEdit ? 'Edit Prompt Details' : 'Add New Prompt'}
              </Typography>
            </Grid>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid container direction="row" style={{ justifyContent: 'center' }}>
                <Grid item xs={12} direction="row">
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
                    <FormControl sx={{ mt: 1, width: '32%', mr: 1 }} size="small" required>
                      <InputLabel id="feature">Feature</InputLabel>
                      <Select
                        label="Feature"
                        name="feature"
                        onChange={handleInputChange}
                        value={inputValue.feature}
                        disabled={isEdit && (isLiveStatus || inputValue.status === constants.PROMPT_ARCHIVED_STATUS)}
                      >
                        {!isEdit &&
                          featureData &&
                          featureData.length > 0 &&
                          featureData.map((item) => <MenuItem value={item.db_field}>{item.display_name}</MenuItem>)}
                        {isEdit && <MenuItem value={inputValue.feature}>{inputValue.feature}</MenuItem>}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ mt: 1, width: '32%', mr: 1 }} size="small" required>
                      <InputLabel id="type">Type</InputLabel>
                      <Select
                        label="Type"
                        name="type"
                        onChange={handleInputChange}
                        value={inputValue.type}
                        disabled={
                          (isEdit && (isLiveStatus || inputValue.status === constants.PROMPT_ARCHIVED_STATUS)) ||
                          !inputValue.feature
                        }
                      >
                        {!isEdit &&
                          types &&
                          types.length > 0 &&
                          types.map((item) => <MenuItem value={item.db_field}>{item.display_name}</MenuItem>)}
                        {isEdit && <MenuItem value={inputValue.type}>{inputValue.type}</MenuItem>}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ mt: 1, width: '32%', mr: 1 }} size="small" required>
                      <InputLabel id="status">Status</InputLabel>
                      <Select
                        label="Status"
                        style={{ minWidth: '32%', marginBottom: '10px' }}
                        name="status"
                        onChange={handleInputChange}
                        value={inputValue.status}
                        disabled={isEdit && (isLiveStatus || inputValue.status === constants.PROMPT_ARCHIVED_STATUS)}
                      >
                        {isEdit &&
                          !isEditPubishArchived &&
                          ChatgptConfig.adminStatusData.map((item) => {
                            if (item.id < 2) {
                              return <MenuItem value={item.statusId}>{item.title}</MenuItem>;
                            }
                            return <></>;
                          })}
                        {isEdit && isEditPubishArchived && (
                          <MenuItem value={inputValue.status}>{inputValue.status}</MenuItem>
                        )}
                        {!isEdit && (
                          <MenuItem value={ChatgptConfig.adminStatusData[0].statusId}>
                            {ChatgptConfig.adminStatusData[0].title}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <TextField
                      label="Version"
                      style={{ minWidth: '25%', marginRight: '8px' }}
                      margin="dense"
                      name="version"
                      onChange={handleInputChange}
                      value={inputValue.version}
                      variant="outlined"
                      required
                      disabled={isEdit && inputValue.status === constants.PROMPT_ARCHIVED_STATUS}
                    />
                    <FormControl sx={{ mt: 1, width: '32%', mr: 1 }} size="small" required>
                      <InputLabel id="selectModel" htmlFor="selectModel">
                        Model
                      </InputLabel>
                      <Select
                        id="selectModel"
                        label="Model"
                        name="model"
                        onChange={handleChange}
                        value={selectedModel.model}
                        disabled={isEdit && inputValue.status === constants.PROMPT_ARCHIVED_STATUS}
                      >
                        {modelData &&
                          modelData.map((item) => (
                            <MenuItem key={item.modelId} value={item.model}>
                              {item.model}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  {renderVersionText()}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  {isEdit ? (
                    <>
                      <Grid item xs={12}>
                        <Divider className={classes.heading}>
                          <Chip label="Prompt Start" size="medium" />
                        </Divider>
                      </Grid>
                      {renderPromptStartTextArea()}
                      <Grid item xs={12}>
                        <Divider className={classes.heading}>
                          <Chip label="Prompt End" size="medium" />
                        </Divider>
                      </Grid>
                      {renderPromptEndTextArea()}
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Divider className={classes.heading}>
                          <Chip label="Prompt Start" size="medium" />
                        </Divider>
                      </Grid>
                      {promptAddStartData.map((promptStartval) => {
                        return (
                          <Grid item xs={12}>
                            <Grid item xs={12} className={classes.labelColumns}>
                              <InputLabel id={promptStartval.role} className={classes.subLable}>
                                Role: {promptStartval.role}
                              </InputLabel>
                            </Grid>
                            <Grid item xs={12} className={classes.gridColumns}>
                              <TextareaAutosize
                                className={classes.textAreaSize}
                                id={`${promptStartval.role}_promptStart_id`}
                                name={`promptStart_${promptStartval.role}`}
                                variant="outlined"
                                aria-label="maximum height"
                                onChange={(e) => handlePromptStartChange(e)}
                                minRows={6}
                                maxRows={12}
                                value={promptAddStartData.content}
                                required
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                      <Grid item xs={12}>
                        <Divider className={classes.heading}>
                          <Chip label="Prompt End" size="medium" />
                        </Divider>
                      </Grid>
                      {promptAddEndData.map((promptEndval) => {
                        return (
                          <Grid item xs={12}>
                            <Grid item xs={12} className={classes.labelColumns}>
                              <InputLabel id={promptEndval.role} className={classes.subLable}>
                                Role: {promptEndval.role}
                              </InputLabel>
                            </Grid>
                            <Grid item xs={12} className={classes.gridColumns}>
                              <TextareaAutosize
                                className={classes.textAreaSize}
                                id={`${promptEndval.role}_promptEnd_id`}
                                name={`promptEnd_${promptEndval.role}`}
                                onChange={(e) => handlePromptEndChange(e)}
                                variant="outlined"
                                aria-label="maximum height"
                                minRows={6}
                                maxRows={12}
                                value={promptAddEndData.content}
                                required
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                    </>
                  )}
                  <Grid item xs={12}>
                    <Grid item xs={12} className={classes.labelColumns}>
                      <InputLabel id="comments" className={classes.subLable}>
                        Comments
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} className={classes.gridColumns}>
                      <TextField
                        style={{ width: '80%' }}
                        margin="dense"
                        name="comments"
                        onChange={handleInputChange}
                        value={inputValue.comments}
                        multiline
                        minRows={4}
                        maxRows={4}
                        variant="outlined"
                        required
                        disabled={isEdit && inputValue.status === constants.PROMPT_ARCHIVED_STATUS}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid item xs={12}>
                    <Divider className={classes.heading}>
                      <Chip label="Properties" size="medium" />
                    </Divider>
                  </Grid>
                  <Box className={classes.properties}>
                    {sliderValues &&
                      sliderValues.length > 0 &&
                      sliderValues.map((item) => {
                        return (
                          <Grid item xs={12} key={item.id}>
                            <Grid item xs={12} className={classes.sliderLabelColumns}>
                              <InputLabel id={item.sliderLable} className={classes.subSliderLable}>
                                {item.sliderLable}
                              </InputLabel>
                            </Grid>
                            <Grid item xs={12} className={classes.gridColumns}>
                              <Slider
                                size="small"
                                min={item.minVal}
                                max={item.maxVal}
                                step={item.step}
                                value={item.value}
                                onChange={handleSliderChange(item.id)}
                                disabled={isEdit && inputValue.status === constants.PROMPT_ARCHIVED_STATUS}
                              />
                            </Grid>
                            <Grid item xs={12} className={classes.gridColumns}>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                                  {item.minVal}
                                </Typography>
                                <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                                  {item.maxVal}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12} className={classes.gridColumns}>
                              <TextField
                                label={`Enter ${item.sliderLable} Value`}
                                margin="dense"
                                style={{ width: '100%' }}
                                name={item.id}
                                value={item.value}
                                onChange={handleTextFieldChange(item.id)}
                                variant="outlined"
                                type="number"
                                inputProps={{ min: item.minVal, max: item.maxVal }}
                                disabled={isEdit && inputValue.status === constants.PROMPT_ARCHIVED_STATUS}
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                    <Divider className={classes.heading}>
                      <Chip label="Other" size="medium" />
                    </Divider>
                    <Grid item xs={12} className={classes.gridColumns} style={{ marginTop: '5px' }}>
                      <FormControl style={{ width: '100%' }} size="small" required>
                        <InputLabel id="type">Stream</InputLabel>
                        <Select
                          label="Stream"
                          name="stream"
                          onChange={(e) => setStream(e.target.value === 'true')}
                          value={stream}
                          disabled={isEdit && inputValue.status === constants.PROMPT_ARCHIVED_STATUS}
                        >
                          <MenuItem value="false">False</MenuItem>
                          <MenuItem value="true">True</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.gridColumns} style={{ marginTop: '5px' }}>
                      <TextField
                        label="Stop"
                        margin="dense"
                        style={{ width: '100%' }}
                        name="stop"
                        value={stop}
                        onChange={(e) => setStop(e.target.value)}
                        variant="outlined"
                        disabled={isEdit && inputValue.status === constants.PROMPT_ARCHIVED_STATUS}
                      />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <div className={classes.flexContainerBottom}>
            <div className={classes.ButtonClass}>
              <Stack direction="row" spacing={2}>
                <Button variant="contained" size="small" onClick={() => handleCancel()} sx={{ background: '#005d83' }}>
                  {constants.CANCEL}
                </Button>
                <TooltipCustom
                  title={
                    !permissionsList.includes('admin_can_edit') ? (
                      <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                    ) : (
                      <FormattedMessage {...language.getText('common.SAVE')} />
                    )
                  }
                >
                  <span>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={(e) => handleSave(e)}
                      sx={{ background: '#005d83' }}
                      disabled={
                        !permissionsList.includes('admin_can_edit') ||
                        (isEdit && inputValue.status === constants.PROMPT_ARCHIVED_STATUS) ||
                        (isLiveStatus && !compareVersions(latestVersion, inputValue.version)) ||
                        (!isEdit &&
                          ['DRAFT', 'EXPERIMENT'].includes(inputValue.status) &&
                          !compareVersions(latestVersion, inputValue.version)) ||
                        isFormValid()
                      }
                    >
                      {constants.SAVE}
                    </Button>
                  </span>
                </TooltipCustom>
                {isEdit && (
                  <>
                    <TooltipCustom
                      title={
                        !permissionsList.includes('admin_can_edit') ? (
                          <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                        ) : (
                          <FormattedMessage {...language.getText('prompt.PUBLISH')} />
                        )
                      }
                    >
                      <span>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => handlePublishDialogBoxOpen()}
                          sx={{ background: '#005d83' }}
                          disabled={
                            !permissionsList.includes('admin_can_edit') ||
                            (isLiveStatus && !compareVersions(latestVersion, inputValue.version)) ||
                            inputValue.status === constants.PROMPT_ARCHIVED_STATUS ||
                            (!isEdit &&
                              ['DRAFT', 'EXPERIMENT'].includes(inputValue.status) &&
                              !compareVersions(latestVersion, inputValue.version)) ||
                            isFormValid()
                          }
                        >
                          {constants.GPT_PUBLISHALL}
                        </Button>
                      </span>
                    </TooltipCustom>
                    <TooltipCustom
                      title={
                        !permissionsList.includes('admin_can_edit') ? (
                          <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                        ) : (
                          <FormattedMessage {...language.getText('common.DELETE')} />
                        )
                      }
                    >
                      <span>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => handleDialogBoxOpen()}
                          sx={{ background: '#005d83' }}
                          disabled={
                            !permissionsList.includes('admin_can_edit') ||
                            (isEdit && (isLiveStatus || inputValue.status === constants.PROMPT_ARCHIVED_STATUS))
                          }
                        >
                          {constants.DELETE}
                        </Button>
                      </span>
                    </TooltipCustom>
                  </>
                )}
              </Stack>
            </div>
          </div>
        </div>
      </Dialog>
      {isDialogboxOpen && (
        <DialogConfirms
          open={isDialogboxOpen}
          handleClose={handleDialogBoxClose}
          title=""
          content={DialogContent}
          handleConfirm={handleConfirmAction}
          checkButtonText={isLiveStatus}
        />
      )}
    </>
  );
};

PromptPropertiesDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedValue: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
  tenantKey: PropTypes.string.isRequired,
  chatgptAdmin: shapes.modelOf(ChatgptAdmin).isRequired,
  chatgptAdminPrompts: shapes.modelOf(chatGptAdminPromptsData).isRequired,
  promptProperties: shapes.modelOf(PromptProperties).isRequired,
  promptModels: shapes.modelOf(PromptModelData).isRequired,
  promptVersion: shapes.modelOf(PromptVersionModel).isRequired,
  permissionsList: PropTypes.array.isRequired,
  featureData: PropTypes.array.isRequired,
  language: shapes.language.isRequired,
  tenantIdValue: PropTypes.string.isRequired
};

export default withStyles(styles)(
  observer(
    inject(
      'chatgptAdmin',
      'chatgptAdminPrompts',
      'promptProperties',
      'promptModels',
      'promptVersion'
    )(PromptPropertiesDialog)
  )
);
