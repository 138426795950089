import Framework, { shapes } from '@greenville/framework';
import { Button, Grid, Paper, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { Box, Divider, Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import _ from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import DialogComponent from '../../../../../common/components/Dialog';
import TableComponent from '../../../../../common/components/Table';
import ColumnConfig from '../../../../../common/config/ColumnConfig';
import * as constants from '../../../../../common/constants';
import utils from '../../../../../common/utils';
import ChannelsModel from '../../../models/ChannelsModel';
import ChatgptUtilityIESUser from '../../../models/ChatgptIESUser';
import ChatgptUtilityMapping from '../../../models/ChatgptMapping';
import ClusterList from '../../../models/ClusterList';
import InternalUsersList from '../../../models/InternalUsersList';
import SubTypeResponse from '../../../models/SubTypeModel';

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const styles = () => ({
  tableColumnHeight: {
    maxHeight: '600px',
    overflow: 'hidden',
    // overflowY: 'scroll',
    // borderRadius: '2px',
    // border: '1px solid #0003',
    marginBottom: '2%'
  },
  highlighted: {
    backgroundColor: '#8080802e'
  },
  columnStyle: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: '600',
    textAlign: 'center'
  },
  tableLeftScroll: {
    maxHeight: '600px',
    overflow: 'hidden',
    overflowY: 'scroll',
    borderRadius: '2px',
    border: '1px solid #0003'
  },
  columnData: {
    color: '#6a7070',
    fontSize: '14px',
    textAlign: 'center'
  },
  tableFilterTooltextWidth: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    color: '#252525'
  },
  iconStyle: {
    display: 'flex',
    paddingLeft: '12px',
    cursor: 'pointer',
    alignItems: 'center'
  },
  fontStyle: {
    marginLeft: '10px'
  },
  buttonStyle: {
    cursor: 'pointer',
    width: '170px',
    margin: '10px',
    fontSize: '14px',
    background: '#005d83 !important'
  }
});

const ChannelsListings = (props) => {
  const {
    classes,
    ChatgptUtilityIESUserData,
    channelsListingUtilityData,
    chatgptMappingUtility,
    channelsSubType,
    channelsModelData,
    modelStatus,
    isUserIdExcluded,
    internalUsers,
    clusterList,
    startDate,
    endDate,
    tenantId,
    channelsList
  } = props;
  const { isNextPageKey } = clusterList;
  const { chatListDatas } = channelsListingUtilityData;
  const [highLight, setHighLght] = useState(-1);
  const [trendsList, setTrendsList] = useState([]);
  const [userEmailDetails, setUserEmailDetails] = React.useState({});
  const [clusterTable, setClusterTable] = useState([]);
  const [clusterName, setClusterName] = useState('');
  const [showTable, setshowTable] = useState(false);
  const [order, SetOrder] = useState('ASC');
  const [directionValue, setDirectionValue] = React.useState('');
  const [openChat, openChatDialog] = React.useState(false);
  const [dataValue, setDataValue] = useState({});
  const [userTocDetails, setUserTocDetails] = useState({});
  const [dataLength, setDatalength] = useState({});
  const [modalIndex, setModalIndex] = useState(0);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [subTypeData, setSubTypeData] = useState([]);
  const [filteredData, setFilteredData] = useState(chatListDatas?.data);
  const [selectedCluster, setSelectedCluster] = useState({ clusterId: '', channelsId: '', clusterName: '' });

  const loadingStatus = modelStatus.isPending;
  const isErrorValue =
    modelStatus.isError && modelStatus.error && modelStatus.error.status === 401 && modelStatus.error.status === 500;
  const errorDisplayVal = modelStatus.error;
  const loaderParams = {
    loadingStatus,
    isErrorValue,
    errorDisplayVal
  };

  useEffect(() => {
    if (!_.isEmpty(filteredData) && filteredData.length > 0) {
      clusterList.resetStoreValues();
      setTrendsList(filteredData);
    }
    setshowTable(false);
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.SET_CHATGPT_IES_USER_DATA, () => {
      const { userData } = ChatgptUtilityIESUserData;
      if (userData.length > 0) {
        setUserEmailDetails(userData[userData.length - 1]);
      }
    });

    Framework.getEventManager().on(constants.SET_CHATGPT_MAPPING_DATA, () => {
      const { toc } = chatgptMappingUtility;
      if (toc.length > 0) {
        setUserTocDetails(toc);
      }
    });
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.CHANNELS_SUB_TYPE_RESPONSE, () => {
      const resData = utils.processChannelsSubTypeResponse(channelsSubType);
      setSubTypeData(resData);
    });
    Framework.getEventManager().on(constants.CHANNELS_MODEL_RESPONSE, () => {
      const resData = utils.processChannelsSubTypeResponse(channelsModelData);
      setLoaderStatus(false);
      if (resData.length > 0) {
        const [record] = resData;
        setDataValue(record);
      } else {
        setDataValue({});
      }
    });
  }, [filteredData]);

  useEffect(() => {
    const internalUserIds = JSON.parse(JSON.stringify(toJS(internalUsers)));
    if (isUserIdExcluded && internalUserIds?.userId.length > 0) {
      const filteredItems = filteredData.filter((item) => !internalUserIds?.userId.includes(item.userId));
      setFilteredData(filteredItems);
    } else {
      setFilteredData(chatListDatas?.data);
    }
  }, [isUserIdExcluded]);

  function getObjectName(array, targetName) {
    const matchObjects = array.filter(
      (obj) => obj.clusterName === targetName && obj.type !== constants.PROXY_CONTENT_FILTER
    );
    return matchObjects.map(
      ({ serverDateTime, userCommand, dateStamp, channelsId, userId, title, type, requestId }) => ({
        serverDateTime,
        userCommand,
        dateStamp,
        channelsId,
        userId,
        type,
        title,
        requestId
      })
    );
  }

  const fetchClusterList = (params, lastKeyVal = {}) => {
    const { clusterId, channelsId } = params;
    clusterList.fetch({ clusterId, startDate, endDate, channelsId, tenantId, lastKey: lastKeyVal });
    Framework.getEventManager().on(constants.SET_CLUSTER_LISTING_DATA, () => {
      const { data } = clusterList;
      if (data) {
        channelsList.forEach((value) => {
          const alteredValue = value.channelsId;
          if (!alteredValue) return;
          data.forEach((val, index) => {
            if (val.channelsId === alteredValue) {
              data[index].title = value.title;
            }
          });
        });
        const trimmedName = params?.clusterName.replace(/\(\d+\)/g, '').trim();
        setClusterName(trimmedName);
        setshowTable(true);
        const matchingNamesArray = getObjectName(data, trimmedName);
        const sortedArray = matchingNamesArray.sort((x, y) => new Date(y.dateStamp) - new Date(x.dateStamp));
        if (_.isEmpty(lastKeyVal)) {
          setClusterTable(sortedArray);
        } else {
          setClusterTable([...clusterTable, ...sortedArray]);
        }
      }
    });
  };

  const handleList = (item, listIndex) => {
    setHighLght(listIndex);
    setSelectedCluster(item);
    fetchClusterList(item);
  };

  const handleAscending = (dir, value) => {
    if (dir === 'asc') {
      const sorted = [...clusterTable].sort((a, b) => (a[value] > b[value] ? 1 : -1));
      setClusterTable(sorted);
    } else {
      const sorted = [...clusterTable].sort((a, b) => (a[value] < b[value] ? 1 : -1));
      setClusterTable(sorted);
    }
  };

  const sorting = (value) => {
    if (order === 'ASC') {
      handleAscending('asc', value);
      SetOrder('DSC');
      setDirectionValue(value);
    }
    if (order === 'DSC') {
      handleAscending('des', value);
      SetOrder('ASC');
      setDirectionValue(value);
    }
  };

  const hanldeGETUserId = (userId) => {
    const { userData } = ChatgptUtilityIESUserData;
    if (userId) {
      const dataMatch = userData && userData.find((item) => item.userId === userId);
      if (dataMatch) {
        setUserEmailDetails(dataMatch);
      } else {
        ChatgptUtilityIESUserData.fetch({ userId });
      }
    }
  };

  const handleEvent = (params, index) => {
    const { requestId, userId, type } = params;
    setDatalength(clusterTable.length);
    openChatDialog(true);
    setModalIndex(index);
    setLoaderStatus(true);
    channelsModelData.fetch({ type, requestId, userId });
  };

  const handleClose = () => {
    openChatDialog(false);
    setModalIndex(1);
    setSubTypeData([]);
  };

  const handleModalChange = (value) => {
    if (value === 'prev' && modalIndex > 0) {
      const { requestId, userId, type } = clusterTable[modalIndex - 1];
      setModalIndex(modalIndex - 1);
      setLoaderStatus(true);
      setSubTypeData([]);
      channelsModelData.fetch({ type, requestId, userId });
    } else {
      const { requestId, userId, type } = clusterTable[modalIndex + 1];
      setModalIndex(modalIndex + 1);
      setLoaderStatus(true);
      setSubTypeData([]);
      channelsModelData.fetch({ type, requestId, userId });
    }
  };

  const handleSelectedCheckBox = (value, sourceType) => {
    const { userId, requestId } = dataValue;
    if (subTypeData.length > 0) {
      const findValue = subTypeData.find((data) => data.requestId === requestId && data.type === sourceType);
      if (!findValue) {
        channelsSubType.fetch({ userId, type: value, requestId });
      }
    } else {
      channelsSubType.fetch({ userId, type: value, requestId });
    }
  };

  const onColumnClick = (val) => {
    sorting(val);
  };
  const onRowClick = (val, index) => {
    handleEvent(val, index);
  };
  const onMouseEnter = (val) => {
    hanldeGETUserId(val);
  };

  const handlePageChange = (e) => {
    e.preventDefault();
    let lastKey = {};
    if (isNextPageKey && isNextPageKey.ExclusiveStartKey) {
      const startKey = isNextPageKey.ExclusiveStartKey;
      lastKey = Object.entries(startKey).reduce((acc, [key, value]) => {
        if (key !== null && value !== null) {
          acc[key] = value;
        }
        return acc;
      }, {});
    }
    const lastKeyVal = { ExclusiveStartKey: lastKey };
    fetchClusterList(selectedCluster, lastKeyVal);
  };

  return (
    <>
      {trendsList.length > 0 && (
        <Grid container direction="row">
          {trendsList.length > 0 && (
            <Grid item xs={7}>
              <br />
              <Typography align="left" variant="h3">
                {constants.CLUSTER_LIST}
              </Typography>
              <br />
            </Grid>
          )}
          <Grid item xs={5}>
            <br />
            <Typography align="left" variant="h3">
              {showTable && clusterName}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={4} className={classes.tableColumnHeight}>
            {trendsList.length > 0 && (
              <Box
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  height: '100%',
                  border: '1px solid #0003',
                  overflowY: 'scroll',
                  borderRadius: '2px'
                }}
              >
                {trendsList.length > 0 &&
                  trendsList.map((item, index) => (
                    <div key={index} className={index === highLight ? classes.highlighted : ''}>
                      <div
                        className={classes.iconStyle}
                        onClick={() => handleList(item, index)}
                        onKeyDown={() => handleList(item)}
                        role="button"
                        tabIndex={0}
                      >
                        <LightbulbIcon sx={{ color: '#0003' }} />
                        <p className={classes.fontStyle}>{item.clusterName}</p>
                      </div>
                    </div>
                  ))}
                <Divider />
              </Box>
            )}
          </Grid>
          <Grid item xs={8}>
            <Box sx={{ width: '100%' }}>
              {showTable && (
                <Paper sx={{ p: 2 }} className={classes.tableLeftScroll}>
                  <TableComponent
                    columns={ColumnConfig.ChannelsListingDataGridColumn}
                    data={clusterTable}
                    onColumnClick={onColumnClick}
                    order={order}
                    onRowClick={onRowClick}
                    directionValue={directionValue}
                    userEmailDetails={userEmailDetails}
                    onMouseEnter={onMouseEnter}
                    showSelectColumn
                  />
                </Paper>
              )}
            </Box>
            <Box spacing={2}>
              <Stack sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                {isNextPageKey &&
                  isNextPageKey.ExclusiveStartKey &&
                  isNextPageKey.ExclusiveStartKey.clusterId &&
                  isNextPageKey.ExclusiveStartKey.clusterId.S !== null && (
                    <Box>
                      <ThemeProvider theme={themes}>
                        <Button
                          size="small"
                          color="primary"
                          className={classes.buttonStyle}
                          variant="contained"
                          onClick={(e) => handlePageChange(e)}
                        >
                          <Tooltip title="Load next set of data">Load More</Tooltip>
                        </Button>
                      </ThemeProvider>
                    </Box>
                  )}
              </Stack>
            </Box>
          </Grid>
          <br />
          <br />
          {openChat && (
            <DialogComponent
              openChat={openChat}
              dataValue={dataValue}
              userTocDetails={userTocDetails}
              dataLength={dataLength}
              modalIndex={modalIndex}
              handleClose={handleClose}
              handleModalChange={handleModalChange}
              subTypeData={subTypeData}
              handleSelectedCheckBox={handleSelectedCheckBox}
              loaderStatus={loaderStatus}
              loaderParams={loaderParams}
            />
          )}
        </Grid>
      )}
    </>
  );
};

ChannelsListings.propTypes = {
  channelsListingUtilityData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  ChatgptUtilityIESUserData: shapes.modelOf(ChatgptUtilityIESUser).isRequired,
  chatgptMappingUtility: shapes.modelOf(ChatgptUtilityMapping).isRequired,
  channelsSubType: shapes.modelOf(SubTypeResponse),
  channelsModelData: shapes.modelOf(ChannelsModel),
  modelStatus: shapes.state.isRequired,
  isUserIdExcluded: PropTypes.bool.isRequired,
  internalUsers: shapes.modelOf(InternalUsersList).isRequired,
  clusterList: shapes.modelOf(ClusterList).isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
  channelsList: PropTypes.array.isRequired
};

ChannelsListings.defaultProps = {
  channelsSubType: [],
  channelsModelData: []
};

export default withStyles(styles)(
  observer(
    inject(
      'ChatgptUtilityIESUserData',
      'chatgptMappingUtility',
      'channelsModelData',
      'modelStatus',
      'channelsSubType',
      'internalUsers',
      'clusterList'
    )(ChannelsListings)
  )
);
