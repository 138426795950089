import Framework from '@greenville/framework';
import { applySnapshot, types } from 'mobx-state-tree';
import * as constants from '../../../common/constants';

const modelResponse = types.model('modelResponse', {
  model: types.maybeNull(types.string),
  modelId: types.maybeNull(types.string)
});

const PromptModelData = types
  .model('PromptModelData', {
    data: types.maybeNull(types.array(modelResponse, []))
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_MODEL_PROPERTIES_ADMIN_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.SET_MODEL_PROPERTIES_ADMIN_DATA);
    }
  }));

export default PromptModelData;
