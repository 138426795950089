/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Stage environment overrides.
 */

const stg = {
  INT_LOGIN_URL: 'https://mycloud.pearson.com/redirect',
  INT_LOGOUT_URL: 'https://mycloud.pearson.com/logout',
  DELAYED_GTM: false,
  GTM_CONTAINER_ID: 'GTM-WTX7GQN',
  GTM_AUTH: '2RfxHnUh9vgQxsMrRfX-zQ',
  GTM_PREVIEW: 'env-5',
  INT_AZURE_CLIENT_ID: '80e606ce-e3e0-4db3-9602-ee8e8117064a',
  AZURE_TENANT_ID: '209a71f6-c9bb-48f1-a23d-a26d275b75e2',
  AZURE_REDIRECT_URI: 'https://evergreen-stg.pearson.com/',
  EPUB_CLOUD_FRONT_URL: 'https://d358nny7hi1tqb.cloudfront.net'
};

export default stg;
