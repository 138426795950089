import React from 'react';
import ValidatePermissions from '../../../../users/ValidatePermissions';
import PromptPropertiesComponent from './PromptPropertiesComponent';

/* eslint-disable no-shadow */
function PromptPropertiesView() {
  const showDashboardEnabled = false;
  return (
    <>
      <ValidatePermissions givenPermissions="admin_can_view" showDashboardEnabled={showDashboardEnabled}>
        <PromptPropertiesComponent />
      </ValidatePermissions>
    </>
  );
}

PromptPropertiesView.propTypes = {};

PromptPropertiesView.defaultProps = {};

export default PromptPropertiesView;
