import React from 'react';
import ChatGptUtilityContainer from './ChatGptUtilityContainer';
import AIChartsUtilityModel from './models/AIChartsUtility';
import AIContentFilterChartsModel from './models/AIContentFilterCharts';
import AIContentFilterChartsDetailsModel from './models/AIContentFilterChartsDetails';
import AIFeedbackChartsDetailsModel from './models/AIFeedbackChartsDetails';
import AddLLMTestSuite from './models/AddLLMTestSuite';
import AssetTOC from './models/AssetTOC';
import CDNToken from './models/CDNToken';
import ChannelsListing from './models/ChannelsListing';
import ChannelsModel from './models/ChannelsModel';
import ChannelsSubType from './models/ChannelsSubType';
import ChannelsTrends from './models/ChannelsTrends';
import ChannelsUtility from './models/ChannelsUtility';
import ChatgptAdminPromptsData from './models/ChatGptAdminPrompts';
import ChatUtilityGetTypesModel from './models/ChatUtilityGetTypesModel';
import ChatgptAdmin from './models/ChatgptAdmin';
import chatutilityIESData from './models/ChatgptIESUser';
import Mapping from './models/ChatgptMapping';
import ChatgptUtility from './models/ChatgptUtility';
import ChatutilityPermissions from './models/ChatutilityPermissions';
import ChatutilityRoles from './models/ChatutilityRoles';
import ChatutilityUsers from './models/ChatutilityUsers';
import CommonSearchModel from './models/CommonSearchModel';
import ReportDownloadModel from './models/DownloadReport';
import Feedback from './models/Feedback';
import FileData from './models/FileData';
import InternalUsersList from './models/InternalUsersList';
import Listing from './models/Listing';
import McqSearch from './models/McqSearch';
import PopularPageModel from './models/PopularPage';
import PromptModels from './models/PromptModels';
import PromptProperties from './models/PromptProperties';
import PromptVersionModel from './models/PromptVersion';
import ProvidersListModel from './models/ProvidersList';
import Response from './models/Response';
import EffectivenessData from './models/StudyToolEffectiveness';
import SubTypeResponse from './models/SubTypeModel';
import Summary from './models/Summary';
import TenantConfigModel from './models/TenantConfig';
import TenantsListModel from './models/TenantsList';
import Toc from './models/Toc';
import TrendingTopicByBook from './models/TrendingTopicByBook';
import TrendingTopics from './models/TrendingTopics';
import Trends from './models/Trends';
import PromptFeatureModel from './models/PromptFeature';
import AIChartsDetailsUtilityService from './service/AIChartsDetailsUtilityService';
import AIChartsUtilityService from './service/AIChartsUtilityService';
import AIContentFilterChartsDetailsService from './service/AIContentFilterChartsDetails';
import AIContentFilterChartsService from './service/AIContentFilterChartsService';
import AddLLMTestSuiteService from './service/AddLLMTestSuiteService';
import AssetService from './service/AssetService';
import ChannelsModelService from './service/ChannelsModelService';
import ChannelsSubTypeService from './service/ChannelsSubTypeService';
import ChannelsUtilityService from './service/ChannelsUtilityService';
import ChannelsUtilityTrendsListingService from './service/ChannelsUtilityTrendsListingService';
import ChannelsUtilitytrendsService from './service/ChannelsUtilityTrendsService';
import ChatGptAddAdminPromptsService from './service/ChatGptAddAdminPromptsService';
import ChatGptEditAdminPromptsService from './service/ChatGptEditAdminPromptsService';
import ChatGptGetAdminPromptsService from './service/ChatGptGetAdminPromptsService';
import ChatGptPublishAllSummaryStatusUpdateService from './service/ChatGptPublishAllSummaryUpdateService';
import ChatGptUtilityFeedbackService from './service/ChatGptUtilityFeedbackService';
import ChatgptUtilityIESService from './service/ChatGptUtilityIESService';
import ChatGptUtilityListingService from './service/ChatGptUtilityListingService';
import ChatGptUtilityMappingService from './service/ChatGptUtilityMappingService';
import ChatGptUtilityMcqEditService from './service/ChatGptUtilityMcqEditService';
import ChatGptUtilityResponseService from './service/ChatGptUtilityResponseService';
import ChatgptUtilityService from './service/ChatGptUtilityService';
import ChatGptUtilitySummaryService from './service/ChatGptUtilitySummaryService';
import ChatGptUtilitySummaryStatusUpdateService from './service/ChatGptUtilitySummaryStatusUpdateService';
import ChatGptUtilitySummaryUpdateService from './service/ChatGptUtilitySummaryUpdateService';
import ChatGptUtilityTocAddService from './service/ChatGptUtilityTocAddService';
import ChatGptUtilityTocService from './service/ChatGptUtilityTocService';
import ChatGptUtilityTrendsService from './service/ChatGptUtilityTrendsService';
import ChatUtilityGetTypesService from './service/ChatUtilityGetTypesService';
import ChatGptUtilitySubTypeService from './service/ChatUtilitySubTypeService';
import ChatgptAdminService from './service/ChatgptAdminService';
import ChatGptEditAdminService from './service/ChatgptEditAdminService';
import ChatGptGetAdminervice from './service/ChatgptGetAdminService';
import ChatutilityAddRolesService from './service/ChatutilityAddRolesService';
import ChatutilityAddUserService from './service/ChatutilityAddUserService';
import ChatutilityEditUserService from './service/ChatutilityEditUserService';
import ChatutilityPermissionsService from './service/ChatutilityPermissionService';
import ChatutilityRoleService from './service/ChatutilityRoleService';
import ChatutilityUpdateRolesService from './service/ChatutilityUpdateRolesService';
import ChatutilityUsersService from './service/ChatutilityUsersService';
import CommonSearchUtilityService from './service/CommonSearchUtilityService';
import EffectivenessUploadService from './service/EffectivenessFileUploadService';
import EffectivenessInitiateService from './service/EffectivenessInitiateService';
import EffectivenessMetadataService from './service/EffectivenessMetadataService';
import FileDataService from './service/FileDataService';
import GetLLMTestSuiteService from './service/GetLLMTestSuiteService';
import GetPromptModelNamesService from './service/GetPromptModelNames';
import GetPromptPropertiesService from './service/GetPromptPropertiesService';
import GetPromptVersionService from './service/GetPromptVersionService';
import GetProvidersListService from './service/GetProvidersListService';
import GetTenantListService from './service/GetTenantListService';
import GetTrendingTopicsService from './service/GetTrendingTopicsService';
import LLMTestResultsService from './service/LLMTestResultsService';
import McqPublishAllStatusUpdateService from './service/McqPublishAllStatusUpdateService';
import McqStatusDeleteService from './service/McqStatusDeleteService';
import McqStatusUpdateService from './service/McqStatusUpdateService';
import McqUtilityBookSearchService from './service/McqUtilityBookSearchService';
import PopularPagesService from './service/PopularPagesService';
import GetPromptFeatureService from './service/PromptFeatureService';
import ReportDownloadSQSService from './service/ReportDownloadSQSService';
import ReportDownloadService from './service/ReportDownloadService';
import StudyToolEffectivenessService from './service/StudyToolEffectivenessService';
import TrendingTopicsService from './service/TrendingTopicsService';
import ClusterListService from './service/ClusterListService';
import ClusterList from './models/ClusterList';
import UpdatePromptPropertiesService from './service/UpdatePromptPropertiesService';
// TODO: Hide Reports tab
// '/chatgptutility/aistudytools/reports',
// '/chatgptutility/channels/reports',

export default {
  path: [
    '/chatgptutility/aistudytools',
    '/chatgptutility/aistudytools/feedback',
    '/chatgptutility/aistudytools/trends',
    '/chatgptutility/aistudytools/trends/charts',
    '/chatgptutility/aistudytools/trends/clustering',
    '/chatgptutility/aistudytools/trends/unanswered',
    '/chatgptutility/aistudytools/mcq/view/:bookId',
    '/chatgptutility/aistudytools/mcq/view',
    '/chatgptutility/aistudytools/mcq/add/:bookId',
    '/chatgptutility/aistudytools/mcq/add',
    '/chatgptutility/aistudytools/mcq/edit',
    '/chatgptutility/aistudytools/mcq/edit/:bookId',
    '/chatgptutility/aistudytools/titlesearch',
    '/chatgptutility',
    '/chatgptutility/channels',
    '/chatgptutility/channels/feedback',
    '/chatgptutility/channels/trends',
    '/chatgptutility/admin',
    '/chatgptutility/aistudytools/summary/view/:bookId',
    '/chatgptutility/aistudytools/summary/view',
    '/chatgptutility/aistudytools/summary/add/:bookId',
    '/chatgptutility/aistudytools/summary/add',
    '/chatgptutility/aistudytools/summary/edit',
    '/chatgptutility/aistudytools/summary/edit/:bookId',
    '/chatgptutility/aistudytools/trends/effectiveness',
    '/chatgptutility/aistudytools/promptmgt',
    '/chatgptutility/aistudytools/promptproperties',
    '/chatgptutility/channels/promptmgt',
    '/chatgptutility/tenants',
    '/chatgptutility/users',
    '/chatgptutility/roles',
    '/chatgptutility/popularpage',
    '/chatgptutility/pvsassessment',
    '/chatgptutility/pvsassessment/selectlesson',
    '/chatgptutility/pvsassessment/selectlesson/chapter',
    '/chatgptutility/chatbotconfig',
    '/chatgptutility/aitrendingtopics',
    '/chatgptutility/report/download/:fileId',
    '/chatgptutility/*'
  ],
  exact: true,
  services: [
    new ChatgptUtilityService(),
    new ChatGptUtilityFeedbackService(),
    new ChatGptUtilityResponseService(),
    new ChatgptUtilityIESService(),
    new ChatGptUtilityTrendsService(),
    new ChatGptUtilityMappingService(),
    new ChatGptUtilitySubTypeService(),
    new ChatGptUtilityListingService(),
    new AssetService(),
    new ChatGptUtilityTocService(),
    new ChatGptUtilitySummaryService(),
    new ChatGptUtilitySummaryUpdateService(),
    new ChatGptUtilitySummaryStatusUpdateService(),
    new ChatGptUtilityMcqEditService(),
    new ChatGptUtilityTocAddService(),
    new McqStatusDeleteService(),
    new McqStatusUpdateService(),
    new McqUtilityBookSearchService(),
    new ChannelsUtilityService(),
    new ChannelsUtilityTrendsListingService(),
    new ChannelsUtilitytrendsService(),
    new ChannelsSubTypeService(),
    new ChannelsModelService(),
    new ChatgptAdminService(),
    new ChatGptGetAdminervice(),
    new ChatGptEditAdminService(),
    new ChatGptGetAdminPromptsService(),
    new ChatGptAddAdminPromptsService(),
    new ChatGptEditAdminPromptsService(),
    new StudyToolEffectivenessService(),
    new EffectivenessInitiateService(),
    new EffectivenessUploadService(),
    new EffectivenessMetadataService(),
    new McqPublishAllStatusUpdateService(),
    new ChatGptPublishAllSummaryStatusUpdateService(),
    new CommonSearchUtilityService(),
    new ChatutilityUsersService(),
    new ChatutilityRoleService(),
    new ChatutilityAddUserService(),
    new ChatutilityEditUserService(),
    new ChatutilityAddRolesService(),
    new ChatutilityPermissionsService(),
    new ChatutilityUpdateRolesService(),
    new PopularPagesService(),
    new ChatUtilityGetTypesService(),
    new AIChartsUtilityService(),
    new AIContentFilterChartsService(),
    new TrendingTopicsService(),
    new GetTrendingTopicsService(),
    new AIChartsDetailsUtilityService(),
    new AIContentFilterChartsDetailsService(),
    new FileDataService(),
    new AddLLMTestSuiteService(),
    new GetLLMTestSuiteService(),
    new LLMTestResultsService(),
    new ReportDownloadService(),
    new ReportDownloadSQSService(),
    new GetTenantListService(),
    new GetProvidersListService(),
    new ClusterListService(),
    new GetPromptPropertiesService(),
    new UpdatePromptPropertiesService(),
    new GetPromptModelNamesService(),
    new GetPromptVersionService(),
    new GetPromptFeatureService()
  ],
  stores: [
    {
      name: 'effectivenessData',
      spec: EffectivenessData,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'ChatgptUtilityIESUserData',
      spec: chatutilityIESData,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'chatgptUtility',
      spec: ChatgptUtility,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'chatgptFeedbackUtility',
      spec: Feedback,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'chatgptResponseUtility',
      spec: Response,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'ChatUtilitySubTypeResponse',
      spec: SubTypeResponse,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'chatgptTrendsUtility',
      spec: Trends,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'chatgptMappingUtility',
      spec: Mapping,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'chatgptListingUtility',
      spec: Listing,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'assetTOC',
      spec: AssetTOC,
      saveLocally: false,
      initialState: {
        id: '',
        title: '',
        children: [
          {
            id: '',
            title: '',
            type: '',
            assetType: '',
            uri: '',
            required: false
          }
        ]
      }
    },
    {
      name: 'chatgptTocUtility',
      spec: Toc,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'chatgptSummaryUtility',
      spec: Summary,
      saveLocally: false,
      initialState: {
        chatgptSummaryUtility: {}
      }
    },
    {
      name: 'mcqSearch',
      spec: McqSearch,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'channelsUtility',
      spec: ChannelsUtility,
      saveLocally: false,
      initialState: {
        channelsUtility: {}
      }
    },
    {
      name: 'channelsSubType',
      spec: ChannelsSubType,
      saveLocally: false,
      initialState: {
        channelsSubType: {}
      }
    },
    {
      name: 'channelsListingUtility',
      spec: ChannelsListing,
      saveLocally: false,
      initialState: {
        channelsUtility: {}
      }
    },
    {
      name: 'channelsTrendsUtility',
      spec: ChannelsTrends,
      saveLocally: false,
      initialState: {
        channelsUtility: {}
      }
    },
    {
      name: 'channelsModelData',
      spec: ChannelsModel,
      saveLocally: false,
      initialState: {
        channelsModelData: {}
      }
    },
    {
      name: 'chatgptAdmin',
      spec: ChatgptAdmin,
      saveLocally: false,
      initialState: {
        chatgptAdmin: {}
      }
    },
    {
      name: 'chatgptAdminPrompts',
      spec: ChatgptAdminPromptsData,
      saveLocally: false,
      initialState: {
        chatgptAdminPrompts: {}
      }
    },
    {
      name: 'promptProperties',
      spec: PromptProperties,
      saveLocally: false,
      initialState: {
        promptProperties: {}
      }
    },
    {
      name: 'chatutilityusersState',
      spec: ChatutilityUsers,
      saveLocally: false,
      initialState: {
        data: [],
        addUser: '',
        updateUser: ''
      }
    },
    {
      name: 'chatutilityuserRoles',
      spec: ChatutilityRoles,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'chatutilityrolesState',
      spec: ChatutilityRoles,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'chatutilityuserPermissions',
      spec: ChatutilityPermissions,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'popularPages',
      spec: PopularPageModel,
      saveLocally: false,
      initialState: {
        popularPages: {}
      }
    },
    {
      name: 'chatUtilityGetTypes',
      spec: ChatUtilityGetTypesModel,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'aiChartsUtility',
      spec: AIChartsUtilityModel,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'aiContentfilterCharts',
      spec: AIContentFilterChartsModel,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'aiContentfilterChartsDetails',
      spec: AIContentFilterChartsDetailsModel,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'aiFeedbackChartsDetails',
      spec: AIFeedbackChartsDetailsModel,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'trendingTopics',
      spec: TrendingTopics,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'trendingTopicByBook',
      spec: TrendingTopicByBook,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'fileData',
      spec: FileData,
      saveLocally: false,
      initialState: {
        mathJaxVersion: {}
      }
    },
    {
      name: 'llmEvaluationTestSuiteData',
      spec: AddLLMTestSuite,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'internalUsers',
      spec: InternalUsersList,
      saveLocally: false,
      initialState: {
        userId: []
      }
    },
    {
      name: 'cdnToken',
      spec: CDNToken,
      saveLocally: false,
      initialState: {
        data: ''
      }
    },
    {
      name: 'reportDownloadModel',
      spec: ReportDownloadModel,
      saveLocally: false,
      initialState: {
        errorMessage: '',
        successMessage: '',
        isReportGenerated: false
      }
    },
    {
      name: 'tenantsList',
      spec: TenantsListModel,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'providersList',
      spec: ProvidersListModel,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'promptModels',
      spec: PromptModels,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'commonSearchUtility',
      spec: CommonSearchModel,
      saveLocally: false,
      initialState: {
        commonSearchUtility: {}
      }
    },
    {
      name: 'promptVersion',
      spec: PromptVersionModel,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'tenantConfig',
      spec: TenantConfigModel,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'clusterList',
      spec: ClusterList,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'promptFeature',
      spec: PromptFeatureModel,
      saveLocally: false,
      initialState: {
        data: []
      }
    }
  ],
  render: (props) => (
    <ChatGptUtilityContainer {...props} /> // eslint-disable-line react/prop-types
  )
};
