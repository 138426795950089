/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const UserDetailsByLogin = types.model('UserDetailsByLogin', {
  userId: types.maybeNull(types.integer),
  roleId: types.maybeNull(types.integer),
  tenantId: types.maybeNull(types.string)
});

const UserId = types
  .model('UserId', {
    userdetails: types.compose(UserDetailsByLogin)
  })
  .actions((self) => ({
    fetchDetails(loginId) {
      Framework.getEventManager().publish(constants.MANAGE_USERID, { loginId });
    },
    setUserDetails(response) {
      applySnapshot(self.userdetails, response.data);
      Framework.getEventManager().publish(constants.USER_DETAILS_FETCHED);
    }
  }));
export default UserId;
