import Framework from '@greenville/framework';
import { applySnapshot, types } from 'mobx-state-tree';
import * as constants from '../../../common/constants';

const promptObj = types.model('promptObj', {
  display_name: types.maybeNull(types.string),
  db_field: types.maybeNull(types.string)
});

const featureObj = types.model('featureObj', {
  db_field: types.maybeNull(types.string),
  display_name: types.maybeNull(types.string),
  prompt_types: types.maybeNull(types.array(types.optional(promptObj, {})))
});

const featureRes = types.model('featureRes', {
  feature: types.maybeNull(types.array(types.optional(featureObj, {})))
});

const PromptFeatureModel = types
  .model('PromptFeatureModel', {
    data: types.maybeNull(types.array(featureRes, []))
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_PROMPT_FEATURE_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.SET_PROMPT_FEATURE_DATA);
    }
  }));

export default PromptFeatureModel;
