import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from '@mui/icons-material/Edit';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import * as FormData from 'form-data';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Loader from '../../../../common/Loader';
import TableComponent from '../../../../common/components/Table';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import ColumnConfig from '../../../../common/config/ColumnConfig';
import * as constants from '../../../../common/constants';
import env from '../../../../common/env';
import utils from '../../../../common/utils';
import UsersPermissions from '../../../users/models/UsersPermissions';
import AddLLMTestSuite from '../../models/AddLLMTestSuite';
import EffectivenessData from '../../models/StudyToolEffectiveness';
import EffectivenessDialogComponent from './EffectivenessDialogComponent';
import InputFileUploadComponent from './InputFileUploadComponent';

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const styles = (theme) => ({
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  datePicker: {
    marginTop: 'inherit'
  },
  marginBox: {
    border: '1px solid #322c2c2e',
    width: '52%',
    padding: '2px 15px 2px 0px',
    borderRadius: '4px',
    color: '#fff',
    height: '41px'
  },
  blurStyle: {
    position: 'absolute',
    background: 'rgb(255 255 255 / 20%)',
    top: '0',
    width: '93%',
    height: '100%',
    backdropFilter: 'blur(0.3px)',
    zIndex: '9999'
  },
  buttonStyle: {
    width: '150px',
    margin: '10px'
  },
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -20
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  },
  root: {
    top: '12%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: '14px !important'
  },
  tableBookWidth: {
    width: '150px'
  },
  tableSummaryWidth: {
    width: '150px'
  },
  centerAlign: {
    textAlign: 'center'
  },
  msgStyle: {
    fontSize: 'large',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0)
    },
    zIndex: 9999
  },
  downloadLink: {
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px',
    fontSize: '14px'
  },
  downloadBtn: {
    background: 'none',
    border: 'none',
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: '0',
    font: 'inherit'
  },
  fileUploadMessage: {
    textAlign: 'center',
    padding: '10px',
    fontSize: '14px',
    color: 'green',
    fontWeight: '600'
  },
  tableLeftScroll: {
    maxHeight: '500px',
    overflow: 'hidden',
    overflowY: 'scroll',
    borderRadius: '2px',
    border: '1px solid #0003',
    position: 'relative'
  },
  tableFilterTooltextWidth: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    color: '#252525'
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  editIconButton: {
    marginleft: '8px' /* Add some space between the text and the button */,
    padding: '4px'
  }
});

/* eslint-disable */
const EffectivenessMainComponent = ({
  effectivenessData,
  EffectivenessMetaDataStatus,
  EffectivenessDataStatus,
  classes,
  userPermissions,
  language,
  llmEvaluationTestSuiteData
}) => {
  const history = useHistory();
  const [effectivenessDataValue, setEffectivenessData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [effectivenessRecords, setEffectivenessRecords] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dataValue, setDataValue] = useState({});
  const [modalIndex, setModalIndex] = useState(0);
  const permissionsList = toJS(userPermissions.permissionList.permissions);
  const [effectivenessColumnConfig, setEffectivenessColumnConfig] = useState(ColumnConfig.EffectivenessDataGridColumn);
  const [listOfUploadedFile, setListOfUploadedFile] = useState('');
  const [showUploadMessage, setShowUploadMessage] = useState(false);
  const [displayFileName, setDisplayFileName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [rowsRecordsPerPage, setRecordsRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [recordsPage, setRecordsPage] = useState(0);
  const [order, setOrder] = useState('ASC');
  const [directionValue, setDirectionValue] = useState('');
  const [resultDirectionValue, setResultDirectionValue] = useState('');
  const [resultOrder, setResultOrder] = useState('ASC');
  const [selectedTestName, setSelectedTestName] = useState([{ testId: '', testSuiteName: '', testFileName: '' }]);
  const [open, setOpen] = useState(false);
  const [testName, setTestName] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [selectedTestSuiteDetails, setSelectedTestSuiteDetails] = useState({
    selectedTestId: '',
    selectedTestName: '',
    selectedTestFileName: ''
  });
  const [tenantVal, setTenantVal] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const fetchData = async (val) => {
    await llmEvaluationTestSuiteData.fetch({ tenantId: val });
    Framework.getEventManager().on(constants.SET_TEST_SUITE_DATA, () => {
      const result = toJS(llmEvaluationTestSuiteData.data);
      setSelectedTestName(result);
    });
    await effectivenessData.fetchMetadata({ tenantId: val });
    Framework.getEventManager().on(constants.SET_STUDYTOOL_EFFECTIVENESS_METADATA, () => {
      const result = toJS(effectivenessData.data);
      setEffectivenessData(result);
    });
    setTenantVal(val);
  };

  useEffect(() => {
    let tenantId;
    if (history.location.pathname === '/chatgptutility/channels/promptmgt/effectiveness') {
      tenantId = env.TENANT_ID.CHANNELS;
    }
    if (history.location.pathname === '/chatgptutility/aistudytools/promptmgt/effectiveness') {
      tenantId = env.TENANT_ID.AI_STUDY_TOOLS;
    }

    fetchData(tenantId);
  }, []);

  const handleUploadFile = (event) => {
    const fileList = event?.target?.files;
    if (fileList?.length > 0) {
      const formData = new FormData();
      formData.append('file', fileList[0]);
      effectivenessData.initiateFileUpload(formData);
      Framework.getEventManager().on(constants.EFFECTIVENESS_SET_FILE_UPLOAD_RESPONSE, () => {
        const { fileName } = effectivenessData;
        if (fileName) {
          setListOfUploadedFile(fileName);
          setShowUploadMessage(true);
        }
      });
    }
    event.preventDefault();
  };

  const handleBackClick = () => {
    setShowResults(false);
  };

  const handleRunTest = () => {
    if (listOfUploadedFile || !_.isEmpty(selectedTestSuiteDetails)) {
      const payload = {
        fileName: listOfUploadedFile || selectedTestSuiteDetails.selectedTestFileName,
        tenantId: tenantVal,
        testId: selectedTestSuiteDetails.selectedTestId,
        testName: selectedTestSuiteDetails.selectedTestName
      };
      effectivenessData.initiate(payload);
      setListOfUploadedFile('');
      setShowUploadMessage(false);
      fetchData(tenantVal);
    }
  };

  const format = (records) => {
    return records.map((record) => ({
      input: record.input,
      output: record.output.toString().replace(/\\r\\n/g, '<br >'),
      prompt: record.prompt,
      requestId: record.requestId,
      feedbacks: record.feedbacks
    }));
  };

  const handleShowResults = (data) => {
    if (data) {
      const formattedData = format(data?.scores);
      setEffectivenessRecords(formattedData);
      const fileName = data?.metadata?.testName;
      setDisplayFileName(fileName);
      const isGPTResults = formattedData?.some((item) => {
        const feedbackArr = item.feedbacks;
        return feedbackArr.some((obj) => Object.keys(obj).some((val) => val.includes('gpt') && obj[val] !== null));
      });
      if (isGPTResults) {
        setEffectivenessColumnConfig(ColumnConfig.EffectivenessLatestDataGridColumn);
      }
      setShowResults(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRecordsChangePage = (e, newPage) => {
    setRecordsPage(newPage);
  };

  const handleChangeRecordsRowsPerPage = (e) => {
    setRecordsRowsPerPage(parseInt(e.target.value, 10));
    setRecordsPage(0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setFromToValueForPagination = (from, to, count) => {
    return `${from}-${to} of ${count}`;
  };

  const onRowClick = (row, index) => {
    setIsDialogOpen(true);
    setDataValue(effectivenessRecords[index]);
    setModalIndex(index);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleModalChange = (value) => {
    if (value === 'prev' && modalIndex > 0) {
      setDataValue(effectivenessRecords[modalIndex - 1]);
      setModalIndex(modalIndex - 1);
    } else {
      setDataValue(effectivenessRecords[modalIndex + 1]);
      setModalIndex(modalIndex + 1);
    }
  };

  const sorting = (value) => {
    const dateFieldMatch = ['dateExecuted'].includes(value);
    let sorted;
    if (order === 'ASC') {
      if (dateFieldMatch) {
        sorted = [...effectivenessDataValue].sort(
          (a, b) => a.metadata[value] && b.metadata[value] && new Date(a.metadata[value]) - new Date(b.metadata[value])
        );
      } else {
        sorted = [...effectivenessDataValue].sort((a, b) =>
          a.metadata[value] && b.metadata[value] && a.metadata[value].toLowerCase() > b.metadata[value].toLowerCase()
            ? 1
            : -1
        );
      }
      setOrder('DSC');
      setDirectionValue(value);
    }
    if (order === 'DSC') {
      if (dateFieldMatch) {
        sorted = [...effectivenessDataValue].sort(
          (a, b) => a.metadata[value] && b.metadata[value] && new Date(b.metadata[value]) - new Date(a.metadata[value])
        );
      } else {
        sorted = [...effectivenessDataValue].sort((a, b) =>
          a.metadata[value] && b.metadata[value] && a.metadata[value].toLowerCase() < b.metadata[value].toLowerCase()
            ? 1
            : -1
        );
      }
      setOrder('ASC');
      setDirectionValue(value);
    }
    setEffectivenessData(sorted);
  };

  const onColumnClick = (val) => {
    sorting(val);
  };

  const handleTemplateDownload = () => {
    const csvData = [['Date / Time (GMT)', 'Book Id', 'Title', 'Type', 'User Input', 'User Id', 'Request Id']];

    const csvContent = csvData.map((row) => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'sample.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleDownload = async (filePath) => {
    const token = utils.getToken();
    const fileName = filePath.split('/').pop();
    if (fileName) {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const responseData = await axios
        .post(
          `${env.EVERGREEN_API_BASE_URL}/chatutility/effectiveness/s3download`,
          { fileName },
          {
            headers,
            responseType: 'json'
          }
        )
        .catch((error) => {
          const { response } = error;
          if (response) {
            const { status, data } = response;
            if (status === 500 && data && data.error) {
              const notification = {
                open: true,
                message: data.error.message,
                type: 'error'
              };
              Framework.getStoreRegistry().getStore('notificationState').set(notification);
            }
          } else if (error.message === 'timeout of 60000ms exceeded') {
            const notification = {
              open: true,
              message: error.message,
              type: 'error'
            };
            Framework.getStoreRegistry().getStore('notificationState').set(notification);
          }
        });
      if (responseData.data.status === 'success' && responseData.data.data) {
        const byteArray = new Uint8Array(responseData.data.data.data);
        const text = new TextDecoder().decode(byteArray);
        const blob = new Blob([text], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        const notification = {
          open: true,
          message: `${fileName} Downloaded Successfully`,
          type: 'success'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
      }
    }
  };

  const handleLLMBack = () => {
    history.push('/chatgptutility/aistudytools/promptmgt');
  };

  const handleAddClickOpen = (e) => {
    setOpen(true);
    setIsEdit(false);
    setTestName('');
    setUploadedFileName('');
    setListOfUploadedFile('');
    setShowUploadMessage(false);
    e.stopPropagation();
  };

  const handleEditClick = (e, val) => {
    setOpen(true);
    setIsEdit(true);
    setTestName(val.testSuiteName);
    setUploadedFileName(val.testFileName);
    setShowUploadMessage(false);
    e.stopPropagation();
  };

  const handleDialogClose = () => {
    setListOfUploadedFile('');
    setOpen(false);
  };

  const handleSave = async () => {
    if (testName && !isEdit) {
      await llmEvaluationTestSuiteData.save({
        testSuiteName: testName,
        testFileName: listOfUploadedFile,
        tenantId: tenantVal
      });
    } else {
      const testId = selectedTestName.find((item) => item.testFileName === uploadedFileName).testId;
      if (testId) {
        const token = utils.getToken();
        const headers = {
          Authorization: `Bearer ${token}`
        };
        const payload = {
          testSuiteName: testName,
          testFileName: listOfUploadedFile,
          testId,
          uploadedFileName
        };
        const response = await axios.post(
          `${env.EVERGREEN_API_BASE_URL}${constants.UPDATE_TEST_SUITE_DATA_URL}`,
          payload,
          {
            headers
          }
        );
        if (response && response.data && response.data.data) {
          if (response.data.data) {
            const notification = {
              open: true,
              type: 'success',
              message: 'Data Updated successfully'
            };
            Framework.getStoreRegistry().getStore('notificationState').set(notification);
          } else {
            const notification = {
              open: true,
              type: 'error',
              message: 'Data not Updated'
            };
            Framework.getStoreRegistry().getStore('notificationState').set(notification);
          }
        }
      } else {
        const notification = {
          open: true,
          type: 'error',
          message: 'Data not Updated'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
      }
    }
    fetchData(tenantVal);
    setTestName('');
    handleDialogClose();
  };

  const handleSelectChange = (event) => {
    const {
      target: { value }
    } = event;
    const testNameval = selectedTestName.find((item) => item.testFileName === value).testSuiteName;
    const testSuiteId = selectedTestName.find((item) => item.testFileName === value).testId;
    setSelectedTestSuiteDetails({
      selectedTestId: testSuiteId,
      selectedTestName: testNameval,
      selectedTestFileName: value
    });
  };

  const showTooltipTitle = (colName) => {
    let tooltipVal = <></>;
    switch (colName) {
      case 'Relevance':
        tooltipVal = (
          <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
            Directly on point
          </Typography>
        );
        break;
      case 'Coherence':
        tooltipVal = (
          <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
            Logically flowing
          </Typography>
        );
        break;
      case 'Groundedness':
        tooltipVal = (
          <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
            Factually anchored
          </Typography>
        );
        break;
      case 'Fluency':
        tooltipVal = (
          <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
            Smooth and natural
          </Typography>
        );
        break;
      case 'Similarity':
        tooltipVal = (
          <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
            Closely matched
          </Typography>
        );
        break;
      case 'F1 Score':
        tooltipVal = (
          <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
            Balanced accuracy
          </Typography>
        );
        break;
      default:
        tooltipVal = (
          <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
            {colName}
          </Typography>
        );
    }
    return tooltipVal;
  };

  const handleGetResults = async () => {
    await effectivenessData.fetchLLMResults({ tenantId: tenantVal, testId: selectedTestSuiteDetails.selectedTestId });
    Framework.getEventManager().on(constants.SET_STUDYTOOL_EFFECTIVENESS_DATA, () => {
      const result = toJS(effectivenessData.data);
      setEffectivenessData(result);
    });
  };

  const handleResultSort = (columnName) => {
    let sortArray = [];
    const getObjItem = (arr) => {
      const result = arr.reduce((val, item) => {
        if (item[columnName] != null) {
          val = item[columnName];
        }
        return val;
      }, 0);
      return result;
    };
    const isColumnInputOROutput = columnName === 'input' || columnName === 'output';
    if (resultOrder === 'ASC') {
      if (isColumnInputOROutput) {
        sortArray = effectivenessRecords.sort((item1, item2) =>
          item1[columnName]?.toLowerCase() < item2[columnName]?.toLowerCase() ? 1 : -1
        );
      } else {
        sortArray = effectivenessRecords.sort((item1, item2) => {
          const firstItem = getObjItem(item1.feedbacks);
          const secondItem = getObjItem(item2.feedbacks);
          return firstItem < secondItem ? 1 : -1;
        });
      }
      setResultOrder('DSC');
    } else {
      if (isColumnInputOROutput) {
        sortArray = effectivenessRecords.sort((item1, item2) =>
          item1[columnName]?.toLowerCase() > item2[columnName]?.toLowerCase() ? 1 : -1
        );
      } else {
        sortArray = effectivenessRecords.sort((item1, item2) => {
          const firstItem = getObjItem(item1.feedbacks);
          const secondItem = getObjItem(item2.feedbacks);
          return firstItem > secondItem ? 1 : -1;
        });
      }
      setResultOrder('ASC');
    }
    setResultDirectionValue(columnName);
    setEffectivenessRecords(sortArray);
  };

  return (
    <>
      <Paper>
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ p: 1 }}>
            <IconButton edge="start" color="default" onClick={() => handleLLMBack()} aria-label="back">
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="h5" align="center" gutterBottom spacing={9}>
              LLM Evaluation
            </Typography>
          </Box>
          <Box sx={{ p: 1 }}>
            <Typography variant="h6" align="center" gutterBottom spacing={9}>
              Please select a test name to fetch the results.
            </Typography>
          </Box>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <div style={{ width: '50%' }}>
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'end', p: '0px 33px' }}>
                <FormControl sx={{ mt: 1, width: '70%', maxWidth: '70%' }} size="small" required>
                  <InputLabel id="demo-simple-select-label">Select Test Name</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Select Test Name"
                    onChange={handleSelectChange}
                    value={selectedTestSuiteDetails.selectedTestFileName}
                    renderValue={(selected) => {
                      const selectedItem = selectedTestName.find((item) => item.testFileName === selected);
                      return selectedItem ? selectedItem.testSuiteName : '';
                    }}
                    required
                  >
                    {selectedTestName &&
                      selectedTestName.map((name) => (
                        <MenuItem key={name.testSuiteName} value={name.testFileName}>
                          <div className={classes.menuItem}>
                            {name.testSuiteName}
                            {!_.isEmpty(name.testSuiteName) && (
                              <IconButton
                                size="small"
                                onClick={(e) => handleEditClick(e, name)}
                                className={classes.editIconButton}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            )}
                          </div>
                        </MenuItem>
                      ))}
                    <MenuItem style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        onClick={(e) => handleAddClickOpen(e)}
                        className={classes.buttonStyle}
                        variant="outlined"
                        size="small"
                        color="primary"
                      >
                        Add New Test
                      </Button>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div style={{ width: '30%', textAlign: 'start' }}>
              <ThemeProvider theme={themes}>
                <TooltipCustom
                  title={
                    !permissionsList.includes('admin_can_view') ? (
                      <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                    ) : (
                      <FormattedMessage {...language.getText('effectiveness.RUN_TEST')} />
                    )
                  }
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={handleRunTest}
                    className={classes.buttonStyle}
                    disabled={
                      !permissionsList.includes('admin_can_view') ||
                      (permissionsList.includes('admin_can_view') && !selectedTestSuiteDetails.selectedTestName)
                    }
                  >
                    Run Test
                  </Button>
                </TooltipCustom>
                <TooltipCustom
                  title={
                    !permissionsList.includes('admin_can_view') ? (
                      <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                    ) : (
                      <FormattedMessage {...language.getText('effectiveness.GET_RESULTS')} />
                    )
                  }
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={handleGetResults}
                    className={classes.buttonStyle}
                    disabled={
                      !permissionsList.includes('admin_can_view') ||
                      (permissionsList.includes('admin_can_view') && !selectedTestSuiteDetails.selectedTestName)
                    }
                  >
                    Get Results
                  </Button>
                </TooltipCustom>
              </ThemeProvider>
            </div>
          </div>
          <Dialog
            open={open}
            onClose={handleDialogClose}
            PaperProps={{
              style: {
                width: '500px',
                height: '350px'
              }
            }}
          >
            <DialogTitle>
              {isEdit ? constants.EDIT_LLM_TEST_SUITE_TITLE : constants.ADD_LLM_TEST_SUITE_TITLE}
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Test Name"
                type="text"
                fullWidth
                variant="outlined"
                value={testName}
                onChange={(e) => setTestName(e.target.value)}
                required
              />
              <div className={classes.downloadLink}>
                <button onClick={handleTemplateDownload} className={classes.downloadBtn}>
                  {constants.DOWNLOAD_TEMPLATE}
                </button>
              </div>
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', p: '0px 33px' }}>
                <ThemeProvider theme={themes}>
                  {permissionsList.includes('admin_can_view') && (
                    <InputFileUploadComponent handleUploadFile={handleUploadFile} />
                  )}
                </ThemeProvider>
              </Box>
              {isEdit && !showUploadMessage && uploadedFileName && (
                <div className={classes.fileUploadMessage}>Uploaded FileName: {uploadedFileName}</div>
              )}
              {showUploadMessage && <div className={classes.fileUploadMessage}>New File Selected Successfully</div>}
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={handleDialogClose}
                className={classes.buttonStyle}
                variant="outlined"
                size="small"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                className={classes.buttonStyle}
                variant="outlined"
                size="small"
                color="primary"
                disabled={!testName || !listOfUploadedFile}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <br />
        </Box>
      </Paper>
      <br />
      <LoadingHandler
        loading={EffectivenessMetaDataStatus.isPending || EffectivenessDataStatus.isPending}
        loadingContent={<Loader />}
        content={
          !showResults && (
            <Paper sx={{ p: 2 }} className={classes.tableLeftScroll}>
              <TableContainer component={Paper} className={classes.root}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={classes.tableBookWidth}>
                        <Typography className={classes.columnStyle} variant="h6">
                          {constants.DATE_TIME_GMT}
                          <TableSortLabel
                            onClick={() => onColumnClick('dateExecuted')}
                            active
                            direction={directionValue === 'dateExecuted' && order === 'DSC' ? 'asc' : 'desc'}
                            sx={{
                              // disable cell selection style
                              '.MuiDataGrid-cell:focus': {
                                outline: 'none'
                              },
                              // pointer cursor on ALL rows
                              '& .MuiTableSortLabel-root:hover': {
                                cursor: 'pointer'
                              }
                            }}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell align="center" className={classes.tableBookWidth}>
                        <Typography className={classes.columnStyle} variant="h6">
                          Test Name
                          <TableSortLabel
                            onClick={() => onColumnClick('testName')}
                            active
                            direction={directionValue === 'testName' && order === 'DSC' ? 'asc' : 'desc'}
                            sx={{
                              // disable cell selection style
                              '.MuiDataGrid-cell:focus': {
                                outline: 'none'
                              },
                              // pointer cursor on ALL rows
                              '& .MuiTableSortLabel-root:hover': {
                                cursor: 'pointer'
                              }
                            }}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell align="center" className={classes.tableBookWidth}>
                        <Typography className={classes.columnStyle} variant="h6">
                          Status
                          <TableSortLabel
                            onClick={() => onColumnClick('status')}
                            active
                            direction={directionValue === 'status' && order === 'DSC' ? 'asc' : 'desc'}
                            sx={{
                              // disable cell selection style
                              '.MuiDataGrid-cell:focus': {
                                outline: 'none'
                              },
                              // pointer cursor on ALL rows
                              '& .MuiTableSortLabel-root:hover': {
                                cursor: 'pointer'
                              }
                            }}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell align="center" className={classes.tableBookWidth}>
                        <Typography className={classes.columnStyle} variant="h6">
                          {constants.EXECUTED_BY}
                          <TableSortLabel
                            onClick={() => onColumnClick('executedBy')}
                            active
                            direction={directionValue === 'executedBy' && order === 'DSC' ? 'asc' : 'desc'}
                            sx={{
                              // disable cell selection style
                              '.MuiDataGrid-cell:focus': {
                                outline: 'none'
                              },
                              // pointer cursor on ALL rows
                              '& .MuiTableSortLabel-root:hover': {
                                cursor: 'pointer'
                              }
                            }}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell align="center" className={classes.tableBookWidth}>
                        <Typography className={classes.columnStyle} variant="h6">
                          {constants.SHOW_RESULTS}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {effectivenessDataValue &&
                      Array.isArray(effectivenessDataValue) &&
                      effectivenessDataValue.length > 0 &&
                      effectivenessDataValue.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data) => {
                        return (
                          <>
                            <TableRow>
                              <TableCell>
                                <Typography className={classes.tableText}>{data.metadata.dateExecuted}</Typography>
                              </TableCell>
                              <TableCell className={classes.centerAlign}>
                                <button
                                  onClick={() => handleDownload(data.metadata.s3FilePath)}
                                  className={classes.downloadBtn}
                                >
                                  {data?.metadata?.testName}
                                </button>
                              </TableCell>
                              <TableCell className={classes.centerAlign}>{data?.metadata?.status}</TableCell>
                              <TableCell>
                                <Typography className={classes.tableText}>{data.metadata.executedBy}</Typography>
                              </TableCell>
                              <TableCell className={classes.centerAlign}>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  onClick={() => handleShowResults(data)}
                                  disabled={
                                    data.disable ||
                                    data?.metadata?.status === 'In Progress' ||
                                    data?.metadata?.status === 'Failed'
                                  }
                                  className={classes.buttonStyle}
                                >
                                  {constants.SHOW_RESULTS}
                                </Button>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={effectivenessDataValue.length}
                labelDisplayedRows={({ from, to, count }) =>
                  useMemo(() => setFromToValueForPagination(from, to, count), [from, to, count])
                }
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{ display: 'flex', justifyContent: 'center' }}
              />
            </Paper>
          )
        }
      />
      {showResults && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => handleBackClick()}>
              <ArrowBackIosIcon />
            </IconButton>
            <Typography>{displayFileName}</Typography>
          </Box>
          {effectivenessRecords?.length > 0 ? (
            <Paper sx={{ p: 2 }} className={classes.tableLeftScroll}>
              <TableComponent
                columns={effectivenessColumnConfig}
                data={
                  effectivenessRecords &&
                  Array.isArray(effectivenessRecords) &&
                  effectivenessRecords.length > 0 &&
                  effectivenessRecords.slice(
                    recordsPage * rowsRecordsPerPage,
                    recordsPage * rowsRecordsPerPage + rowsRecordsPerPage
                  )
                }
                onColumnClick={handleResultSort}
                order={resultOrder}
                onRowClick={onRowClick}
                selectedValue=""
                directionValue={resultDirectionValue}
                userEmailDetails=""
                onMouseEnter={() => {}}
                showSelectColumn
                showColumnHeaderToolTip
                showTooltipTitle={showTooltipTitle}
              />
              <TablePagination
                component="div"
                count={effectivenessRecords.length}
                labelDisplayedRows={({ from, to, count }) =>
                  useMemo(() => setFromToValueForPagination(from, to, count), [from, to, count])
                }
                page={recordsPage}
                onPageChange={handleRecordsChangePage}
                rowsPerPage={rowsRecordsPerPage}
                onRowsPerPageChange={handleChangeRecordsRowsPerPage}
                style={{ display: 'flex', justifyContent: 'center' }}
              />
            </Paper>
          ) : (
            <>
              <Paper>
                <Box sx={{ p: 1, mt: 1 }}>
                  <Typography variant="h4" align="center">
                    {constants.LLM_NO_DATA_FOUND}
                  </Typography>
                </Box>
              </Paper>
              <br />
            </>
          )}
        </>
      )}
      {isDialogOpen && (
        <EffectivenessDialogComponent
          dataValue={dataValue}
          openChat={isDialogOpen}
          dataLength={effectivenessRecords.length}
          handleClose={handleClose}
          handleModalChange={handleModalChange}
          modalIndex={modalIndex}
        />
      )}
    </>
  );
};

EffectivenessMainComponent.propTypes = {
  effectivenessData: shapes.modelOf(EffectivenessData).isRequired,
  classes: PropTypes.object.isRequired,
  userPermissions: shapes.modelOf(UsersPermissions).isRequired,
  language: shapes.language.isRequired,
  llmEvaluationTestSuiteData: shapes.modelOf(AddLLMTestSuite).isRequired
};

export default withStyles(styles)(
  inject(
    'effectivenessData',
    'EffectivenessDataStatus',
    'EffectivenessMetaDataStatus',
    'userPermissions',
    'language',
    'llmEvaluationTestSuiteData'
  )(observer(EffectivenessMainComponent))
);
