/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Local environment settings.
 */
// const BASE_URL = 'http://127.0.0.1:3036';
const BASE_URL = 'https://evergreen-dev.pearson.com';
const V1_PATH = '/api/v1';
const V2_PATH = '/api/v2.0';
const local = {
  ENVIRONMENT: 'local',
  PARTNER_ENV: 'local',
  EVERGREEN_API_BASE_URL: `${BASE_URL}${V1_PATH}`,
  EVERGREEN_API_BASE_URL_V2: `${BASE_URL}${V2_PATH}`,
  INT_BASE_URL: `${BASE_URL}${V1_PATH}`
  // AZURE_REDIRECT_URI: 'https://local.evergreen.pearson.com:3005'
};

export default local;
