import Framework, { shapes } from '@greenville/framework';
import { Button, Grid, Paper, TableSortLabel, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { Box, Divider, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';
import ChatgptUtilityIESUser from '../models/ChatgptIESUser';
import ClusterList from '../models/ClusterList';
import InternalUsersList from '../models/InternalUsersList';
import Listing from '../models/Listing';
import McqSearch from '../models/McqSearch';
import ResponseModel from '../models/Response';
import ChatDialogComponent from './datagrid/ChatDialogComponent';

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const styles = () => ({
  tableColumnHeight: {
    maxHeight: '600px',
    overflow: 'hidden',
    marginBottom: '2%'
  },
  highlighted: {
    backgroundColor: '#8080802e'
  },
  columnStyle: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: '600',
    textAlign: 'center'
  },
  tableLeftWidth: {
    paddingLeft: '12px'
  },
  tableLeftScroll: {
    maxHeight: '600px',
    overflow: 'hidden',
    overflowY: 'scroll',
    borderRadius: '2px',
    border: '1px solid #0003'
  },
  columnData: {
    color: '#6a7070',
    fontSize: '14px',
    textAlign: 'center'
  },
  tableFilterTooltextWidth: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    color: '#252525'
  },
  iconStyle: {
    display: 'flex',
    paddingLeft: '12px',
    cursor: 'pointer',
    alignItems: 'center'
  },
  fontStyle: {
    marginLeft: '10px'
  },
  buttonStyle: {
    cursor: 'pointer',
    width: '170px',
    margin: '10px',
    fontSize: '14px',
    background: '#005d83 !important'
  }
});

const Listings = (props) => {
  const {
    classes,
    ChatgptUtilityIESUserData,
    chatgptListingUtility,
    chatgptResponseUtility,
    isUserIdExcluded,
    internalUsers,
    clusterList,
    startDate,
    endDate,
    tenantId,
    mcqSearch
  } = props;
  const feedback = false;
  const { isNextPageKey } = clusterList;
  const [highLight, setHighLght] = useState(-1);
  const [trendsList, setTrendsList] = useState([]);
  const [userEmailDetails, setUserEmailDetails] = useState({});
  const [clusterTable, setClusterTable] = useState([]);
  const [clusterName, setClusterName] = useState('');
  const [showTable, setshowTable] = useState(false);
  const [rowDialogue, setRowDialogue] = useState({});
  const [modalIndex, setModalIndex] = useState(0);
  const [openChat, openChatDialog] = useState(false);
  const [dataLength, setDatalength] = useState({});
  const [order, SetOrder] = useState('ASC');
  const [directionValue, setDirectionValue] = useState('');
  const [filteredData, setFilteredData] = useState(chatgptListingUtility?.data);
  const [selectedCluster, setSelectedCluster] = useState({ clusterId: '', bookId: '', clusterName: '' });

  useEffect(() => {
    if (filteredData.length > 0) {
      clusterList.resetStoreValues();
      setTrendsList(filteredData);
    }
    setshowTable(false);
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.SET_CHATGPT_IES_USER_DATA, () => {
      const { userData } = ChatgptUtilityIESUserData;
      if (userData.length > 0) {
        setUserEmailDetails(userData[userData.length - 1]);
      }
    });

    Framework.getEventManager().on(constants.GET_CHATGPT_RESPONSE_MODEL_DATA, () => {
      const storeData = utils.processStoreValue(chatgptResponseUtility);
      if (storeData) {
        setRowDialogue(storeData);
      }
    });
  }, [filteredData]);

  useEffect(() => {
    const internalUserIds = JSON.parse(JSON.stringify(toJS(internalUsers)));
    if (isUserIdExcluded && internalUserIds?.userId.length > 0) {
      const filteredItems = filteredData.filter((item) => !internalUserIds?.userId.includes(item.userId));
      setFilteredData(filteredItems);
    } else {
      setFilteredData(chatgptListingUtility?.data);
    }
  }, [isUserIdExcluded]);

  function getObjectName(array, targetName) {
    const matchObjects = array.filter((obj) => obj.clusterName === targetName);
    return matchObjects.map(({ serverDateTime, userCommand, dateStamp, bookId, userId, title, type, requestId }) => ({
      serverDateTime,
      userCommand,
      dateStamp,
      bookId,
      userId,
      type,
      title,
      requestId
    }));
  }

  const fetchClusterList = (params, lastKeyVal = {}) => {
    const { clusterId, bookId } = params;
    clusterList.fetch({ clusterId, startDate, endDate, bookId, tenantId, lastKey: lastKeyVal });
    Framework.getEventManager().on(constants.SET_CLUSTER_LISTING_DATA, () => {
      const { data } = clusterList;
      if (data) {
        mcqSearch?.data?.forEach((value) => {
          const alteredValue = value.bookId;
          if (!alteredValue) return;
          data.forEach((dataValue, index) => {
            if (dataValue.bookId === alteredValue) {
              data[index].title = value.title;
            }
          });
        });
        const trimmedName = params?.clusterName.replace(/\(\d+\)/g, '').trim();
        setClusterName(trimmedName);
        setshowTable(true);
        const matchingNamesArray = getObjectName(data, trimmedName);
        const sortedArray = matchingNamesArray.sort((x, y) => new Date(y.dateStamp) - new Date(x.dateStamp));
        if (_.isEmpty(lastKeyVal)) {
          setClusterTable(sortedArray);
        } else {
          setClusterTable([...clusterTable, ...sortedArray]);
        }
      }
    });
  };

  const handleList = (item, listIndex) => {
    setHighLght(listIndex);
    setSelectedCluster(item);
    fetchClusterList(item);
  };

  const handleEvent = (params, index) => {
    const { requestId, bookId } = params;
    setDatalength(clusterTable.length);
    // setRowDialogue(params);
    openChatDialog(true);
    setModalIndex(index);
    chatgptResponseUtility.fetch({ type: constants.CHAT_UTILITY_ANSWER, requestId, bookId });
  };

  const handleClose = () => {
    openChatDialog(false);
    setModalIndex(1);
  };

  const handleModalChange = (value) => {
    if (value === 'prev' && modalIndex > 0) {
      const { requestId, bookId } = clusterTable[modalIndex - 1];
      // setRowDialogue(clusterTable[modalIndex - 1]);
      setModalIndex(modalIndex - 1);
      chatgptResponseUtility.fetch({ requestId, bookId, type: constants.CHAT_UTILITY_ANSWER });
    } else {
      const { requestId, bookId } = clusterTable[modalIndex + 1];
      // setRowDialogue(clusterTable[modalIndex + 1]);
      setModalIndex(modalIndex + 1);
      chatgptResponseUtility.fetch({ requestId, bookId, type: constants.CHAT_UTILITY_ANSWER });
    }
  };

  const handleAscending = (dir, value) => {
    if (dir === 'asc') {
      const sorted = [...clusterTable].sort((a, b) => (a[value] > b[value] ? 1 : -1));
      setClusterTable(sorted);
    } else {
      const sorted = [...clusterTable].sort((a, b) => (a[value] < b[value] ? 1 : -1));
      setClusterTable(sorted);
    }
  };

  const sorting = (value) => {
    if (order === 'ASC') {
      handleAscending('asc', value);
      SetOrder('DSC');
      setDirectionValue(value);
    }
    if (order === 'DSC') {
      handleAscending('des', value);
      SetOrder('ASC');
      setDirectionValue(value);
    }
  };

  const hanldeGETUserId = (userId) => {
    const { userData } = ChatgptUtilityIESUserData;
    if (userId) {
      const dataMatch = userData && userData.find((item) => item.userId === userId);
      if (dataMatch) {
        setUserEmailDetails(dataMatch);
      } else {
        ChatgptUtilityIESUserData.fetch({ userId });
      }
    }
  };

  const LightTooltip = styled(({ className, ...prop }) => <Tooltip {...prop} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11
      }
    })
  );

  const handlePageChange = (e) => {
    e.preventDefault();
    let lastKey = {};
    if (isNextPageKey && isNextPageKey.ExclusiveStartKey) {
      const startKey = isNextPageKey.ExclusiveStartKey;
      lastKey = Object.entries(startKey).reduce((acc, [key, value]) => {
        if (key !== null && value !== null) {
          acc[key] = value;
        }
        return acc;
      }, {});
    }
    const lastKeyVal = { ExclusiveStartKey: lastKey };
    fetchClusterList(selectedCluster, lastKeyVal);
  };

  return (
    <>
      <Paper>
        <Grid item xs={12}>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', p: '0px 33px' }}>
            <br />
            {trendsList.length > 0 && (
              <Grid container direction="row">
                {trendsList.length > 0 && (
                  <Grid item xs={7}>
                    <br />
                    <Typography align="left" variant="h3">
                      {constants.CLUSTER_LIST}
                    </Typography>
                    <br />
                  </Grid>
                )}
                <Grid item xs={5}>
                  <br />
                  <Typography align="left" variant="h3">
                    {showTable && clusterName}
                  </Typography>
                  <br />
                </Grid>
                <Grid item xs={4} className={classes.tableColumnHeight}>
                  {trendsList.length > 0 && (
                    <Box
                      sx={{
                        width: '100%',
                        maxWidth: 360,
                        height: '100%',
                        border: '1px solid #0003',
                        overflowY: 'scroll',
                        borderRadius: '2px'
                      }}
                    >
                      {trendsList.length > 0 &&
                        trendsList.map((item, index) => (
                          <div key={index} className={index === highLight ? classes.highlighted : ''}>
                            <div
                              className={classes.iconStyle}
                              onClick={() => handleList(item, index)}
                              onKeyDown={() => handleList(item)}
                              role="button"
                              tabIndex={0}
                            >
                              <LightbulbIcon sx={{ color: '#0003' }} />
                              <p className={classes.fontStyle}>{item.clusterName}</p>
                            </div>
                          </div>
                        ))}
                      <Divider />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={8} className={classes.tableLeftWidth}>
                  <Box sx={{ width: '100%' }}>
                    {showTable && (
                      <Paper sx={{ p: 2 }} className={classes.tableLeftScroll}>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography variant="h6" className={classes.columnStyle}>
                                    {constants.GPT_TRENDS_DATE}
                                    <TableSortLabel
                                      onClick={() => sorting('dateStamp')}
                                      active
                                      direction={directionValue === 'dateStamp' && order === 'DSC' ? 'asc' : 'desc'}
                                      sx={{
                                        // disable cell selection style
                                        '.MuiDataGrid-cell:focus': {
                                          outline: 'none'
                                        },
                                        // pointer cursor on ALL rows
                                        '& .MuiTableSortLabel-root:hover': {
                                          cursor: 'pointer'
                                        }
                                      }}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography variant="h6" className={classes.columnStyle}>
                                    {constants.BOOK_ID}
                                    <TableSortLabel
                                      onClick={() => sorting('bookId')}
                                      active
                                      direction={directionValue === 'bookId' && order === 'DSC' ? 'asc' : 'desc'}
                                      sx={{
                                        // disable cell selection style
                                        '.MuiDataGrid-cell:focus': {
                                          outline: 'none'
                                        },
                                        // pointer cursor on ALL rows
                                        '& .MuiTableSortLabel-root:hover': {
                                          cursor: 'pointer'
                                        }
                                      }}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography variant="h6" className={classes.columnStyle}>
                                    {constants.GPT_TRENDS_BOOKTITLE}
                                    <TableSortLabel
                                      onClick={() => sorting('')}
                                      active
                                      direction={directionValue === '' && order === 'DSC' ? 'asc' : 'desc'}
                                      sx={{
                                        // disable cell selection style
                                        '.MuiDataGrid-cell:focus': {
                                          outline: 'none'
                                        },
                                        // pointer cursor on ALL rows
                                        '& .MuiTableSortLabel-root:hover': {
                                          cursor: 'pointer'
                                        }
                                      }}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography variant="h6" className={classes.columnStyle}>
                                    {constants.GPT_TRENDS_USER_INPUT}
                                    <TableSortLabel
                                      onClick={() => sorting('userCommand')}
                                      active
                                      direction={directionValue === 'userCommand' && order === 'DSC' ? 'asc' : 'desc'}
                                      sx={{
                                        // disable cell selection style
                                        '.MuiDataGrid-cell:focus': {
                                          outline: 'none'
                                        },
                                        // pointer cursor on ALL rows
                                        '& .MuiTableSortLabel-root:hover': {
                                          cursor: 'pointer'
                                        }
                                      }}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography variant="h6" className={classes.columnStyle}>
                                    {constants.CHAT_USERLOGIN}
                                    <TableSortLabel
                                      onClick={() => sorting('userId')}
                                      active
                                      direction={directionValue === 'userId' && order === 'DSC' ? 'asc' : 'desc'}
                                      sx={{
                                        // disable cell selection style
                                        '.MuiDataGrid-cell:focus': {
                                          outline: 'none'
                                        },
                                        // pointer cursor on ALL rows
                                        '& .MuiTableSortLabel-root:hover': {
                                          cursor: 'pointer'
                                        }
                                      }}
                                    />
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {clusterTable.map((row, index) => (
                                <TableRow onClick={() => handleEvent(row, index)}>
                                  <TableCell>
                                    <Typography className={classes.columnData}>{row.dateStamp}</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography className={classes.columnData}>{row.bookId}</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography className={classes.columnData}>{row.title}</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography className={classes.columnData}>{row.userCommand}</Typography>
                                  </TableCell>
                                  <TableCell align="left" onMouseEnter={() => hanldeGETUserId(row.userId)} key={index}>
                                    <LightTooltip
                                      title={
                                        userEmailDetails
                                          ? Object.keys(userEmailDetails).map((item) => {
                                              let poperValue = <></>;
                                              if (item === 'firstName') {
                                                poperValue = (
                                                  <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
                                                    <b>First Name : </b>

                                                    {userEmailDetails[item]}
                                                  </Typography>
                                                );
                                              } else if (item === 'lastName') {
                                                poperValue = (
                                                  <Typography gutterBottom>
                                                    <b>Last Name : </b>

                                                    {userEmailDetails[item]}
                                                  </Typography>
                                                );
                                              } else if (item === 'email') {
                                                poperValue = (
                                                  <Typography gutterBottom>
                                                    <b>Email : </b>

                                                    {userEmailDetails[item]}
                                                  </Typography>
                                                );
                                              }
                                              return poperValue;
                                            })
                                          : 'Loading Data ...'
                                      }
                                    >
                                      <Typography className={classes.columnData}>{row.userId}</Typography>{' '}
                                    </LightTooltip>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    )}
                  </Box>
                  <Box spacing={2}>
                    <Stack sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                      {isNextPageKey &&
                        isNextPageKey.ExclusiveStartKey &&
                        isNextPageKey.ExclusiveStartKey.clusterId &&
                        isNextPageKey.ExclusiveStartKey.clusterId.S !== null && (
                          <Box>
                            <ThemeProvider theme={themes}>
                              <Button
                                size="small"
                                color="primary"
                                className={classes.buttonStyle}
                                variant="contained"
                                onClick={(e) => handlePageChange(e)}
                              >
                                <Tooltip title="Load next set of data">Load More</Tooltip>
                              </Button>
                            </ThemeProvider>
                          </Box>
                        )}
                    </Stack>
                  </Box>
                </Grid>
                <br />
                <br />
                {openChat && (
                  <ChatDialogComponent
                    openChat={openChat}
                    dataValue={rowDialogue}
                    filterPromt={constants.FILTER_PROMPT}
                    dataLength={dataLength}
                    modalIndex={modalIndex}
                    handleClose={handleClose}
                    handleModalChange={handleModalChange}
                    isTrendsInsightsList
                    isFeedback={feedback}
                  />
                )}
              </Grid>
            )}
          </Box>
        </Grid>
      </Paper>
    </>
  );
};

Listings.propTypes = {
  chatgptUtilityListingData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  ChatgptUtilityIESUserData: shapes.modelOf(ChatgptUtilityIESUser).isRequired,
  chatgptListingUtility: shapes.modelOf(Listing).isRequired,
  chatgptResponseUtility: shapes.modelOf(ResponseModel).isRequired,
  setListingResponseData: PropTypes.func.isRequired,
  isUserIdExcluded: PropTypes.bool.isRequired,
  internalUsers: shapes.modelOf(InternalUsersList).isRequired,
  clusterList: shapes.modelOf(ClusterList).isRequired,
  mcqSearch: shapes.modelOf(McqSearch).isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired
};

export default withStyles(styles)(
  observer(
    inject(
      'ChatgptUtilityIESUserData',
      'chatgptListingUtility',
      'chatgptResponseUtility',
      'internalUsers',
      'clusterList',
      'mcqSearch'
    )(Listings)
  )
);
