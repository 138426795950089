import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { inject, observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Framework, { shapes } from '@greenville/framework';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import { FormattedMessage } from 'react-intl';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import utils from '../../../../common/utils';
import * as constants from '../../../../common/constants';

const styles = () => ({
  root: {
    maxHeight: 480,
    flexGrow: 1
  },
  cursor: {
    cursor: 'pointer'
  },
  layout: {
    tableLayout: 'fixed'
  }
});

const BookListItem = ({ classes, data, language }) => (
  <TableContainer className={classes.root} component={Paper}>
    <Table className={classes.layout} stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '9%' }}>
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.STATUS')} />
            </Typography>
          </TableCell>
          <TableCell style={{ width: '24%' }}>
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.TITLE')} />
            </Typography>
          </TableCell>
          <TableCell style={{ width: '15%' }}>
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.AUTHOR')} />
            </Typography>
          </TableCell>
          <TableCell style={{ width: '11%' }}>
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.TITLE_TYPE')} />
            </Typography>
          </TableCell>
          <TableCell style={{ width: '12%' }}>
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.GLOBAL_BOOK_ID')} />
            </Typography>
          </TableCell>
          <TableCell style={{ width: '9%' }}>
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.EDITION')} />
            </Typography>
          </TableCell>
          <TableCell style={{ width: '16%' }}>
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.BOOK_ID')} />
            </Typography>
          </TableCell>
          <TableCell style={{ width: '12%' }}>
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.MODIFIED_BY')} />
            </Typography>
          </TableCell>
          <TableCell style={{ width: '10%' }}>
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.LAST_MODIFIED')} />
            </Typography>
          </TableCell>
          <TableCell style={{ width: '8%' }}>
            <Typography variant="h5">
              <FormattedMessage {...language.getText('label.PAGE_COUNT')} />
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {data.map((row) => (
          <TableRow>
            <TableCell>
              <Typography variant="body2" className={classes.inherit} component="div">
                {row.status}
              </Typography>
            </TableCell>
            <TableCell size="medium">
              <Grid container spacing={1} alignItems="center">
                <Grid
                  item
                  style={{ width: '27%' }}
                  className={classes.cursor}
                  onClick={() => {
                    Framework.redirectTo(`books/${row.bookID}`);
                  }}
                >
                  <Avatar alt={row.title} src={row.thumbnail} />
                </Grid>
                <Grid
                  item
                  style={{ width: '73%' }}
                  title={row.title + utils.getEditionTypeValue(row.editionTypeID)}
                  onClick={() => {
                    Framework.redirectTo(`books/${row.bookID}`);
                  }}
                >
                  <Typography variant="body2" component="div">
                    {row.title}
                    {utils.getEditionTypeValue(row.editionTypeID)}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Typography variant="body2" component="div" title={utils.authorFullName(row.authorList)}>
                {utils.authorFullName(row.authorList)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" component="div" title={row.titleType}>
                {row.titleType}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" component="div" noWrap title={row.globalBookID}>
                {row.globalBookID}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" component="div" title={row.bookEditionNumber}>
                {row.bookEditionNumber.trim() && row.bookEditionNumber}
                {!row.bookEditionNumber.trim() && 'N/A'}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                component="div"
                noWrap
                title={
                  row.titleType === constants.RPLUS_PDF_TITLE || row.titleType === constants.RPLUS_EPUB_TITLE
                    ? row.pulseDetails?.pulseDetails?.[0]?.pulseBookID ?? ''
                    : row.bookID ?? ''
                }
              >
                {row.titleType === constants.RPLUS_PDF_TITLE || row.titleType === constants.RPLUS_EPUB_TITLE
                  ? row.pulseDetails?.pulseDetails?.[0]?.pulseBookID ?? ''
                  : row.bookID ?? ''}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" component="div" noWrap title={row.modifiedBy}>
                {row.modifiedBy}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" component="div" noWrap title={row.lastModified}>
                {row.lastModified}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" component="div" noWrap>
                {row.pageCount}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

BookListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  language: shapes.language.isRequired
};

BookListItem.defaultProps = {
  data: []
};

export default withStyles(styles)(observer(inject('language')(BookListItem)));
